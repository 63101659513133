import AccountTable from './accounts-table';
import AccountView from './AccountView';
import NetwrokForm from './NetworkForm';
import React, { useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { AccountForm } from './CreateAccountForm/AccountForm';
import { useDispatch, useSelector } from 'react-redux';
import { getIndustries } from 'redux/slicers/settings';
import { initAccounts } from 'redux/slicers/accounts';
import { selectPermissions } from 'redux/slicers/permissions';

export const Accounts = () => {
    const { path } = useRouteMatch();
    const dispatch = useDispatch();
    const permissions = useSelector(selectPermissions);
    const didFetchAccounts = useSelector((state) => state.accounts.didFetchAccounts);
    const token = window.localStorage.getItem('auth0Token');

    useEffect(() => {
        if (token && !didFetchAccounts) {
            if (permissions.account && permissions.account.read) {
                dispatch(getIndustries());
                dispatch(initAccounts());
            }
        }
    }, [token, permissions]);

    return (
        <div key={'accounts'}>
            <Switch>
                <Route path={`${path}/view/:accountId`} component={AccountView} />
                <Route path={`${path}/create`}>
                    <AccountForm action="create" />
                </Route>
                <Route path={`${path}/edit/:accountId`}>
                    <AccountForm action="edit" />
                </Route>
                <Route path={`${path}/networks/:accountId/create`}>
                    <NetwrokForm action="create" />
                </Route>
                <Route path={`${path}/networks/:accountId/edit/:networkId`}>
                    <NetwrokForm action="edit" />
                </Route>
                <Route path={`${path}`} component={AccountTable} />
            </Switch>
        </div>
    );
};
