export default   {
    baseAPI: process.env.REACT_APP_BASE_API,
    disableSSL: process.env.REACT_APP_DISABLE_SSL,
    iamUrl: process.env.REACT_APP_IAM_URL,
    accountsUrl: process.env.REACT_APP_ACCOUNTS_URL,
    iamAudience: process.env.REACT_APP_IAM_AUDIENCE,
    iamDomain: process.env.REACT_APP_IAM_DOMAIN,
    iamClientId: process.env.REACT_APP_IAM_CLIENT_ID,
    iamAdminConsoleOrgId: process.env.REACT_APP_ADMIN_CONSOLE_ORG_ID,
    licenseLicenseUrl:  process.env.REACT_APP_LICENSE_LICENSE_URL,
    licenseProductUrl:  process.env.REACT_APP_LICENSE_PRODUCT_URL,
    implementationGuideUrl: process.env.REACT_APP_IMPLEMENTATION_GUIDE_URL,
    maintenanceMode: process.env.REACT_APP_SHOW_MAINTENANCE_PAGE,
    marketoServiceURL: process.env.REACT_APP_MARKETO_URL,
    hubspotServiceURL: process.env.REACT_APP_HUBSPOT_URL
};
