import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch } from 'react-router-dom';
import { Navbar } from '../../widgets/navbar';
import { Accounts } from '../../containers/accounts';
import Users from '../../containers/users';
import { Reports } from '../../containers/reports';
import { requirePermissions } from 'redux/slicers/permissions';
import { useDispatch } from 'react-redux';

const perms = {
    accounts: ['account:read'],
    report: ['analytics:read', 'account:read'],
    users: ['user:read'],
};

const tabs = [<Accounts key={'accounts'} perms={perms.accounts} />, <Reports key={'reports'} perms={perms.report} />, <Users key={'users'} perms={perms.users} />];

export default function Main() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {
        params: { tab },
    } = useRouteMatch();

    const Component = tabs.find(({ key }) => key === tab && dispatch(requirePermissions({ required: perms[key], Component: true, Disabled: false }))) || <div>NOT FOUND</div>;

    return (
        <div className={classes.container}>
            <Navbar selectedTab={Component.key} tabs={tabs.map(({ key, props: { perms } }) => ({ key, perms }))} />
            <div className={classes.view}>{Component}</div>
        </div>
    );
}

const useStyles = makeStyles(() => ({
    container: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        overflowX: 'hidden',
    },
    view: {
        paddingTop: 50,
        width: '100%',
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '5px',
            height: '5px',
            '&:hover': {
                opacity: 0.8,
            },
            backgroundColor: '#14172c',
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundImage: 'linear-gradient(to right,#fe0072, #fe0072)',
            outline: '1px solid slategrey',
        },

        '.MuiSnackbar-root': {
            bottom: 0,
        },

        '.MuiSnackbarContent-root': {
            background: 'rgb(20, 23, 44)',
            color: 'white',
            borderRadius: 0,
            borderTop: '1px solid #fe0072',
            borderLeft: '1px solid #fe0072',
            borderRight: '1px solid #fe0072',
        },

        '.MuiSnackbarContent-message': {
            fontFamily: 'avenir',
            fontSize: '14px',
        },
    },
}));
