import React, { useState } from 'react';
import './styles.css';
import styles from './styles';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Toast from '../../../services/notifications';

const CustomDate = (props) => {
    const { filters, setFilters, isOpen, setIsDialogOpen } = props;
    const [startDate, setStartDate] = useState(filters.where.startDate);
    const [endDate, setEndDate] = useState(filters.where.endDate);
    const classes = styles();

    const onSubmit = () => {
        if (moment(startDate).isBefore(endDate)) {
            setFilters((s) => ({ ...s, where: { ...s.where, startDate, endDate } }));
            setIsDialogOpen(false);
        } else {
            Toast({ message: 'invalid date range' });
        }
    };

    const onCancel = () => {
        setIsDialogOpen(false);
    };

    return (
        <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle className={classes.customDateTitle} id="alert-dialog-title">
                Select date range
            </DialogTitle>
            <DialogContent>
                <div className={classes.customDateInputContainer}>
                    <input className={classes.customDateInput} type={'date'} value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    <input className={classes.customDateInput} type={'date'} value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
            </DialogContent>
            <DialogActions className="dialog-buttons-Container">
                <button className="cq-btn" onClick={onSubmit}>
                    SAVE
                </button>
                <button className="cq-btn" onClick={onCancel}>
                    CANCEL
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default CustomDate;
