import { MenuItem } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import { IValueLabelPair } from 'models/Common';

interface IMuiSelectSingleChoice {
    id: string;
    label: string;
    size?: 'small' | 'medium' | undefined;
    onChange: (event: any) => void;
    value: string | number;
    type?: string;
    placeholder?: string;
    disabled?: boolean,
    options: any[];
    inputProps?: any;
    InputProps?: any;
    InputLabelProps?: any;
    SelectProps?: any;
    menuItemsProps?: any;
}

export const MuiSelectSingleChoice = ({
    id,
    label,
    size,
    type,
    placeholder,
    onChange,
    value,
    disabled=false,
    options,
    inputProps,
    InputProps,
    InputLabelProps,
    SelectProps,
    menuItemsProps,
}: IMuiSelectSingleChoice) => {
    return (
        <TextField
            id={id}
            label={label}
            variant="outlined"
            type={type}
            size={size}
            disabled={disabled}
            defaultValue=''
            placeholder={placeholder}
            autoComplete="off"
            onChange={onChange}
            value={value}
            inputProps={inputProps}
            InputProps={InputProps}
            InputLabelProps={InputLabelProps}
            select
            SelectProps={SelectProps}
        >
            {options.map((plan: IValueLabelPair) => (
                <MenuItem key={plan.value} value={plan.value} classes={menuItemsProps}>
                    {plan.label}
                </MenuItem>
            ))}
        </TextField>
    );
};
