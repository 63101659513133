import Toast from "../../../../services/notifications";
import moment from "moment";
const MAX_CLICKS = 30;
const MIN_ACTIVE_RULES = 1;

export const TIME_FRAME_OPTIONS = [
    { value: 'minutes', label: 'minutes', minMilliValue: 60000 },
    { value: 'hours', label: 'hours', minMilliValue: 60000 * 60 },
    { value: 'days', label: 'days', minMilliValue: 60000 * 60 * 24 },
    { value: 'weeks', label: 'weeks', minMilliValue: 60000 * 60 * 24 * 7 },
];

export const handleOnRuleChange = (tagId, property, value, ruleFields, setRuleFields, initialRuleValues, setInitialRuleValues, onActive) => {
    if (!initialRuleValues) setInitialRuleValues(ruleFields);
    switch (property) {
        case 'active':
            isValid(property, value, ruleFields, onActive, tagId)
                ? setRuleFields({ ...ruleFields, [property]: value })
                : Toast({ type: 'error', message: `Minimum active tags have to be equal to or greater than ${MIN_ACTIVE_RULES}.` });
            return { ...ruleFields, [property]: value };
        case 'clicks': {
            if (isValid(property, value, ruleFields, onActive)) {
                setRuleFields({ ...ruleFields, [property]: Number(value) ? Number(value) : '' });
            } else {
                value < 0
                    ? Toast({ type: 'error', message: `minimum clicks per interval is greater than 0.` })
                    : Toast({ type: 'error', message: `Maximum clicks per interval is ${MAX_CLICKS}.` });
            }

            return { ...ruleFields, [property]: Number(value) ? Number(value) : '' };
        }
        case 'campaignInput':
            if (isValid(property, value, ruleFields, onActive)) {
                setRuleFields({ ...ruleFields, [property]: value })
                return { ...ruleFields, [property]: value }
            } else {
                Toast({ type: 'error', message: `Maximum clicks per interval is ${MAX_CLICKS}.` });
            }
            break;

        case 'interval': {
            if (isValid(property, value, ruleFields, onActive)) {
                if (ruleFields.timeFrame) {
                    const interval = getMilliByTimeFrame(value, ruleFields.timeFrame);
                    const { amount, frame } = getTimeFrameByMilli(interval);
                    setRuleFields({ ...ruleFields, displayedInterval: amount, timeFrame: frame, interval });
                    return { ...ruleFields, displayedInterval: amount, timeFrame: frame, interval }
                } else {
                    setRuleFields({ ...ruleFields, displayedInterval: value });
                    return { ...ruleFields, displayedInterval: value }
                }
            }
            break;
        }

        case 'timeFrame': {
            const valueInMilli = getMilliByTimeFrame(ruleFields.displayedInterval, value);
            setRuleFields({ ...ruleFields, timeFrame: value, interval: valueInMilli });
            return { ...ruleFields, timeFrame: value, interval: valueInMilli }
        }

        case 'addCampaign':
            if (isValid(property, value, ruleFields, onActive)) {
                setRuleFields({ ...ruleFields, campaignArr: [...ruleFields.campaignArr, value] });
                return { ...ruleFields, campaignArr: [...ruleFields.campaignArr, value] }
            } else {
                Toast({ type: 'error', message: `Campaign "${value}" is already exists.` });
            }
            break;
        case 'deleteCampaign':
            if (ruleFields.campaignArr.includes(value)) {
                const filteredCampaignArr = ruleFields.campaignArr.filter((campaign) => campaign !== value);
                setRuleFields({ ...ruleFields, campaignArr: filteredCampaignArr });
                return { ...ruleFields, campaignArr: filteredCampaignArr }
            }
            break;

        default:
            break;
    }
};


export const getTimeFrameByMilli = (value) => {
    let timeFrame = {};

    TIME_FRAME_OPTIONS.forEach((frame, index, arr) => {
        const { minMilliValue, label } = frame;
        if (value >= minMilliValue && (index === arr.length - 1 || value < arr[index + 1].minMilliValue)) {
            timeFrame = { amount: Math.floor(value / minMilliValue), frame: label };
        }
    });
    return timeFrame;
};

const isValid = (property, value, ruleFields, onActive, tagId) => {
    switch (property) {
        case 'active':
            return value ? true : onActive(tagId);
        case 'clicks':
            return value > 0 && value <= MAX_CLICKS ? true : value === '';
        case 'interval':
            return !value || (value > 0 && value <= 60);
        case 'addCampaign':
            return !ruleFields.campaignArr.includes(value);

        default:
            return true;
    }
};

const getMilliByTimeFrame = (value, frame) => moment.duration(value, frame).asMilliseconds();
