import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    container: {
        width: 1370,
        height: 665,
        margin: '50px auto',

    },
    accounts: {},
    users: {
        marginLeft: 50
    },
    accordion: {
        width: 400,
        marginBottom: 10
        // height: 40
    },
    userNotActive: {
        backgroundColor: 'gray !important'
    },
    accordionSummary: {
        height: 40,
        padding: 0,
        minHeight: 40,
        '& .MuiAccordionSummary-content': {
            justifyContent: 'center',
        }
    },
    accordionDetails: {
        padding: 0,
        minHeight: 40,
        display: 'block'
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '20px 10px 20px 10px'
    },
    actionBtn: {
        padding: 5,
        backgroundColor: '#fe0072',
        color: '#fff',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
    },
    addUser: {
        color: '#fe0072',
        textDecoration: 'underline',
        cursor: 'pointer',
        margin: 'auto auto 10px 0px',
        fontSize: 16,
        textAlign: 'left',
        display: 'flex'
    },
    addUserIconBtn: {
        marginRight: 10,
        marginLeft: -1
    },
    addUserContainer: {
        transform: 'rotate(360deg)',
        height: 350,
        width: 540,
        position: 'relative',
        backgroundColor: '#EBECF0',
        padding: '40px 20px 20px 20px',
        overflowX: 'hidden'
    },
    closeAddUserBtn: {
        position: 'absolute',
        cursor: 'pointer',
        transform: 'rotate(45deg)',
        color: '#fe0072',
        right: 15,
        top: 15
    },
    inputGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 10
    },
    input: {
        width: 212,
        height: 33
    },
    selectLabel: {
        textAlign: 'left',
        marginLeft: 8,
        fontSize: 14,
        color: '#000'
    },
    addUserBtn: {
        padding: 5,
        backgroundColor: '#fe0072',
        color: '#fff',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        width: 200,
        height: 50,
        marginTop: 40,
        fontSize: 24,
        margin: 'auto'
    },
    deleteUserBtn: {
        padding: 5,
        backgroundColor: 'red',
        color: '#fff',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        width: 100,
        height: 25,
        marginTop: 30,
        fontSize: 16,
        margin: 'auto'
    },
    searchBox: {
        marginBottom: 10,
        '& .MuiFilledInput-root': {
            backgroundColor: '#fff',
        }
    },
    tableActionBtn: {
        color: '#fe0072',
        cursor: 'pointer',
    },
    addUserBtnNavBar: {
        display: 'block',
        marginLeft: 'auto'
    }

}))