import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import { Select } from '../../widgets/select';
import moment from 'moment';
import { isLoading as isAccountLoading, selectAccounts } from 'redux/slicers/accounts';
import { getReport, getHistoryKey } from 'redux/slicers/reports';
import CustomDate from './CustomDate';

const getDateRanges = () => {
    const dateFormat = 'YYYY-MM-DD';
    return [
        {
            label: 'Today',
            value: {
                startDate: moment().format(dateFormat),
                endDate: moment().format(dateFormat),
            },
        },
        {
            label: 'Last 7 days',
            value: {
                startDate: moment().subtract(7, 'days').format(dateFormat),
                endDate: moment().format(dateFormat),
            },
        },
        {
            label: 'Last 30 days',
            value: {
                startDate: moment().subtract(30, 'days').format(dateFormat),
                endDate: moment().format(dateFormat),
            },
        },
        {
            label: 'Last 3 months',
            value: {
                startDate: moment().subtract(3, 'months').format(dateFormat),
                endDate: moment().format(dateFormat),
            },
        },
        {
            label: 'Last 6 months',
            value: {
                startDate: moment().subtract(6, 'months').format(dateFormat),
                endDate: moment().format(dateFormat),
            },
        },
        {
            label: 'Custom',
            value: 'custom',
        },
    ];
};

const groupByOptions = [
    {
        label: 'CSM',
        value: 'csm',
    },
    {
        label: 'Sales engineer',
        value: 'se',
    },
];

const FilteringForm = (props) => {
    const { report } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const dispatch = useDispatch();
    const { regions = [], products = [], accountTypes = [], accounts = [], csms = [], ses = [], ready: accountsReady } = useSelector(selectAccounts);
    const accountsOptions = accounts.map(({ name, id }) => ({ label: name, value: id }));
    const regionsOptions = regions.map(({ name, id }) => ({ label: name, value: id }));
    const productsOptions = products.map(({ name, id }) => ({ label: name, value: id }));
    const accountTypesOptions = accountTypes.map(({ name, id }) => ({ label: name, value: id }));
    const csmsOptions = csms.map(({ name, id }) => ({ label: name, value: id }));
    const sesOptions = ses.map(({ name, id }) => ({ label: name, value: id }));
    const dateRanges = getDateRanges();
    const paresCustomDate = () => {
        return filters.where.startDate && filters.where.endDate
            ? {
                  label: `${moment(filters.where.startDate, 'YYYY-MM-DD').format('YYYY/MM/DD')}-${moment(filters.where.endDate, 'YYYY-MM-DD').format('YYYY/MM/DD')}`,
                  value: filters.dateRange,
              }
            : null;
    };
    const [filters, setFilters] = useState(report.params || { groupBy: [], where: { ...dateRanges[0].value } });
    const disableRun = report.key === getHistoryKey(filters);

    const clearFilters = () => {
        setFilters({ groupBy: [], where: { ...dateRanges[0].value } });
    };

    const runReport = () => {
        dispatch(
            getReport({
                groupBy: filters.groupBy,
                where: Object.entries(filters.where)
                    .filter(([key, value]) => value.length)
                    .reduce((total, [key, value]) => ({ ...total, [key]: value }), {}),
            })
        );
    };
    useEffect(() => report.params && setFilters(report.params), [report.params]);

    const disableClearAll =
        filters.where.startDate === dateRanges[0].value.startDate &&
        filters.where.endDate === dateRanges[0].value.endDate &&
        !filters.groupBy.length &&
        (!filters.where.account || !filters.where.account.length) &&
        (!filters.where.region || !filters.where.region.length) &&
        (!filters.where.accountType || !filters.where.accountType.length) &&
        (!filters.where.ses || !filters.where.ses.length) &&
        (!filters.where.csms || !filters.where.csms.length);
    return (
        <div className="formContainer">
            <div>
                <button disabled={disableClearAll} className="cq-btn" onClick={clearFilters}>
                    CLEAR ALL
                </button>
                <Select
                    className="cq-form-control"
                    value={dateRanges.find(({ value }) => value.startDate === filters.where.startDate && value.endDate === filters.where.endDate) || paresCustomDate() || dateRanges[0]}
                    options={dateRanges}
                    placeholder={'Time Range'}
                    onChange={({ value }) => (value === 'custom' ? setIsDialogOpen(true) : setFilters((s) => ({ ...s, where: { ...s.where, ...value } })))}
                />
                <Select
                    className="cq-form-control"
                    isMulti={true}
                    isLoading={dispatch(isAccountLoading('initAccounts'))}
                    value={groupByOptions.filter(({ value }) => (filters.groupBy || []).includes(value))}
                    options={groupByOptions}
                    placeholder={'Group By'}
                    onChange={(selected) => setFilters((s) => ({ ...s, groupBy: (selected || []).map(({ value }) => value) }))}
                />
            </div>

            <div>
                <p className="cq-heading">
                    <b>ADVANCED</b> FILTERING
                </p>
                <Select
                    className="cq-form-control"
                    isMulti={true}
                    isLoading={dispatch(isAccountLoading('initAccounts'))}
                    value={accountsOptions.filter(({ value }) => (filters.where.account || []).includes(value))}
                    options={accountsOptions}
                    placeholder={'Account Name'}
                    onChange={(selected) => setFilters((s) => ({ ...s, where: { ...s.where, account: (selected || []).map(({ value }) => value) } }))}
                />
                <Select
                    className="cq-form-control"
                    isMulti={true}
                    isLoading={dispatch(isAccountLoading('initAccounts'))}
                    value={regionsOptions.filter(({ value }) => (filters.where.region || []).includes(value))}
                    options={regionsOptions}
                    placeholder={'Region'}
                    onChange={(selected) => setFilters((s) => ({ ...s, where: { ...s.where, region: (selected || []).map(({ value }) => value) } }))}
                />
                <Select
                    className="cq-form-control"
                    isMulti={true}
                    isLoading={dispatch(isAccountLoading('initAccounts'))}
                    value={productsOptions.filter(({ value }) => (filters.where.product || []).includes(value))}
                    options={productsOptions}
                    placeholder={'Product'}
                    onChange={(selected) => setFilters((s) => ({ ...s, where: { ...s.where, product: (selected || []).map(({ value }) => value) } }))}
                />
                <Select
                    className="cq-form-control"
                    isMulti={true}
                    isLoading={dispatch(isAccountLoading('initAccounts'))}
                    value={accountTypesOptions.filter(({ value }) => (filters.where.accountType || []).includes(value))}
                    options={accountTypesOptions}
                    placeholder={'Account Type'}
                    onChange={(selected) => setFilters((s) => ({ ...s, where: { ...s.where, accountType: (selected || []).map(({ value }) => value) } }))}
                />
                <Select
                    className="cq-form-control"
                    isMulti={true}
                    isLoading={dispatch(isAccountLoading('initAccounts'))}
                    value={csmsOptions.filter(({ value }) => (filters.where.csms || []).includes(value))}
                    options={csmsOptions}
                    placeholder={'CSM'}
                    onChange={(selected) => setFilters((s) => ({ ...s, where: { ...s.where, csms: (selected || []).map(({ value }) => value) } }))}
                />
                <Select
                    className="cq-form-control"
                    isMulti={true}
                    isLoading={dispatch(isAccountLoading('initAccounts'))}
                    value={sesOptions.filter(({ value }) => (filters.where.ses || []).includes(value))}
                    options={sesOptions}
                    placeholder={'Sales Engineer'}
                    onChange={(selected) => setFilters((s) => ({ ...s, where: { ...s.where, ses: (selected || []).map(({ value }) => value) } }))}
                />
                <button disabled={disableRun} className="cq-btn-lg runBtn" onClick={runReport}>
                    RUN
                </button>
            </div>
            <CustomDate filters={filters} setFilters={setFilters} isOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} />
        </div>
    );
};

export default FilteringForm;
