export const actions = createConstants([
   'LOGIN',
   'LOGOUT'
]);


export const ACCOUNT_SUPPORTER_ROLES = ["se", "csm", "console_team_lead", "console_vp_admin"]

function createConstants(...constants) {
    return constants.reduce((acc, constant) => {
        acc[constant] = constant;
        return acc;
    }, {});
}