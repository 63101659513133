import { customerConversionDataOptions } from '../UserConversionData.config';
import { ConversionDataEnum } from '../UserConversionData.types';

export const CONVERSION_EVENT_NAME = customerConversionDataOptions[ConversionDataEnum.TYPE][0];
export const THANK_YOU_PAGE = customerConversionDataOptions[ConversionDataEnum.TYPE][1];
export const THANK_YOU_PAGE_TYPE_NUM = 2;
export const EVENT_NAME_TYPE_NUM = 1;

export const validateDomain = (domain: string): boolean => {
    const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;
    return regex.test(domain);
};
