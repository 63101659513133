import { initialUserConversionDataState, userConversionDataReducer } from './UserConversionData.state';
import { ConversionDataEnum, ConversionDataTypeEnum, EventTypeValue, IDAOEvent, IEvent, IEventDAO, IEventProps, INetwork, ITag } from './UserConversionData.types';
import { useEffect, useReducer, useState } from 'react';
import { ControlContainer, FormLabelStyle, GroupControls } from './components/UserConversionDataEvent.style';
import { Select } from 'components/widgets/select';
import { customerConversionDataOptions } from './UserConversionData.config';
import { selectStyle } from '../../../selectStyle';
import Button from '../../../../widgets/button';
import useUserDataConvertionStyle from './UserConversionData.stayle';
import Dialog from './../../../../widgets/dialog';
import { CustomerConversionDataService } from '../../../../../services/api/customerConversionData.service';
import { TagService } from '../../../../../services/api/tag.service';
import { AddUCDEvent } from './components/AddUCDEvent';
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UserConversionDataEventItem from './components/UserConversionDataEventItem';
import { CONVERSION_EVENT_NAME, THANK_YOU_PAGE } from './utils';
import Toast from '../../../../../services/notifications';
import { useSelector } from 'react-redux';
import { selectPermissions } from '../../../../../redux/slicers/permissions';

const conversionDataSelectStyle = { ...selectStyle, container: { ...selectStyle.container, width: '70%' } };

const commonSelectProps = {
    isMulti: false,
    isDisabled: false,
};

const useStyles = makeStyles({
    root: {
        borderRadius: 4,
        backgroundColor: '#282b3f',

        marginBottom: 7,
        margin: '7px -20px',
    },
});

export const UserConversionData = ({ network }: { network: INetwork }) => {
    const [state, dispatch] = useReducer(userConversionDataReducer, initialUserConversionDataState);
    const [userEvents, setUserEvents] = useState<IEvent>({});
    const [conversionEvent, setConversionEvent] = useState<IEventDAO>({});
    const [tags, setTags] = useState<ITag[]>([]);
    const [isThankYouPage, setThankYouPage] = useState<boolean>(false);
    const [addEvent, setAddEvent] = useState<boolean>(false);
    const classes = useStyles();
    const [expanded, setExpanded] = useState<string>('');
    const [editMode, setEditMode] = useState<boolean>(false);
    // @ts-ignore
    const { account } = useSelector(selectPermissions);
    const userCanEditCED = account && account?.write;
    const UCDClasses = useUserDataConvertionStyle();

    useEffect(() => {
        getTagsAndEvents().then();
    }, []);

    const getTagsAndEvents = async () => {
        const tags = await TagService.getTags(Number(network.id));
        customerConversionDataOptions[ConversionDataEnum.SEARCH_ID] = [...tags];
        setTags(tags);
        tags.map(async (tag: ITag) => {
            const { value: tagId } = tag;
            const event: IDAOEvent = { tagId: tagId, networkId: Number(network.id) };
            const userEvents = await CustomerConversionDataService.getConversionEvents(event);
            const conversionEvents: IEventDAO = await CustomerConversionDataService.getAllEvents(event);
            setUserEvents((s) => ({ ...s, [tagId]: userEvents.events }));
            setConversionEvent((s) => ({ ...s, [tagId]: conversionEvents[tagId] }));
        });
    };

    const handleSubmit = async (e: IEventProps) => {
        setAddEvent(false);
        let event: IDAOEvent = {
            eventType: e.eventType?.value,
            networkId: Number(network.id),
            tagId: e.searchId?.value,
        };
        if (ConversionDataTypeEnum.THANK_YOU_PAGE === state.type?.value) {
            event.type = 2;
            event.event = e.url?.value;
        } else {
            event.type = 1;
            event.event = e.eventName?.value;
        }

        const events = conversionEvent[Number(event.tagId)];
        if (events && events.length > 0 && events[0].type === 1 && events.length === 3) {
            Toast({ message: 'Number of event name limit to 3' });
        } else if (events && events.length > 0 && events[0].type === 2 && events.length === 1) {
            console.log({ events });
            Toast({ message: 'Number of url limit to 1' });
        } else {
            await CustomerConversionDataService.createEvent(event);
            await getTagsAndEvents();
        }
    };
    const onSubmit = async (e: IEventProps) => {
        setAddEvent(false);
        let event: IDAOEvent = {
            eventType: e.eventType?.value,
            networkId: Number(network.id),
            tagId: e.searchId?.value,
        };
        if (ConversionDataTypeEnum.THANK_YOU_PAGE === state.type?.value) {
            event.type = 2;
            event.event = e.url?.value;
        } else {
            event.type = 1;
            event.event = e.eventName?.value;
        }

        await CustomerConversionDataService.createEvent(event);
        await getTagsAndEvents();
    };
    const handleAddEvent = () => {
        setAddEvent(!addEvent);
    };

    const getTagNameById = (id: string) => {
        const tag = tags.find((item: ITag) => item.value === Number(id));
        return tag?.label;
    };
    const handleChange = (tagId: string) => {
        setExpanded(tagId);
    };
    const handleDelete = async (tagId: number, id: number) => {
        const filteredEvents = conversionEvent[tagId]?.filter((event: IDAOEvent) => event.id !== id);
        await CustomerConversionDataService.deleteEvents({ id });
        setConversionEvent((s) => ({ ...s, [tagId]: [...filteredEvents] }));
        await getTagsAndEvents();
    };

    const handleDeleteTagEvents = async (event: IEventProps, tagId: number, type: number) => {
        await CustomerConversionDataService.deleteAllEventsByTagIdAndType({ tagId: Number(tagId), type });
        setConversionEvent((s) => {
            const { [tagId]: temp, ...rest } = s;
            console.log({ rest });
            return rest;
        });
        await getTagsAndEvents();
        await onSubmit(event);
    };
    const handleOnRuleEdit = (flag: boolean) => {
        setEditMode(flag);
    };
    const checkEventByTagId = (tagId: number) => {
        let events: IDAOEvent[] = [];
        if (conversionEvent[tagId]) {
            return conversionEvent[tagId];
        }
        return events;
    };
    return (
        <div className="account-details-container">
            <div className="account-details-section">
                <form>
                    <GroupControls>
                        <p className="cq-heading">Conversion Data</p>
                        <div />
                        {userCanEditCED && (
                            <Button className={UCDClasses.addEventButton} onClick={() => handleAddEvent()} color="primary" style={{}} disabled={false} onEnter={undefined}>
                                Add Event
                            </Button>
                        )}
                    </GroupControls>
                    {addEvent && (
                        <GroupControls>
                            <ControlContainer>
                                <FormLabelStyle
                                    label="Type"
                                    labelPlacement="start"
                                    control={
                                        <Select
                                            styles={conversionDataSelectStyle}
                                            options={customerConversionDataOptions[ConversionDataEnum.TYPE]}
                                            onChange={(selectedOption: EventTypeValue) => {
                                                if (selectedOption.label === THANK_YOU_PAGE.label) setThankYouPage(true);
                                                dispatch({ type: ConversionDataEnum.TYPE, payload: { value: selectedOption } });
                                            }}
                                            value={state?.type}
                                            placeholder="Select Type"
                                            {...commonSelectProps}
                                        />
                                    }
                                />
                            </ControlContainer>
                        </GroupControls>
                    )}
                    <Dialog
                        onClose={() => {
                            setThankYouPage(false);
                        }}
                        onDismiss={() => {
                            const eventValue: EventTypeValue = {
                                value: CONVERSION_EVENT_NAME.value,
                                label: CONVERSION_EVENT_NAME.label,
                            };
                            dispatch({ type: ConversionDataEnum.TYPE, payload: { value: eventValue } });
                        }}
                        isOpen={isThankYouPage}
                        onSubmit={() => {
                            const eventValue: EventTypeValue = {
                                value: THANK_YOU_PAGE.value,
                                label: THANK_YOU_PAGE.label,
                            };
                            dispatch({ type: ConversionDataEnum.TYPE, payload: { value: eventValue } });
                            setThankYouPage(false);
                        }}
                        title={THANK_YOU_PAGE.label}
                        body={`Use only for customer who doesn't use events on conversion actions or doesn't use GTM.`}
                        submitText={`Continue`}
                        dismissText={`Cancel`}
                        Component={undefined}
                        props={undefined}
                    />

                    {addEvent && (
                        <AddUCDEvent
                            key={1}
                            event={userEvents}
                            type={state[ConversionDataEnum.TYPE].value}
                            onSave={handleSubmit}
                            onDelete={handleDeleteTagEvents}
                            checkEvents={checkEventByTagId}
                        />
                    )}
                </form>
                <p className="cq-heading">Conversion Data Events</p>
                {Object.keys(conversionEvent).map((tagId: string) => {
                    return (
                        conversionEvent[tagId] &&
                        conversionEvent[tagId].length > 0 && (
                            <Accordion key={tagId} expanded={expanded === tagId} onChange={() => handleChange(expanded === tagId ? '' : tagId)} className={classes.root}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${tagId}-content`} id={tagId}>
                                    <div>
                                        Search Name: {getTagNameById(tagId)} ({tagId})
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ width: '100%' }}>
                                        {conversionEvent[tagId].map((event: IDAOEvent) => {
                                            return (
                                                <UserConversionDataEventItem
                                                    key={event.id}
                                                    event={event}
                                                    eventList={userEvents[tagId]}
                                                    onEdit={handleOnRuleEdit}
                                                    editMode={editMode}
                                                    handleDelete={handleDelete}
                                                    isPermitted={!!userCanEditCED}
                                                />
                                            );
                                        })}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    );
                })}
            </div>
        </div>
    );
};
