// @ts-nocheck
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import Toast from '../../../services/notifications';
import { selectAccounts } from 'redux/slicers/accounts';
import styles from './styles';
import { selectStyle } from '../selectStyle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { IUser } from 'models/User';
import { IAccount } from 'models/Account';
import InputLabel from '../../widgets/input-label';
import { Select } from '../../widgets/select';
import validator from 'validator';
import { IOrgMember } from 'models/Common';

interface IProps {
    user: IOrgMember | {};
    currentUsers: IOrgMember[];
    onClose: any;
    onAdd?: any;
    onEdit?: any;
    onDelete?: any;
    usersTypes: Array<{ label: string; value: any }>;
    childrensTypes: Array<string>;
    regionOptions: Array<{ label: string; value: any }>;
}

const UserForm: FC<IProps> = ({
    user,
    currentUsers,
    onClose,
    onAdd = () => {},
    onEdit = () => {},
    onDelete = () => {},
    usersTypes = [],
    childrensTypes = [],
    regionOptions = [],
    isDelete = false,
}) => {
    const classes: any = styles();
    const [currentUser, setCurrentUser] = useState(user);
    const { accounts }: { accounts: IAccount[] } = useSelector(selectAccounts);
    const isCreateMode = Object.values(user).length == 0;
    const isChildRole = childrensTypes.includes(user.userTypeName);
    const canEditOrDelete = isCreateMode || isChildRole;

    return (
        <div className={classes.addUserContainer}>
            <AddCircleOutlineIcon className={classes.closeAddUserBtn} onClick={onClose} />
            <div className={classes.inputGroup}>
                <InputLabel
                    disabled={!canEditOrDelete}
                    className={classes.input}
                    value={currentUser.name}
                    type={'text'}
                    onChange={(name) => setCurrentUser((s) => ({ ...s, name }))}
                    placeholder={'name'}
                />
                <InputLabel
                    disabled={!canEditOrDelete}
                    className={classes.input}
                    value={currentUser.username}
                    type={'text'}
                    onChange={(username) => setCurrentUser((s) => ({ ...s, username }))}
                    placeholder={'username'}
                />
            </div>
            <div className={classes.inputGroup}>
                <InputLabel
                    disabled={!canEditOrDelete}
                    className={classes.input}
                    value={currentUser.email}
                    type={'email'}
                    onChange={(email) => setCurrentUser((s) => ({ ...s, email }))}
                    placeholder={'email'}
                />
                <div>
                    <div className={classes.selectLabel}>User Type</div>
                    {canEditOrDelete ? (
                        <Select
                            styles={selectStyle}
                            value={currentUser.userTypeName ? usersTypes.find(({ value }) => value === currentUser.userTypeName) : null}
                            options={usersTypes}
                            placeholder={'Select User Type'}
                            onChange={({ value }) => setCurrentUser((s) => ({ ...s, userTypeName: value }))}
                        />
                    ) : (
                        <InputLabel className={classes.input} value={currentUser.userTypeName} type={'text'} disabled />
                    )}
                </div>
            </div>

            <div className={classes.inputGroup}>
                <div style={{ pointerEvents: isCreateMode ? 'auto' : 'none' }}>
                    <div className={classes.selectLabel}>Region</div>
                    <Select
                        disabled={!canEditOrDelete}
                        styles={selectStyle}
                        value={currentUser.regionId ? regionOptions.find(({ value }) => value === currentUser.regionId) : null}
                        options={regionOptions}
                        placeholder={'Select Region'}
                        onChange={({ value, label }) => setCurrentUser((s) => ({ ...s, regionId: value, regionName: label }))}
                    />
                </div>
            </div>
            <div style={{ width: '100%', textAlign: 'center', opacity: canEditOrDelete ? 'initial' : '0.4' }}>
                {isCreateMode ? (
                    <button
                        onClick={() => {
                            const errors = isValidState(currentUser, user, accounts, currentUsers);
                            if (errors.length) {
                                Toast({ type: 'error', message: errors.shift() });
                            } else {
                                onAdd(currentUser);
                            }
                        }}
                        className={classes.addUserBtn}
                    >
                        Add User
                    </button>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <button
                            disabled={!canEditOrDelete}
                            onClick={() => {
                                const errors = isValidState(currentUser, user, accounts, currentUsers);
                                if (errors.length) {
                                    Toast({ type: 'error', message: errors.shift() });
                                } else {
                                    onEdit(currentUser);
                                }
                            }}
                            className={classes.addUserBtn}
                        >
                            Update User
                        </button>
                        <button
                            disabled={!canEditOrDelete}
                            onClick={() => {
                                const account = accounts.find(({ seAuth0Id, csmAuth0Id }) => `${seAuth0Id}` === `${user.id}` || `${csmAuth0Id}` === `${user.id}`);
                                if (account) {
                                    const role = `${account.seAuth0Id}` === `${user.id}` ? 'SE' : 'CSM';
                                    Toast({
                                        message: (
                                            <div>
                                                {user.username} is {role} for account {account.name}
                                            </div>
                                        ),
                                    });
                                } else {
                                    onDelete();
                                }
                            }}
                            className={classes.deleteUserBtn}
                        >
                            Delete User
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

const isValidState = (state: IOrgMember, user: IOrgMember, accounts: IAccount[], currentUsers: IOrgMember[]) => {
    const errors = [];
    if ((user.userTypeName === 'csm' && state.userTypeName !== 'csm') || (user.userTypeName === 'se' && state.userTypeName !== 'se')) {
        const account = accounts.find(({ seId, csmId }) => String(seId) === String(user.id) || String(csmId) === String(user.id));
        if (account) errors.push(`${user.username} is a ${user.userTypeName} for ${account.name} account`);
    }
    if (!state.name || '?,:;{[}]\\|*/+=)(^%$#!`~'.split('').some((char) => state.name.includes(char))) errors.push(`Invalid name`);
    const { username } = state;
    if (!username || username.length < 1 || username.length > 50 || !new RegExp('^[a-zA-Z0-9_+\\-.!,#$^`~@]*$').test(username)) {
        errors.push(`Invalid user name`);
    }
    if (!state.email || !validator.isEmail(state.email)) errors.push(`Invalid email`);
    if (!state.userTypeName) errors.push(`Invalid user type`);
    if (!state.regionId) errors.push(`Invalid region`);

    // check if the name and email is unique from the other users
    if (currentUsers.some((currUser) => currUser.id !== user.id && currUser.name === state.name)) errors.push('Name is not unique');
    if (currentUsers.some((currUser) => currUser.id !== user.id && currUser.email === state.email)) errors.push('Email is not unique');

    return errors;
};

export default UserForm;
