import instance from './';
import config from '../../config';
const { marketoServiceURL } = config;

export class MarketoService {
    static async getMonitoringMode(networkId) {
        const res = await instance.get(`${marketoServiceURL}/config/v1/monitoringMode/${networkId}`);
        return res.data;
    }

    static async setMonitoringMode(isMonitoringMode, networkId) {
        const res = await instance.put(`${marketoServiceURL}/config/v1/monitoringMode/${networkId}`, {isMonitoringMode});
        return res.data;
    }
}
