import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import lodash from 'lodash'


export default ({className, placeholder, type = 'text', onChange = console.log, disabled, value: inputValue = '', ...props}) => {
    const handleChange = v => onChange(v);
    const classes = useStyle();

    return <div>
        <div className={classes.label}>{lodash.capitalize(placeholder)}</div>
        <input
            className={`${className} ${classes.input} ${inputValue ? classes.notEmpty : ''}`}
            placeholder={placeholder}
            type={type}
            value={inputValue}
            disabled={disabled}
            onChange={({ target: { value } }) => handleChange(value)}
            {...props}
        />
    </div>
}
const useStyle = makeStyles(() => ({
    notEmpty: {
        border: 'solid 1px #fe0072 !important'
    },
    input: {
        borderRadius: 4,
        backgroundColor: 'hsl(0,0%,100%)',
        borderColor: 'hsl(0,0%,80%)',
        borderStyle: 'solid',
        borderWidth: 1,
        paddingLeft: 10,
        '&:focus': {
            outlineColor: '#fe0072'

        }
    },
    label: {
        textAlign: 'left',
        marginLeft: 8,
        fontSize: 14,
        color: '#000'
    }
}))