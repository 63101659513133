import instance from './';
import config from '../../config';

const { iamUrl, iamAdminConsoleOrgId } = config;

function toIamUserModel(params) {
    return {
        id: params.id,
        name: params.name,
        username: params.username,
        email: params.email,
        role: params.userType,
        regionId: params.regionId,
        regionName: params.regionName,
        orgId: iamAdminConsoleOrgId,
    };
}

export class UsersService {
    static baseRoute = `${iamUrl}/user`;
    static organizationBaseRoute = `${iamUrl}/organization`;

    static async createUser(user) {
        const u = toIamUserModel(user);
        const res = await instance.post(`${this.baseRoute}/invite`, { ...u, send_email: true, validateDomain: true });
        return res.data;
    }

    static async updateUser(user) {
        const u = toIamUserModel(user);
        await instance.post(`${this.baseRoute}/edit`, { ...u, validateDomain: true });
    }

    static async updateProfile(user) {
        const u = toIamUserModel(user);
        const res = await instance.post(`${this.baseRoute}/profile`, u);
        return res.data;
    }

    static async deleteUser(user) {
        const u = toIamUserModel(user);
        const res = await instance.delete(`${this.baseRoute}/${u.id}/${u.orgId}`);
        return res.data;
    }

    static async getUserById(userId) {
        const res = await instance.get(`${this.baseRoute}/${userId}`);
        return res.data;
    }

    static async activateUser(id) {
        console.log(`Send request to attach new user: ${id} to activate his account`);
        await instance.put(`${this.baseRoute}/activate`, { id });
    }

    static async addUserToDemoAccounts(user_id) {
        console.log(`Send request to attach new user: ${user_id} to demo accounts`);
        await instance.post(`${this.baseRoute}/attach_to_demo_accounts/${user_id}`);
    }

    static async addUserToAllOgrsInRegion(user_id) {
        console.log(`Send request to attach new user: ${user_id} to all his organizations in his region`);
        await instance.post(`${this.baseRoute}/attach_to_region_accounts/${user_id}`);
    }

    static async addCheqUsersToOrganizations(regionID, orgID) {
        await instance.post(`${this.organizationBaseRoute}/add_cheq_members`, { regionID, orgID });
    }
}
