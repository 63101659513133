import {makeStyles} from "@material-ui/core";

export default makeStyles(theme => ({
    container: {
        // backgroundColor: '#EBECF0',
        display: 'flex',
        height: '94.7vh',
        width: '-webkit-fill-available'
    },
    filters: {
        width: 250,
        height: 630,
        margin: '60px auto',
        padding: 20
    },
    historyTitle: {
        textAlign: 'left',
        color: '#fe0072',
        fontWeight: 'bold',
        textDecoration: 'underline'
    },
    accordionDetails: {
        display: 'block !important'
    },
    accordion: {
        marginTop: 10
    },
    historyProperties: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    loadHistoryReportBtn: {
        padding: 5,
        backgroundColor: '#fe0072',
        color: '#fff',
        outline: 'none',
        border: 'none'
    },
    clearAllBtn: {
        padding: 5,
        backgroundColor: '#fe0072',
        color: '#fff',
        outline: 'none',
        border: 'none',
    },
    clearAllContainer: {
        width: '226px',
        textAlign: 'left',
        margin:'auto'
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content': {
            justifyContent: 'center'
        }
    },
    customDateInputContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 120
    },
    customDateInput: {
        width: 226,
        height: 40,
        margin: 'auto'

    },
    advancedFilteringTitle: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        margin: '20px auto'
    },
    customDateContainer: {
        '& .MuiDialog-paperWidthSm': {
            height: 300,
            width: 400
        },
        '& .MuiDialogContent-root': {
            flex: 'none'
        }
    },
    customDateButtonsContainer: {
        justifyContent: 'space-between',
        margin: 'auto',
        width: '50%'

    },

    historyActionBtn: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    customDateButton: {
      backgroundColor: '#fe0072',
      color: '#fff'
    },

    customDateTitle: {
        '& h2': {
            textAlign: 'center'
        }
    },

    runButton: {
        backgroundColor: '#fe0072',
        color: '#ffffff',
        outline: 'none',
        border: 'none',
        alignSelf: 'center',
        fontSize: 22,
        cursor: 'pointer',
        margin: '40px auto',
        padding: 5
    },
    disabledButton: {
        backgroundColor: 'gray !important'
    },
    report: {
        width: 1370,
        height: 625,
        margin: '60px auto',
        paddingTop: 20,
    },
    selectContainer: {
        margin: '10px auto'
    },
    table: {

    },
    progress: {
        marginTop: 400
    }

}))