import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    accounts: {
        flexGrow: 1,
        marginLeft: 25,
        // height: '99%'
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: 10,
        width: '98%',
    },
    searchContainer: {
        display: 'flex',
        width: 170,
        justifyContent: 'space-between',
    },
    searchButton: {
        height: 24,
        border: 'none',
        backgroundColor: '#fe0072',
        color: '#fff',
        cursor: 'pointer',
        outline: 'none',
    },
    addAccountBtn: {
        height: 24,
        border: 'none',
        backgroundColor: '#fe0072',
        color: '#fff',
        textAlign: 'center',
        // width: 120,
        cursor: 'pointer',
        outline: 'none',
        marginRight: 20,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    nav: {
        alignSelf: 'flex-start',
        display: 'flex',
        cursor: 'pointer',
    },
    createButton: {
        backgroundColor: '#fe0072',
        color: '#ffffff',
        outline: 'none',
        border: 'none',
        height: 38,
        width: 250,
        alignSelf: 'center',
        fontSize: 22,
        cursor: 'pointer',
        margin: '40px auto',
    },
    disabledBtn: {
        backgroundColor: 'gray',
        cursor: 'auto',
    },
    backButton: {
        transform: 'rotate(180deg)',
        color: '#fe0072',
        cursor: 'pointer',
    },
    saveButton: {
        backgroundColor: '#fe0072',
        color: '#ffffff',
        outline: 'none',
        border: 'none',
        borderRadius: '5px',
        padding: ' 0 10px',
        alignSelf: 'center',
        fontSize: 22,
        cursor: 'pointer',
        height: 38,
    },
    form: {
        padding: '0px 30px 10px 30px',
        textAlign: 'left',
        alignSelf: 'center',
        // backgroundColor: 'red',
        width: '40rem',
    },
    accountTypeContainer: {
        display: 'flex',
        margin: 'auto',
        marginLeft: 10,
    },
    inactiveNetwork: {
        backgroundColor: 'gray !important',
    },
    accountTypeRadio: {
        color: '#fe0072 !important',
        padding: '4px 4px 4px 4px',
        '& .MuiSvgIcon-root': {
            height: 20,
            width: 20,
        },
        '& input': {
            height: 20,
            width: 20,
            top: 8,
            left: 4,
        },
    },
    title: {
        display: 'block',
        marginInlineStart: 0,
        marginInlineEnd: 0,
        fontWeight: 'bold',
        paddingTop: 8,
        paddingBottom: 8,
    },
    inputContainer: {
        width: 500,
    },
    inputGroup: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 5,
        width: 500,
    },
    inputNotEmpty: {
        outlineColor: '#fe0072',
    },
    smallInput: {
        width: 212,
        height: 36,
    },
    selectProduct: {
        width: 228,
        height: 26,
    },
    largeInput: {
        width: 900,
        height: 36,
        marginBottom: 5,
    },
    midInput: {
        width: 556,
        height: 36,
        marginBottom: 5,
        marginLeft: 32,
    },

    attention: {
        display: 'flex',
    },
    agencyLabel: {
        display: 'block',
        fontSize: 14,
        marginInlineStart: 0,
        marginInlineEnd: 0,
        fontWeight: 'bold',
        paddingTop: 8,
    },
    agencySwitch: {
        '& .MuiSwitch-track': {
            backgroundColor: 'hsl(0,0%,80%)',
        },
    },
    editBtn: {
        border: 'none',
        backgroundColor: '#fe0072',
        color: '#fff',
        textAlign: 'center',
        cursor: 'pointer',
        outline: 'none',
        fontSize: 24,
        padding: '5px 10px 5px 10px',
        margin: '0px 20px auto',
    },
    accountContainer: {
        width: 600,
        margin: 'auto',
    },
    actionsBtnContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: 20,
        position: 'sticky',
        top: 100,
    },
    accountImg: {
        width: 200,
        height: 100,
        margin: 'auto',
    },
    accountTitle: {
        fontSize: 42,
        fontWeight: 'bold',
    },
    accountProperty: {
        fontSize: 24,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    },
    networkProperty: {
        fontSize: 16,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    },
    accountValue: {
        fontSize: 20,
        marginLeft: 5,
        whiteSpace: 'nowrap',
    },
    networkValue: {
        fontSize: 12,
        marginLeft: 5,
        whiteSpace: 'nowrap',
    },
    groupComponents: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 10,
        marginRight: 10,
    },
    content: {
        margin: 'auto 10%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    networks: {
        width: '100%',
        margin: 'auto 10%',
        paddingBottom: 50,
    },
    accordionPanel: {},
    accordionPanelSummary: {
        '& .MuiAccordionSummary-content': {
            justifyContent: 'center',
        },
        '& .Mui-expanded': {
            '& svg': {
                transform: 'rotate(45deg)',
            },
        },
    },
    accordionPanelDetails: { display: 'block !important' },
    addNetworkBtn: {
        margin: 'auto 0px 0px 10px',
        cursor: 'pointer',
    },
    networkActionBtn: {
        padding: 5,
        backgroundColor: '#fe0072',
        color: '#fff',
        border: 'none',
        outline: 'none',
    },
    networkHeader: {
        margin: '50px 10%',
        marginBottom: 10,
        display: 'flex',
    },
    addNetwork: {
        color: '#fe0072',
        textDecoration: 'underline',
        cursor: 'pointer',
        margin: 'auto',
        fontSize: 20,
        marginLeft: 140,
    },
    gridItem: {
        padding: 0,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        '& .MuiPaper-root': {
            color: '#000',
            position: 'relative',
            display: 'flex',
            padding: 10,
            backgroundColor: '#EBECF0',
        },
    },
    grid: {
        width: '100% !important',
        margin: '50px 0px !important',
        justifyContent: 'space-around',
    },
    pagination: {
        textAlign: 'center',
        width: '90%',
        position: 'absolute',
        top: 600,
    },
    gridContainer: {
        position: 'relative',
    },
    checkboxParent: {
        flex: 1,
    }
}));