import React from "react";
import {Box, Button, Checkbox, Chip, IconButton, Input, MenuItem, Select, TextField} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const TIME_FRAME_OPTIONS = [
    { value: 'minutes', label: 'minutes', minMilliValue: 60000 },
    { value: 'hours', label: 'hours', minMilliValue: 60000 * 60 },
    { value: 'days', label: 'days', minMilliValue: 60000 * 60 * 24 },
    { value: 'weeks', label: 'weeks', minMilliValue: 60000 * 60 * 24 * 7 },
];

const Form = ({
    onSubmit, editMode, globalEditMode, ruleFields, handleOnRuleChange, handleOnDelete, handleOnEditMode, handleOnCancelRule, addRulesComponent
}) => {
    const getTimeFrameOptions = () =>
        TIME_FRAME_OPTIONS.map((item) => (
            <MenuItem key={item.value} value={item.value}>
                {item.label}
            </MenuItem>
        ));

    return (
        <form onSubmit={onSubmit} className={`${'rule-row'} ${!editMode && globalEditMode && 'rule-row-blur'}`}>
            <div className={`${'rule-content'} ${!ruleFields.active && 'rule-row-inactive'}`}>
                <div className="rule-content-left">
                    {!addRulesComponent && <Checkbox disabled={!editMode} checked={!!ruleFields.active} onChange={() => handleOnRuleChange('active', !ruleFields.active)} /> }
                    Up to
                    <Input
                        className="frequency-input-container"
                        color="secondary"
                        inputProps={{ className: 'frequency-input' }}
                        type="number"
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => {
                            handleOnRuleChange('clicks', e.target.value);
                        }}
                        classes={{
                            disabled: ruleFields.active ? 'fc-input-disabled' : ''
                        }}
                        value={ruleFields.clicks}
                        disabled={!editMode}
                        required
                    />
                    clicks in
                    <Input
                        className="frequency-input-container"
                        color="secondary"
                        inputProps={{ className: 'frequency-input' }}
                        type="number"
                        onFocus={(e) => e.target.select()}
                        onChange={(e) => handleOnRuleChange('interval', e.target.value)}
                        classes={{
                            disabled: ruleFields.active ? 'fc-input-disabled' : ''
                        }}
                        value={ruleFields.displayedInterval}
                        disabled={!editMode}
                        required
                    />
                    <Select
                        className="frequency-select"
                        value={ruleFields.timeFrame}
                        onChange={(e) => handleOnRuleChange('timeFrame', e.target.value)}
                        color="secondary"
                        classes={{
                            disabled: ruleFields.active ? 'fc-input-disabled' : ''
                        }}
                        disabled={!editMode}
                        required
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                    >
                        {getTimeFrameOptions()}
                    </Select>
                </div>

                {!editMode && (
                    <div className="rule-content-right">
                        <IconButton onClick={handleOnDelete} disabled={!editMode && globalEditMode}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={handleOnEditMode} disabled={!editMode && globalEditMode}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>
                )}
            </div>

            <div className="campaign-content">
                <div className="campaign-input">
                    <TextField
                        style={{ marginRight: '0.5rem' }}
                        color="secondary"
                        value={ruleFields.campaignInput}
                        onChange={(e) => handleOnRuleChange('campaignInput', e.target.value)}
                        onFocus={(e) => e.target.select()}
                        placeholder="UTM_campaign"
                        disabled={!editMode}
                    />
                    <Button disabled={!ruleFields.campaignInput} onClick={() => handleOnRuleChange('addCampaign', ruleFields.campaignInput)}>
                        add
                    </Button>
                </div>

                {ruleFields.campaignArr && (
                    <div className="chip-wrapper">
                        {ruleFields.campaignArr.map((campaign) => (
                            <Box key={campaign} className="chip-container" mr={1}>
                                <Chip disabled={!ruleFields.active && !editMode} label={campaign} variant="outlined" onDelete={() => handleOnRuleChange('deleteCampaign', campaign)} />
                            </Box>
                        ))}
                    </div>
                )}
            </div>

            {editMode && (
                <div className="button-group">
                    {!addRulesComponent && (
                        <>
                            <div className="button">
                                <Button fullWidth variant="contained" color="secondary" type="submit">
                                    save
                                </Button>
                            </div>
                            <div className="button">
                            <Button fullWidth className="button-text" onClick={handleOnCancelRule}>
                                cancel
                            </Button>
                            </div>
                        </>
                    )}
                    {addRulesComponent && (
                        <div className="button">
                            <Button fullWidth className="button-text" onClick={() => handleOnCancelRule(ruleFields.id)}>
                                delete
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </form>
    )
}

export default Form