import axios from 'axios';
import config from '../../config';
import { getHTTPHeaders } from '../api';
const { baseAPI: baseURL } = config;

export const instance = axios.create({
    baseURL,
    withCredentials: true,
});

instance.interceptors.request.use((config) => {
    config.headers = getHTTPHeaders();
    return config;
});

/**deprecated */
const ajax =
    ({ method = 'post', baseRoute = '', config = null }) =>
    (route, params) => {
        return instance[method](`${baseRoute}/${route}`, params, config)
            .then(({ data }) => ({ data }))
            .catch((e) => {
                const { response: { status = 503, data: message = 'General Error' } = {} } = e;
                return { error: { status, message } };
            })
            .then(({ error, data }) => (error ? Promise.reject(error) : data));
    };

export default ajax;

export class Ajax {
    constructor({ method = 'post', baseRoute = '', config }) {
        this.method = method;
        this.baseRoute = baseRoute;
        this.config = config;
        this.loads = [];
        this.instance = axios.create({
            baseURL,
            withCredentials: true,
        });
        this.instance.interceptors.request.use((config) => {
            config.headers = getHTTPHeaders();
            return config;
        });
    }

    isLoading(route) {
        return route ? this.loads.includes(route) : !!this.loads.length;
    }

    request(route, params) {
        this.loads.push(route);
        return this.http[this.method](`${this.baseRoute}/${route}`, params, config)
            .then(({ data }) => {
                this.loads = this.loads.filter((r) => r !== route);
                return { data };
            })
            .finally(() => {
                this.loads = this.loads.filter((r) => r !== route);
            });
    }
}
