import {useEffect} from 'react';
import {UsersService} from '../services/api/users.service'
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { addUserToAccountSupporters } from 'redux/slicers/accounts';
import {ACCOUNT_SUPPORTER_ROLES} from '../constants' 
import { IAccountSupporterRequest } from 'models/AccountSupporterRequest';

export const useFirstLogin = ()=>{
const { getAccessTokenSilently,isAuthenticated,user } = useAuth0();
const dispatch = useDispatch()

const userRoles = user && user[`${window.location.origin}/roles`];
const role = userRoles && userRoles[0]
const userMeta = user && user[`${window.location.origin}/meta`];
const {id,firstLogin,name,email} = userMeta || {}
const isFirstLogin = isAuthenticated && firstLogin;

   useEffect(()=>{
        (async()=>{
            if (isFirstLogin){
                let token = await getAccessTokenSilently()
                window.localStorage.setItem('auth0Token', token)

                await UsersService.activateUser(id)

                if (ACCOUNT_SUPPORTER_ROLES.includes(role)){
                    const body:IAccountSupporterRequest = {id,name,role,email}
                    /* @ts-ignore */
                    dispatch(addUserToAccountSupporters(body))
                }
              
                UsersService.addUserToDemoAccounts(id)
                UsersService.addUserToAllOgrsInRegion(id)

                token = await getAccessTokenSilently()
                window.localStorage.setItem('auth0Token', token)
            }
        })()
    },[isFirstLogin])
}