import Toast from "../notifications";
import {getErrorMessage} from '../../errors-codes'

export const isLoadingSlice = slice => params => (dispatch, getState) => {
    const {[slice]: {loading}} = getState();
    return params ? loading.includes(params) : !!loading.length;
};


export const thunkPending = typePrefix => (state) => void(!state.loading.includes(typePrefix) && state.loading.push(typePrefix));

export const thunkRejected = typePrefix => (state, {error, meta: {requestId }}) => {
    if (state.loading.includes(typePrefix)) {
        state.loading = state.loading.filter(l => l !== typePrefix);
        Toast({message: getErrorMessage(error.message)});
    }
}