import { ConversionDataEnum, ConversionDataTypeEnum, ICustomerConversionDataOptions } from './UserConversionData.types';

export const customerConversionDataOptions: ICustomerConversionDataOptions = {
    [ConversionDataEnum.TYPE]: [
        { label: 'Conversion Event Name', value: ConversionDataTypeEnum.CONVERSION_EVENT_NAME },
        { label: 'Thank You page', value: ConversionDataTypeEnum.THANK_YOU_PAGE },
    ],
    [ConversionDataEnum.SEARCH_ID]: [],
    [ConversionDataEnum.EVENT_NAME]: [],
    [ConversionDataEnum.EVENT_TYPE]: [
        { label: 'Add to cart', value: 'addToCart' },
        { label: 'Add to Wishlist', value: 'addToWishList' },
        { label: 'Initiate checkout', value: 'initiateCheckout' },
        { label: 'Purchase', value: 'purchase' },
        { label: 'Subscribe', value: 'subscribe' },
        { label: 'Complete registration', value: 'completeRegistration' },
        { label: 'Lead / Enter contact Info', value: 'leadEnterContactInfo' },
        { label: 'View content', value: 'viewContent' },
        { label: 'Other', value: 'other' },
    ],
    [ConversionDataEnum.URL]: '',
};
