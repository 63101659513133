import React, { useEffect } from 'react';
import styles from './style.module.css';

export default ({ style, children, disabled, onEnter, onClick = () => {}, className, ...props }) => {
    useEffect(buildEnterHandler({ onEnter, onClick }));

    return (
        <div style={style} className={`${className} ${styles['container']} ${disabled && styles['disabled']}`}>
            <input value={children} type="button" onClick={() => !disabled && onClick()} {...props} />
        </div>
    );
};

const buildEnterHandler = (handlerData) => () => {
    const handler = enterHendler(handlerData);
    window.addEventListener('keyup', handler);
    return () => {
        window.removeEventListener('keyup', handler);
    };
};

const enterHendler = ({ onEnter, onClick }) =>
    function ({ keyCode }) {
        onEnter && keyCode === 13 && onClick();
    };
