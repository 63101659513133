import './App.css';
import './fonts.css';
import 'react-toastify/dist/ReactToastify.css';

import React, { useEffect } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { initPermissions, selectPermissions } from 'redux/slicers/permissions';
import { initProfile } from 'redux/slicers/profile';
import { useDispatch, useSelector } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
import Main from './components/layouts/main';
import Progress from '../src/components/widgets/progress';
import Theme from './theme';
import { ThemeProvider } from '@material-ui/styles';
import { ToastContainer } from 'react-toastify';
import config from './config';
import { useAuth0 } from '@auth0/auth0-react';
import Maintenance from './components/containers/maintenance';
import { useLookUps, useFirstLogin } from './hooks';
import { useSplit } from '../src/hooks/useSplit';
import { SPLIT_CONSTANTS } from '../src/services/split.io/constants';

export function App() {
    useLookUps();
    useFirstLogin();
    useSelector(selectPermissions);
    const dispatch = useDispatch();
    const { isLoading, isAuthenticated, user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    // const { isOn: showMaintenancePage } = useSplit(SPLIT_CONSTANTS.SPLIT_MAINTENANCE_PAGE);
    const showMaintenancePage = false;
    const isInvitation = location.pathname.split('/')[1] === 'login';
    useEffect(() => {
        (async () => {
            if (isLoading) return;

            const url = window.location.href;
            const inviteMatches = url.match(/invitation=([^&]+)/);
            const orgMatches = url.match(/organization=([^&]+)/);
            if (inviteMatches && orgMatches) {
                return loginWithRedirect({
                    organization: orgMatches[1],
                    invitation: inviteMatches[1],
                });
            }

            if (isAuthenticated) {
                getAccessTokenSilently()
                    .then((token) => {
                        window.localStorage.setItem('auth0Token', token);
                        if (user) {
                            const meta = user[`${window.location.origin}/meta`];
                            // using the store without getting the permissions from server
                            const initialPermissionsObject = { userType: 'console_viewer', permissions: meta?.permissions };
                            dispatch(initProfile(user, meta));
                            dispatch(initPermissions(initialPermissionsObject));
                        }
                    })
                    .catch((e) => console.error(e));
            } else {
                await loginWithRedirect({ organization: config.iamAdminConsoleOrgId });
            }
        })();
    }, [isLoading, isAuthenticated, user]);

    return (
        <HttpsRedirect disabled={!!config.disableSSL}>
            <div className="App">
                <ToastContainer />
                {/* the user is authenticated, auth0 finished loading and we have a valid user*/}
                {!isInvitation && isAuthenticated && user && !isLoading ? (
                    showMaintenancePage ? (
                        <Maintenance />
                    ) : (
                        <ThemeProvider theme={Theme}>
                            <Router>
                                <Switch>
                                    <Route
                                        path={'/:tab'}
                                        render={(props) => {
                                            return <Main history={props.history} />;
                                        }}
                                    />
                                    <Route
                                        path={'*'}
                                        render={(props) => {
                                            return <Redirect to={{ pathname: '/accounts', state: props.location.pathname }} />;
                                        }}
                                    />
                                </Switch>
                            </Router>
                        </ThemeProvider>
                    )
                ) : (
                    <div className="app-load">
                        <Progress style={{ verticalAlign: 'center' }} />
                    </div>
                )}
            </div>
        </HttpsRedirect>
    );
}
