import React from 'react';
import lodash from 'lodash';
import { isLoading, selectAccounts } from 'redux/slicers/accounts';
import { useDispatch, useSelector } from 'react-redux';
import Progress from '../../widgets/progress';
import Alert from '../../svgs/alert';
import useStyle from './styles';
import NetworkList from './NetworkList';
import { selectPermissions } from 'redux/slicers/permissions';
import { useHistory } from 'react-router-dom';

const InfoTab = ({ accountId, account, networks }) => {
    const classes = useStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const permissions = useSelector(selectPermissions);
    const { regions = [], products = [], accountTypes = [], bundlePlans = [], mediaSpendTiers = [], csms = [], ses = [] } = useSelector(selectAccounts);
    const { csms: csmsLookup, ses: sesLookup } = useSelector((state) => state.lookUps);
    const license = useSelector((state) => state.accounts.license[accountId]);
    const userCanWriteNetwork = account && permissions.network && permissions.network.write;

    const getNameFromId = (options, id, formater = (str) => str) => formater(options.reduce((found, current) => (current.id === id ? current.name : found), ''));
    const getEmailFromId = (options, id) => options.reduce((found, current) => (current.id === id ? current.email : found), '');

    const AccountProperty = ({ property, value }) => {
        return value && value.length > 1 ? (
            <div className="field-group">
                <div className="field-key">{property}</div>
                {value}
            </div>
        ) : (
            <div className="field-group">
                <div className="field-key">{property}</div>
                <div className="field-value">{value ? value : 'N/A'}</div>
            </div>
        );
    };

    const CheqInfo = ({ account, networks, loading, classes }) => {
        if (!account || account.isAgency) return <></>;

        const whiteLabelDomain = Array.isArray(networks) && networks[0]?.whiteLabelDomain ? networks[0].whiteLabelDomain : undefined;
        const servingDomain = whiteLabelDomain?.domain;
        const location = Array.isArray(networks) && networks[0]?.dataLocation ? networks[0].dataLocation : undefined;
        return (
            <>
                <p className="cq-heading">CHEQ INFO</p>
                {loading || !whiteLabelDomain ? (
                    <div className="field-group">
                        <Progress />
                    </div>
                ) : (
                    <>
                        <AccountProperty property={'Data Location'} value={location} />
                        <AccountProperty property={'Serving Domain'} value={servingDomain} />
                    </>
                )}

                {whiteLabelDomain?.tainted ? (
                    <div className={classes.attention}>
                        <Alert style={{ marginRight: 5, marginTop: 5 }} />
                        <div className="notice text-muted" style={{ fontSize: 14 }}>
                            This domain is blocked by Add-Blockers
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </>
        );
    };

    const canAddNetwork = () => {
        const { brands } = bundlePlans.find(({ id }) => id === account?.bundlePlan) || 0;
        return account?.isAgency && account?.active && userCanWriteNetwork && !dispatch(isLoading('getAssociatedNetworks')) && (networks || []).length < brands;
    };

    const onAddNetwork = () => {
        history.push(`/accounts/networks/${account.id}/create`);
    };

    const renderAddNetworkButton = () => {
        if (canAddNetwork()) {
            return (
                <button className="cq-btn" onClick={onAddNetwork}>
                    ADD BRAND
                </button>
            );
        }
        return <></>;
    };

    const renderBrands = () => {
        if (!account?.isAgency) {
            return <></>;
        }
        if (dispatch(isLoading('getAssociatedNetworks')) || dispatch(isLoading('createNetwork')) || dispatch(isLoading('deleteNetwork')) || dispatch(isLoading('updateNetwork'))) {
            return <Progress />;
        }
        if ((networks || []).length > 0) {
            return (
                <div>
                    <div className="account-netwrok-heading">
                        <span className="cq-heading">
                            <b>ACCOUNT</b> BRANDS
                        </span>
                        <span>{renderAddNetworkButton()}</span>
                    </div>
                    <NetworkList account={account} networks={networks} />
                </div>
            );
        }
        return <></>;
    };

    return (
        <div>
            <div className="account-details-container">
                <div className="account-details-section">
                    <p className="cq-heading">CONTACT INFO</p>
                    <AccountProperty property={'Email'} value={account?.email} />
                    <AccountProperty property={'Domain'} value={account?.domain} />
                    <AccountProperty property={'Billing Address'} value={account?.billingAddress} />
                    <AccountProperty property={'City'} value={lodash.capitalize(account?.city)} />
                    <AccountProperty property={'Country'} value={(account?.country || '').toUpperCase()} />
                    <AccountProperty property={'Zip Code'} value={account?.zipCode} />
                    <AccountProperty property={'Phone Number'} value={account?.phone} />
                    <CheqInfo account={account} networks={networks} loading={dispatch(isLoading('getAssociatedNetworks'))} classes={classes} />
                </div>
                <div className="account-details-section">
                    <p className="cq-heading">PLAN INFO</p>
                    <AccountProperty property={'Plan'} value={getNameFromId(bundlePlans, account?.bundlePlan)} />
                    <AccountProperty property={'End Date'} value={account?.endDate} />
                    <AccountProperty property={'CSM'} value={csmsLookup[account.csmAuth0Id]?.email} />
                    <AccountProperty property={'SE'} value={sesLookup[account.seAuth0Id]?.email} />
                    <AccountProperty property={'Agency'} value={account?.isAgency ? 'Yes' : 'No'} />
                    <AccountProperty property={'Account Type'} value={lodash.capitalize(getNameFromId(accountTypes, account?.accountType))} />
                    <AccountProperty property={'Region'} value={getNameFromId(regions, account?.regionId, (str) => str.toUpperCase())} />
                    <AccountProperty property={'Budget'} value={getNameFromId(mediaSpendTiers, account?.mediaSpendTierId)} />
                </div>
                {license?.products !== undefined && (
                    <div className="account-details-section">
                        <p className="cq-heading">
                            <b>LICENSE</b> INFO
                        </p>

                        <AccountProperty
                            property={'Products'}
                            value={license?.products?.map((product, i) => (
                                <div key={i}>{getNameFromId(products, product.id, (str) => str.charAt(0).toUpperCase() + str.substr(1))} </div>
                            ))}
                        />
                    </div>
                )}
            </div>
            {renderBrands()}
        </div>
    );
}

export default InfoTab;