import instance from '../../../../services/api';

export const updateIsSsoEnabled = async (networkId, isSSOLoginEnabled) => {
    try {
        await instance.post(`api/v2/network/update_sso_login_enabled/`, {networkId,isSSOLoginEnabled});
    } catch (error) {
        throw error;
    }
}

export const getNetworkSettings = async (networkId) => {
    try {
        const res = await instance.get(`api/v2/network/get_sso_login_enabled/?networkId=${networkId}`);
        return res.data;
    } catch (error) {
        throw error;
    }
}