import api from './';
import { IDAOEvent, IEventDAO } from '../../components/containers/accounts/tabs/UserConversionData/UserConversionData.types';
import Toast from '../../services/notifications';

export class CustomerConversionDataService {
    static baseRoute = 'user_conversion_data';

    static async getConversionEvents(inputEvent: IDAOEvent) {
        const { tagId, networkId } = inputEvent;
        const res = await api.get(`${this.baseRoute}/conversionData?tagId=${tagId}&networkId=${networkId}`);
        return res.data;
    }

    static async getAllEvents(inputEvent: IDAOEvent) {
        const { tagId, networkId } = inputEvent;
        const res = await api.get<IEventDAO>(`${this.baseRoute}/conversionEvent?tagId=${tagId}&networkId=${networkId}`);
        return res.data;
    }

    static async createEvent(inputEvent: IDAOEvent) {
        return api
            .post(`${this.baseRoute}/conversionEvent`, { ...inputEvent })
            .then((data) => data)
            .catch((error) => {
                Toast({ message: `The request failed - ${error.response.data.replaceAll('_', ' ').toLowerCase()}` });
            });
    }

    static async updateEvents(inputEvent: IDAOEvent) {
        const { id, tagId, networkId, type, event, eventType } = inputEvent;
        return api
            .put(`${this.baseRoute}/conversionEvent`, { id, tagId, networkId, type, event, eventType })
            .then((data) => data)
            .catch((error) => {
                Toast({ message: `The request failed - ${error.response.data.replaceAll('_', ' ').toLowerCase()}` });
            });
    }

    static async deleteEvents(inputEvent: IDAOEvent) {
        const { id } = inputEvent;
        return api
            .delete(`${this.baseRoute}/delConversionEvent?id=${id}`)
            .then((data) => data)
            .catch((error) => {
                Toast({ message: `The request failed - ${error.response.data.replaceAll('_', ' ').toLowerCase()}` });
            });
    }
    static async deleteAllEventsByTagIdAndType(inputEvent: IDAOEvent) {
        const { tagId, type } = inputEvent;
        return api
            .delete(`${this.baseRoute}/delConversionEventsByTag?tagId=${tagId}&type=${type}`)
            .then((data) => data)
            .catch((error) => {
                Toast({ message: `The request failed - ${error.response.data.replaceAll('_', ' ').toLowerCase()}` });
            });
    }
}
