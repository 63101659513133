import React, { useState, useEffect } from 'react';
import moment from 'moment'
import lodash from 'lodash'
import {
    Table as MuiTable,
    TableCell,
    TableHead,
    Paper,
    TableBody,
    TableRow,
    TablePagination,
    IconButton,
    makeStyles
} from "@material-ui/core";
import {
    FirstPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
    ArrowUpward,
    ArrowDownward
} from "@material-ui/icons";
import TableSortLabel from "@material-ui/core/TableSortLabel/TableSortLabel";
import {ArrowDropDown,ArrowDropUp} from '@material-ui/icons';
import Pagination from '../pagination/index';
import Search from '@material-ui/icons/esm/Search';
import SearchBox from '../search-box/index';
import Progress from '../progress/index';
import NoData from '../no-data/index';


const invertDirection = {
    'asc': 'desc',
    'desc': 'asc'
};

const Table =  function ({
    name,
    className,
    dataKey,
    columns = [],
    width = '100%',
    search = 'footer',
    headCellPadding = 'default',
    bodyCellPadding = 'default',
    rows = [],
    RenderHead = ({ column }) => <div>{column.label}</div>,
    RenderBody = ({ value }) => <div>{value}</div>,
    pagination = true,
    lineaCount = false,
    stickyHeader = true,
    style = {},
    sortable = true,
    Actions = [],
    size = 'medium',
    alignHead = 'left',
    alignBody = 'left',
    buildColumns,
    getOrderBy = _getOrderBy,
    justifyHeaderContent = 'center',
    totalRow,
    isLoading = false,
    RenderEmpty = props => <NoData {...props}/>,
    isError = '',
     RenderError = props => <div style={{color:'red'}}>something went wrong...</div>,
    showHeadersOnEmpty = false,
    rowsPerPage = 10,
    ...rest
}) {
    const classes = makeStyles(() => style)();

    const _columns = columns.length ? columns.map(parseColumn) : (Object.keys(rows[0] || {}).map(parseColumn));
    const [page, setPage] = useState(0);
    const [sortingColumn, setSortingColumn] = useState({});
    const [sortDirection, setSortDirection] = useState('desc');
    const [filterSearch, setFilterSearch] = useState('');
    const prevRowsCountRef = React.useRef();
	const filterRows = rowValues => filterSearch
        ? rowValues.some(value => ![undefined, null].includes(value) && String(value).toLowerCase().includes(filterSearch.toLowerCase()))
        : true;
	const getDisplayRows = () => {
		const sortableRows = sortable ? rows.slice().sort(getOrderBy(sortingColumn.id, sortDirection)) : rows;
		const searchedRows = filterSearch ? sortableRows.filter(row => filterRows(Object.values(lodash.mapValues (row,(val,key,src)=>{
			const matchedColumn = _columns.find(({id})=> id === key);
			if(matchedColumn && !matchedColumn.notSearchable){
				return !matchedColumn.searchTransform ? val : matchedColumn.searchTransform(val)
			}
		})))) : sortableRows;

		return searchedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
	}
    const displayRows = getDisplayRows();

	React.useEffect(() => setPage(0), [dataKey])

    React.useEffect(() => {
        const displayRowsLength = getDisplayRows().length; //might be a better solution from re-running getDisplayRows
        if(!displayRowsLength && rows.length ){
            setPage(Math.max(0, page - 1))
        }
        if(displayRowsLength === rowsPerPage && prevRowsCountRef.current && prevRowsCountRef.current < rows.length) {
            setPage(getPages() - 1)
        }
        prevRowsCountRef.current = rows.length
    },[displayRows.length, rows.length]);







    const getSortingIcon = columnId => {
        if (columnId === sortingColumn.id && sortable) {
            return sortDirection === 'desc' ? <ArrowDownward /> : <ArrowUpward />
        }
    };

    const handelSort = column => {
	    setPage(0);
        setSortingColumn(column);
        setSortDirection(sortingColumn && sortingColumn.id === column.id ? invertDirection[sortDirection] : 'asc')
    };

    const getActions = (type, row) => {
        switch (type) {
            case 'head':
                return Actions.length === 0 ? null : (
                    <TableCell padding={headCellPadding} variant={'head'} style={style.headCell}>
                        ACTIONS
                    </TableCell>
                )
            case 'body':
                return Actions.length === 0 ? null : (
                    <TableCell style={style.bodyCell} padding={bodyCellPadding}>
                        <div style={style.actions}>
                            {Actions.map(Action => Action(row))}
                        </div>
                    </TableCell>
                )
            default:
                return null
        }
    }

    const getLineCount = (type, count) => {
        if (lineaCount)
            switch (type) {
                case 'head':
                    return <TableCell padding={headCellPadding} variant={'head'} style={style.headCell} />;

                case 'body':
                    return !lineaCount ? null : <TableCell style={style.bodyCell} padding={bodyCellPadding}>
                        {count}
                    </TableCell>
            }
    }

    const noResultBecauseOfSearch = filterSearch && rows.length > 0 && displayRows.length === 0;
    const getPages = () => Math.ceil(rows.filter(row => filterRows(Object.values(lodash.mapValues(row,(val,key,src)=>{
        const matchedColumn = _columns.find(({id})=> id === key);
        if(matchedColumn && !matchedColumn.notSearchable){
            return !matchedColumn.searchTransform ? val : matchedColumn.searchTransform(val)
        }
    })))).length / rowsPerPage)
    //'rgb(56, 56, 81)'
    return (
        <div
            style={{width: '100%', height:'100%', position:'relative', backgroundColor: '#f5f5f5'}}
            className={classes.root}
        >
            <div className={classes.tableWrapper}>
                {
                    (!showHeadersOnEmpty && isLoading) ?
                        <div style={{lineHeight: '190px', textAlign:'center'}}><Progress/></div>
                    :
                        (!showHeadersOnEmpty && isError) ?
	                        <RenderError/>
                        :

                        <div className={classes.tableBody}>
                        {(!showHeadersOnEmpty && rows.length === 0 ) ?
                            <RenderEmpty {...rest}/>
                            :
                            <>
                                {(search === 'header' &&   rows.length > 0 ) &&
                                    <span style={{position: 'absolute', right: 10, top: 8}}>
                                        <SearchBox
                                            placeholder={'Search'}
                                            height={24}
                                            width={106}
                                            onChange={term => {
                                                setFilterSearch(term);
                                                setPage(0)
                                            }}
                                            value={filterSearch}
                                        />
                                    </span>
                                }
                                    <MuiTable size={size} style={style.table} className={className}>
                                    <TableHead style={style.head}>
                                    <TableRow
                                        key='head'
                                        style={{position: 'sticky', ...style.headerRow}}
                                    >
                                    {getActions('head')}
                                    {getLineCount('head')}
                                    {_columns.map((column, idx) => {
                                            return (
                                                <TableCell key={idx}
                                                           padding={headCellPadding}
                                                           className={classes.head}
                                                           variant={'head'}
                                                           align={alignHead}
                                                           style={style.headerCell}

                                                           sortDirection={sortingColumn.id && sortingColumn.id === column.id ? sortDirection : false}
                                                >
                                                    {((!showHeadersOnEmpty || rows.length !== 0) && sortable && !column.notSortable)
                                                        ? <TableSortLabel
                                                            active={sortingColumn.id && sortingColumn.id === column.id}
                                                            direction={sortDirection}
                                                            onClick={sortable ? () => handelSort(column) : () => {
                                                            }}
                                                        >
                                                            {!!(!showHeadersOnEmpty || rows.length !== 0) && <RenderHead idx={idx} column={column} {...rest}/>}
                                                            {sortingColumn.id === column.id ? (
                                                                <span style={{

                                                                    border: 0,
                                                                    clip: 'rect(0 0 0 0)',
                                                                    height: 1,
                                                                    margin: -1,
                                                                    overflow: 'hidden',
                                                                    padding: 0,
                                                                    position: 'absolute',
                                                                    width: 1,
                                                                }}>
                                                                            {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                                        </span>
                                                            ) : null}
                                                        </TableSortLabel>
                                                        : !!(!showHeadersOnEmpty || rows.length !== 0) && <RenderHead idx={idx} column={column} {...rest}/>
                                                    }
                                                </TableCell>
                                            )
                                        }
                                    )}
                                    </TableRow>
                                    </TableHead>

                                    <TableBody>
                                    {!displayRows.length ? null : displayRows.map((row, idx) => (
                                        <TableRow hover key={idx} style={!style.oddRow ? style.row : (idx % 2 === 0 ? style.row : style.oddRow)}>

                                            {getActions('body', row)}

                                            {getLineCount('body', ++idx)}

                                            {_columns.map((column, i) => {
                                                return (
                                                    <TableCell
                                                        align={alignBody}
                                                        key={column.id} className={classes.bodyCell}
                                                        padding={bodyCellPadding}
                                                    >
                                                        <RenderBody
                                                            value={row[column.id]}
                                                            column={column}
                                                            row={row}
                                                            filterSearch={filterSearch}
                                                            rowIndex={idx}
                                                            {...rest}
                                                        />
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>))
                                    }
                                    {!totalRow || !displayRows.length
                                        ? null
                                        : <TableRow hover key={'total'} style={style.totalRow}>

                                            {_columns.map((column, i) => {
                                                return (
                                                    <TableCell
                                                        align={alignBody}
                                                        key={column.id} className={classes.bodyCell}
                                                        padding={bodyCellPadding}>
                                                        <RenderBody
                                                            value={i === 0 ? 'TOTAL' : totalRow[column.id]}
                                                            column={column}
                                                            row={totalRow}
                                                            filterSearch={filterSearch}
                                                            isTotal={true}
                                                        />
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>}
                                    </TableBody>
                                </MuiTable>
                            </>
                        }
                        {noResultBecauseOfSearch &&
                        <div style={{fontSize:12,marginTop:'20%', textAlign: 'center'}}>
                            <span>no results for search term</span>
                            <div style={{fontStyle:'italic'}}>'{filterSearch}'</div>
                        </div>
                        }
                        </div>
                    }
            </div>
	        {
		        (showHeadersOnEmpty && isLoading) ?
                    <div style={{lineHeight: '190px', textAlign: 'center'}}><Progress/></div>
			        :
			        (showHeadersOnEmpty && isError) ?
                        <RenderError/>
				        :
				        (showHeadersOnEmpty && rows.length === 0) ?
                            <RenderEmpty {...rest}/>
					        :
					        null
	        }
	        {!!((pagination || search === 'footer') && rows.length > 0) &&
            <div  style={{backgroundColor: '#14172c', height: 39, width: '100%', textAlign: 'left',position:'absolute',bottom:0, ...style.tableFooter}}>
		        {
			        !!(pagination && displayRows.length && rows.length) &&
                    <Pagination
                        page={page}
                        setPage={setPage}
                        pages={getPages()}
                        style={{position: 'absolute', bottom: 6, ...style.pagination}}
                    />
		        }

		        {search === 'footer' && (displayRows.length > 0 || rows.length)
			        ?
                    <div style={{position:'absolute', bottom:7, right:24, width:106,...style.search}}>
                        <SearchBox
                            placeholder={'Search'}
                            onChange={term => {setFilterSearch(term); setPage(0)}}
                            value={filterSearch}
                            height={24}
                            width={106}
                        />
                    </div>

			        : null
		        }
            </div>
	        }

        </div>
    )
};

function _getOrderBy(key, direction) {
    return (a, b) => {
        const asc = direction === 'asc';
        if (moment(a[key], "YYYY-MM-DD HH:mm:ss.SSS", true).isValid()
            || moment(a[key], "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid() //2021-01-05T06:28:03.000Z
            || moment(a[key], "YYYY-MM-DD HH:mm", true).isValid()
            || moment(a[key], "YYYY-MM-DD HH", true).isValid()
            || moment(a[key], "YYYY-MM-DD", true).isValid()
            || moment(a[key], "YYYY-MM", true).isValid()) {
            return asc ?
                moment(a[key]).isBefore(b[key]) ? -1 : 1
                : moment(a[key]).isAfter(b[key]) ? -1 : 1
        }
        if (!isNaN(parseFloat(a[key]))) {
            return asc ? a[key] - b[key] : b[key] - a[key];
        }
        if (typeof a[key] === 'string' && typeof b[key] === 'string' ) {
            return asc ? a[key].toUpperCase() < b[key].toUpperCase() ? -1 : 1
                : a[key].toUpperCase() > b[key].toUpperCase() ? -1 : 1;
        }
        return asc ? a[key] < b[key] ? -1 : 1
            : a[key] > b[key] ? -1 : 1;


    }
}


function parseColumn(columnData) {
    const warn = msg => console.warn(msg + ' prepare for unexpected behaviour from ManageTable');

    const fromString = id => ({
        id, label: lodash.startCase(id)
    });

    const fromObject = object => ({
        ...fromString(object.id),
        ...object
    });

    switch (typeof columnData) {
        case 'string':
            !columnData && warn('columnData is undefined.');
            return fromString(columnData);
        case 'object':
            !columnData.id && warn('columnData.id must be typeof string.');
            return fromObject(columnData);
        default:
            warn('columnData must be typeof string or object.');
            return columnData;
    }
}

export default Table;