import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { App } from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import store from './redux/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import config from './config';
import InitSplitProvider from './contexts/initSplit';

console.log('config.iamDomain: ', config.iamDomain);
ReactDOM.render(
    <Auth0Provider domain={config.iamDomain} clientId={config.iamClientId} redirectUri={window.location.origin} audience={config.iamAudience}>
        <React.StrictMode>
            <Provider store={store}>
                <InitSplitProvider>
                    <App />
                </InitSplitProvider>
            </Provider>
        </React.StrictMode>
    </Auth0Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
