import React from 'react';

export default function Pagination({ page, setPage, pages = 1, style = {}, maxPagesToShow = 5, ...params }) {
    //TODO IMPLEMENT OFFSET PAGES 1,...,14,15,16,...,30

    return (
        <div onMouseDown={(e) => e.preventDefault()} style={style} {...params}>
            <Navigation direction={'Previous'} disabled={page === 0} setPage={() => setPage((page) => Math.max(page - 1, 0))} />
            <Pages page={page} pages={pages} setPage={setPage} maxPagesToShow={maxPagesToShow} />
            <Navigation direction={'Next'} disabled={page + 1 >= pages} setPage={() => setPage((page) => Math.min(page + 1, pages - 1))} />
        </div>
    );
}

function Navigation({ direction, setPage, disabled }) {
    return (
        <div
            onClick={() => !disabled && setPage()}
            style={{
                display: 'inline-block',
                height: '100%',
                marginRight: 10,
                cursor: disabled ? 'default' : 'pointer',
                color: disabled ? '#f5f5f5' : '#fe0072',
                fontSize: 13,
                verticalAlign: 'sub',
            }}
        >
            {direction}
        </div>
    );
}

function Pages({ pages, page, setPage, maxPagesToShow }) {
    //todo, figure out the fix to display correct number of pages where pageNumber is closing to end
    let start, end;
    // if(page === 0){ //in first page
    //     start = 0
    // }else if(page === pages-1){ //in last page
    //    start = pages - maxPagesToShow;
    // }else{
    const shouldStartFrom = page - Math.floor(maxPagesToShow / 2);
    start = shouldStartFrom < 0 ? 0 : shouldStartFrom;
    // const shouldEndOn = page + Math.floor(maxPagesToShow/2);
    // end = shouldEndOn >= pages ? pages  : shouldEndOn;
    // }

    const array = new Array(pages);
    for (let pageNumber = start; pageNumber < start + maxPagesToShow && pageNumber < pages; pageNumber++) {
        array[pageNumber] = (
            <div
                style={{
                    width: 33,
                    height: 30,
                    fontSize: 13,
                    backgroundColor: page === pageNumber ? '#282b3f' : 'inherit',
                    color: page === pageNumber ? '#fff' : '#fe0072',
                    cursor: 'pointer',
                    display: 'inline-block',
                    lineHeight: '30px',
                    textAlign: 'center',
                    position: 'relative',
                    top: 4,
                }}
                key={pageNumber}
                onClick={() => setPage(pageNumber)}
            >
                {pageNumber + 1}
            </div>
        );
    }
    return array;
}
