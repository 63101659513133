import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { UsersService } from 'services/api/users.service';
import {
    isLoadingSlice,/*, thunkPending, thunkRejected&*/
    thunkPending,
    thunkRejected
} from 'services/redux';
import ajaxRequest from 'services/ajax';
import config from 'config';
import { InitUsersFullfiledValue, IUsersState } from './types';
import { IOrgMember, IOrgMemberCRUDRequest } from 'models/Common';
import { RootState } from 'redux/store';
import { AppDispatch } from 'redux/store';
const { iamUrl, iamAdminConsoleOrgId } = config;

const ajax: (route: string, params?: any) => Promise<IOrgMember[]> =
    ajaxRequest({ method: 'post', baseRoute: `${iamUrl}` });

const createAppAsyncThunk = createAsyncThunk.withTypes<{
    state: RootState,
    dispatch: AppDispatch;
}>();

export const initUsers = createAppAsyncThunk<InitUsersFullfiledValue, void>('initUsers', async (_params, { getState }) => {
    const {
        profile: { user },
    } = getState();

    const childroles = user[`${window.location.origin}/childRoles`];
    const seIsChild = childroles.includes('se');
    const camIsChild = childroles.includes('csm');

    const orgMembers = await ajax(`organization/members?org_id=${iamAdminConsoleOrgId}`);
    const initialAccumulator: { childrens: IOrgMember[]; csm: IOrgMember[]; se: IOrgMember[]; } = {
        childrens: [],
        csm: [],
        se: [],
    };
    const mappedUserTypes = orgMembers.reduce(
        (total, user) => {
            if (total[user.userTypeName])
                total[user.userTypeName].push(user);
            if (total[user.userTypeName])
                total[user.userTypeName].push(user);
            if (total[user.userTypeName] === 'csm' && camIsChild)
                total.childrens.push(user);
            if (total[user.userTypeName] === 'se' && seIsChild)
                total.childrens.push(user);
            if (total[user.userTypeName] !== 'se' && total[user.userTypeName] !== 'csm')
                total.childrens.push(user);
            return total;
        },
        initialAccumulator
    );
    return {
        childroles,
        childrens: mappedUserTypes.childrens,
        ses: mappedUserTypes.se,
        csms: mappedUserTypes.csm,
    };
});

export const createUser = createAppAsyncThunk<IOrgMember, IOrgMemberCRUDRequest>('createUser', async (params) => {
    const res: IOrgMember = await UsersService.createUser(params);
    return res;
});
export const deleteUser = createAppAsyncThunk<void, IOrgMemberCRUDRequest>('deleteUser', async (params) => await UsersService.deleteUser(params));
export const updateUser = createAppAsyncThunk<void, IOrgMemberCRUDRequest>('updateUser', async (params) => await UsersService.updateUser(params));

const initialState: IUsersState = {
    ready: false,
    errors: [],
    ses: [],
    csms: [],
    childrensTypes: [],
    childrens: [],
    qrCode: null,
    loading: [],
    didFetchUsers: false,
};

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // init users
        builder.addCase(initUsers.pending, thunkPending(initUsers.typePrefix)),
            builder.addCase(initUsers.rejected, thunkRejected(initUsers.typePrefix)),
            builder.addCase(initUsers.fulfilled, (state, { payload }) => {
                const { childrens, ses, csms, childroles } = payload;
                state.loading = state.loading.filter((l) => l !== initUsers.typePrefix);
                state.didFetchUsers = true;
                state.childrens = childrens;
                state.ses = ses;
                state.csms = csms;
                state.childrensTypes = childroles;
                state.ready = true;
            });

        //create user
        builder.addCase(createUser.pending, thunkPending(createUser.typePrefix)),
            builder.addCase(createUser.rejected, thunkRejected(createUser.typePrefix)),
            builder.addCase(createUser.fulfilled, (state, { meta, payload }) => {
                state.loading = state.loading.filter((l) => l !== createUser.typePrefix);
                const userToAdd: IOrgMember = {
                    ...meta.arg,
                    id: payload.id,
                    active: meta.arg.active === "Yes",
                };
                state.childrens.push(userToAdd);
            });

        //delete user
        builder.addCase(deleteUser.pending, thunkPending(deleteUser.typePrefix)),
            builder.addCase(deleteUser.rejected, thunkRejected(deleteUser.typePrefix)),
            builder.addCase(deleteUser.fulfilled, (state, { meta }) => {
                state.loading = state.loading.filter((l) => l !== deleteUser.typePrefix);
                state.childrens = state.childrens.filter(({ id }) => id !== meta.arg.id);
            });

        //delete user
        builder.addCase(updateUser.pending, thunkPending(updateUser.typePrefix)),
            builder.addCase(updateUser.rejected, thunkRejected(updateUser.typePrefix)),
            builder.addCase(updateUser.fulfilled, (state, { meta }) => {
                state.loading = state.loading.filter((l) => l !== updateUser.typePrefix);
                const userToUpdate: IOrgMember = {
                    ...meta.arg,
                    active: meta.arg.active === "Yes",
                };
                state.childrens = state.childrens.map((user) => (meta.arg.id === user.id ? userToUpdate : user));
            });
    },
});

export const isLoading = isLoadingSlice('users');

export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;
