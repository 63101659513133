import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
    saveButton: {
        backgroundColor: '#fe0072',
        color: '#ffffff',
        outline: 'none',
        border: 'none',
        borderRadius: '5px',
        padding: ' 0 10px',
        alignSelf: 'center',
        fontSize: 22,
        cursor: 'pointer',
        height: 38,
    },
    largeInput: {
        width: 900,
        height: 36,
        marginBottom: 5,
    },
    addEventButton: {
        backgroundColor: '#fe0072',
        color: '#ffffff',
        outline: 'none',
        border: 'none',
        borderRadius: '5px',
        padding: ' 0 10px',
        alignSelf: 'center',
        fontSize: 22,
        cursor: 'pointer',
        height: 38,
    },
    gridItem: {
        padding: 0,
        textAlign: 'center',
        color: theme.palette.text.secondary,
        '& .MuiPaper-root': {
            color: '#000',
            position: 'relative',
            display: 'flex',
            padding: 10,
            backgroundColor: '#EBECF0',
        },
    },
    grid: {
        width: '100% !important',
        margin: '50px 0px !important',
        justifyContent: 'space-around',
    },
    pagination: {
        textAlign: 'center',
        width: '90%',
        position: 'absolute',
        top: 600,
    },
    gridContainer: {
        position: 'relative',
    },
}));
