import React, { useEffect, useRef } from 'react';

import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Search from '../../svgs/search';
import TextField from '@material-ui/core/TextField/TextField';
import { makeStyles } from '@material-ui/core';

export default function SearchBox({ height = 24, width = 120, onChange, value, placeholder = 'Search', className = '' }) {
    const classes = useStyles({
        height,
        width,
    });

    return (
        <form
            onSubmit={(e) => {
                onChange(e.target.children[0].children[0].children[0].value);
                e.preventDefault();
            }}
        >
            <TextField
                className={`${classes.root} ${className}`}
                placeholder={placeholder}
                variant="filled"
                onChange={(e) => onChange(e.target.value)}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="start">
                            <Search width={12} height={12} color={'#fe0072'} />
                        </InputAdornment>
                    ),
                }}
            />
        </form>
    );
}

function useStyles({ height, width }) {
    return makeStyles((theme) => ({
        root: {
            color: '#000',
            fontSize: 12,
            opacity: 1,
            height,
            width,
            fontFamily: 'avenir',
            backgroundColor: '#EBECF0',
            border: 'none',
            '& .MuiInputBase-root': {
                border: 'none',
                paddingRight: 5,
            },
            '& .MuiSvgIcon-root': {
                height: 13,
                width: 13,
                padding: 0,
                color: '#fe0072',
            },
            '& .MuiInputBase-input': {
                color: '#000',
                padding: 0,
                paddingLeft: 8,
                fontFamily: 'avenir',
                fontSize: 12,
                height: '100%',
                width: '100%',
            },

            '& .MuiFilledInput-underline::before': {
                borderBottom: 'none',
            },
            '& .MuiFilledInput-underline::after': {
                borderBottom: 'none',
            },
            '& .MuiTextField-root': {
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
            },
            '& .MuiFilledInput-root.Mui-focused': {
                border: 'solid 1px',
                borderColor: '#fe0072',
            },
            '& .MuiFilledInput-root': {
                borderTopRightRadius: 0,
                borderTopLeftRadius: 0,
                width: '100%',
                height: '100%',
            },
        },
    }))();
}
