const codes = {
    INVALID_HASH: 'Invalid activation link',
    HASH_EXPIRED: 'Activation link expired',
    EMAIL_NOT_EXIST: "Email doesn't exist, please create a user first",
    DUPLICATE_RULE: 'Rule creation failed might be rule duplication',
    FAILED_UPDATE_RULE: 'Rule update failed',
    FAILED_DELETE_RULE: 'Rule delete failed ',
};

export const getErrorMessage = (errorCode) => codes[errorCode] || errorCode;
