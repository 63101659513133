import { FormControlLabel, styled } from '@material-ui/core';

export const Container = styled('div')({
    backgroundColor: '#14172c',
    padding: '10px 20px',
    margin: '0 -20px',
});

export const FormLabelStyle = styled(FormControlLabel)({
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    margin: 0,
});

export const GroupControls = styled('div')({
    margin: '20px 0px',
    display: 'flex',
    justifyContent: 'space-between',
});

export const ControlContainer = styled('div')({
    width: 'calc(50% - 20px)',
    display: 'flex',
    justifyContent: 'space-between',
});

export const ControlContainerUrl = styled('div')({
    width: 'calc(100% - 30px)',

    display: 'flex',
    justifyContent: 'space-between',
});
