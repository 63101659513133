import React, { createContext, useEffect, useState } from 'react';
import { createSDK } from '../services/split.io';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { useSelector } from 'react-redux';

const InitSplitContext = createContext(null);

const InitSplitProvider = ({ children }) => {
    const networkId = useSelector((state) => state?.profile?.meta?.networkId);
    const [isInit, setIsInit] = useState(false);

    const [config, setConfig] = useState(createSDK(networkId));

    useEffect(() => {
        if (networkId != null && !isInit) {
            setConfig(createSDK(networkId));
            setIsInit(true);
        }
    }, [networkId]);

    return (
        <InitSplitContext.Provider value={{}}>
            <SplitFactory factory={config} updateOnSdkUpdate={true} updateOnSdkTimedout={false}>
                {children}
            </SplitFactory>
        </InitSplitContext.Provider>
    );
};

export default InitSplitProvider;
