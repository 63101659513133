export default ({ container, control, singleValue, option, menu, menuList, valueContainer, ...rest }) => ({
    container: (provided, state) => ({
        ...provided,
        width: 226,
        height: 40,
        ...container,
    }),
    control: (provided, state) => {
        return {
            ...provided,
            // borderColor: !state.isFocused ? 'hsl(0,0%,80%)' : '#fe0072'
            border: state.isFocused || state.menuIsOpen || state.hasValue ? 'solid 1px #fe0072' : 'solid 1px hsl(0,0%,80%)',
            boxShadow: state.isFocused || state.menuIsOpen ? '0 0 0 1px #fe0072' : 'none',
            ':hover': {
                border: state.isFocused || state.menuIsOpen ? 'solid 1px #fe0072' : 'solid 1px hsl(0,0%,80%)',
            },
            height: 40,
            fontSize: 14,
            ...control,
        };
    },
    singleValue: (provided, state) => ({ ...provided, fontSize: 15, color: '#000', ...singleValue }),
    option: (provided, state) => {
        return {
            ...provided,
            backgroundColor: state.isFocused ? '#fee5f0' : 'transparent',
            color: '#000',
            fontSize: 14,
            ...option,
        };
    },
    menu: (provided, state) => {
        return {
            ...provided,
            maxHeight: 200,
            ...menu,
            zIndex: 100
        }
    },
    menuList: (provided, state) => {
        return {
            ...provided,
            maxHeight: 200,
            ...menuList,
        };
    },
    valueContainer: (provided, state) => ({ ...provided, overflow: 'hidden', height: '100%', ...valueContainer }),
    ...Object.entries(rest).reduce((total, [key, value]) => ({ ...total, [key]: (provided, state) => ({ ...provided, ...value }) }), {}),
});
