import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    progress: {
        margin: '0 auto',
    },
}));

const Progress = ({ speed = 10, className = '', ...props }) => {
    const classes = useStyles();

    return <CircularProgress className={`${classes.progress} ${className}`} color="secondary" {...props} />;
};

export default Progress;
