import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IIdAndNamePair, ILookUpsState, ISupporter } from './types';

const initialState:ILookUpsState = {
    regions: {},
    dataLocations: {},
    csms: {},
    ses: {},
}

export const lookUpsSlice = createSlice({
    name: 'lookUps',
    initialState,
    reducers: {
        setRegionsLookUp: (state, action:PayloadAction<IIdAndNamePair[]>) => {
            state.regions = action.payload.reduce(
                (initialRegions, currRegion) => ({
                    ...initialRegions,
                    [currRegion.id]: currRegion,
                }),
                {}
            );
        },
        setDataLocationsLookUp: (state, action:PayloadAction<IIdAndNamePair[]>) => {
            state.dataLocations = action.payload.reduce(
                (initialDataLocations, currDataLocation) => ({
                    ...initialDataLocations,
                    [currDataLocation.id]: currDataLocation,
                }),
                {}
            );
        },
        setCsmsLookUp: (state, action:PayloadAction<ISupporter[]>) => {
            state.csms = action.payload.reduce(
                (initialCsms, currCsm) => ({
                    ...initialCsms,
                    [currCsm.id]: currCsm,
                }),
                {}
            );
        },

        setSesLookUp: (state, action:PayloadAction<ISupporter[]>) => {
            state.ses = action.payload.reduce(
                (initialSes, currSe) => ({
                    ...initialSes,
                    [currSe.id]: currSe,
                }),
                {}
            );
        },
    },
});

export const { setRegionsLookUp, setDataLocationsLookUp, setCsmsLookUp, setSesLookUp } = lookUpsSlice.actions;

export default lookUpsSlice.reducer;
