
import { updateUser, deleteUser as deleteUserAction, createUser as createUserAction } from "redux/slicers/users";
import store from '../../../redux/store';
import omit from 'lodash/omit';
import capitalize from "lodash/capitalize";
import { ACCOUNT_SUPPORTER_ROLES } from '../../../constants';
import { IAccountSupporterRequest } from "models/AccountSupporterRequest";
import { deleteAccountSupporter, editAccountSupporter } from "redux/slicers/accounts";
import { IFormattedOrgMember, IIdAndNamePair, IOrgMember, IOrgMemberCRUDRequest } from "models/Common";

export const editUser = (user: IFormattedOrgMember, childrensTypes: any) => {
    const userType = childrensTypes.find((type: any) => type === user.userTypeName);
    const newUser: IOrgMemberCRUDRequest = { ...user, userType };
    // @ts-ignore
    store.dispatch(updateUser(newUser));

    if (ACCOUNT_SUPPORTER_ROLES.includes(userType)) {
        const body: IAccountSupporterRequest = {
            id: user.id,
            name: user.name,
            email: user.email,
            role: userType,
        };
        // @ts-ignore
        store.dispatch(editAccountSupporter(body));
    }
};

export const createUser = (user: IFormattedOrgMember, childrensTypes: any) => {
    const userType = childrensTypes.find((type: any) => type === user.userTypeName);
    const newUser: IOrgMemberCRUDRequest = { ...user, userType };
    // @ts-ignore
    store.dispatch(createUserAction(newUser));
};

export const deleteUser = ({ id, userType }: { id: string; userType: string; }) => {
    // @ts-ignore
    store.dispatch(deleteUserAction({ id, userType }));

    if (ACCOUNT_SUPPORTER_ROLES.includes(userType)) {
        // @ts-ignore
        store.dispatch(deleteAccountSupporter(id));
    }
};

export const formatToDbStructure = (user: IFormattedOrgMember) => {
    const newUser = omit(user, 'tableData');
    return {
        ...newUser,
        active: newUser.active === "Yes" ? 1 : 0,
    };
};

export const formatToTableStructure = (user: IOrgMember, regionsLookUp: any): IFormattedOrgMember => ({
    ...user,
    active: user.member ? "Yes" : "No",
    regionName: regionsLookUp[user.regionId]?.name
});

export const getRegionsOptions = (regions: IIdAndNamePair[]) => (
    regions.map(({ name, id }) => ({
        value: id,
        label: name
    }))
);

export const getUserTypesOptions = (childrensTypes: string[]) => (
    childrensTypes.map((userType) => ({
        label: capitalize(userType.split('_').join(' ')),
        value: userType
    }))
);