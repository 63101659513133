import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './styles.css';
import moment from 'moment';
import { selectReports, isLoading } from 'redux/slicers/reports';
import Table from '../../widgets/table/oldTable';
import Tooltip from '@material-ui/core/Tooltip';
import FilteringForm from './FilteringForm';

export const Reports = () => {
    const { report = {} } = useSelector(selectReports);
    const dispatch = useDispatch();

    return (
        <div className="cq-container">
            <FilteringForm report={report} />
            <div className="reportContainer">
                <Table {...tableBuilder()} rows={report.data || []} dataKey={report.key} isLoading={dispatch(isLoading('runConsoleReport'))} />
            </div>
        </div>
    );
};

function tableBuilder() {
    const style = { headerRow: { backgroundColor: '#14172c' }, bodyCell: { borderBottom: '1px solid #fe0072' } };
    const ellipsis = (val) => (val.length * 10 > 100 ? { overflow: 'hidden', textOverflow: 'ellipsis' } : {});
    const RenderBody = ({ column = {}, value = '' }) => {
        let cell = value;
        switch (column.id) {
            case 'endDate':
            case 'startDate':
                cell = moment(value).format('YYYY-MM-DD');
                break;
            case 'accountType':
                return <div style={{ color: '#000', width: 100 }}>{cell}</div>;
        }
        return (
            <Tooltip title={cell} disableHoverListener={!Object.keys(ellipsis(cell)).length}>
                <div style={{ color: '#000', whiteSpace: 'nowrap', maxWidth: 100, ...ellipsis(cell) }}>{cell}</div>
            </Tooltip>
        );
    };

    const RenderHead = ({ column }) => <div style={{ whiteSpace: 'nowrap' }}>{column.label}</div>;
    const RenderEmpty = () => <div className="noResultsText">NO DATA TO DISPLAY</div>;

    return {
        style,
        RenderBody,
        RenderHead,
        RenderEmpty,
    };
}

//TODO:Check with MAMO if this is needed
// const History = ({ history = [], reportKey }) => {
//     const classes = styles();
//     const dispatch = useDispatch();
//     const isDisabledBtn = (key) => key === reportKey;
//     const { regions = [], products = [], accountTypes = [], accounts = [] } = useSelector(selectAccounts);
//     const { csms = [], ses = [] } = useSelector(selectUsers);
//     return (
//         <div>
//             <div className={classes.historyTitle}>History</div>
//             {history
//                 .slice(0, 5)
//                 .sort((a, b) => ((a.active && b.active) || (!a.active && !b.active) ? 0 : a.active && !b.active ? -1 : 1))
//                 .map((report, index) => (
//                     <Accordion key={index} className={classes.accordion} TransitionProps={{ unmountOnExit: true }}>
//                         <AccordionSummary className={classes.accordionSummary} key={index}>
//                             {report.runAt}
//                         </AccordionSummary>
//                         <AccordionDetails className={classes.accordionDetails}>
//                             <div className={classes.historyProperties}>
//                                 <div>
//                                     <HistoryProperty value={report.params.where.startDate} label={'Start date'} />
//                                     <HistoryProperty value={report.params.where.endDate} label={'End date'} />
//                                     <HistoryProperty value={report.params.groupBy.join(', ')} label={'Group by'} />
//                                     <HistoryProperty
//                                         value={accounts
//                                             .filter(({ id }) => (report.params.where.account || []).includes(id))
//                                             .map(({ name }) => name)
//                                             .join(', ')}
//                                         label={'Accounts'}
//                                     />
//                                     <HistoryProperty
//                                         value={regions
//                                             .filter(({ id }) => (report.params.where.region || []).includes(id))
//                                             .map(({ name }) => name)
//                                             .join(', ')}
//                                         label={'Regions'}
//                                     />
//                                 </div>
//                                 <div>
//                                     <HistoryProperty
//                                         value={products
//                                             .filter(({ id }) => (report.params.where.product || []).includes(id))
//                                             .map(({ name }) => name)
//                                             .join(', ')}
//                                         label={'Products'}
//                                     />
//                                     <HistoryProperty
//                                         value={accountTypes
//                                             .filter(({ id }) => (report.params.where.accountType || []).includes(id))
//                                             .map(({ name }) => name)
//                                             .join(', ')}
//                                         label={'Account type'}
//                                     />
//                                     <HistoryProperty
//                                         value={csms
//                                             .filter(({ id }) => (report.params.where.csms || []).includes(id))
//                                             .map(({ name }) => name)
//                                             .join(', ')}
//                                         label={`CSM's`}
//                                     />
//                                     <HistoryProperty
//                                         value={ses
//                                             .filter(({ id }) => (report.params.where.ses || []).includes(id))
//                                             .map(({ name }) => name)
//                                             .join(', ')}
//                                         label={`Sales engineers`}
//                                     />
//                                 </div>
//                             </div>
//                             <div className={classes.historyActionBtn}>
//                                 <button
//                                     disabled={isDisabledBtn(report.key)}
//                                     className={`${classes.loadHistoryReportBtn} ${isDisabledBtn(report.key) ? classes.disabledButton : ''}`}
//                                     onClick={() => dispatch(setHistoryReport(report))}
//                                 >
//                                     Load report
//                                 </button>
//                                 <button className={classes.loadHistoryReportBtn} onClick={() => dispatch(removeFromHistory(report))}>
//                                     Delete
//                                 </button>
//                             </div>
//                         </AccordionDetails>
//                     </Accordion>
//                 ))}
//         </div>
//     );
// };

// const HistoryProperty = ({ label, value }) => {
//     const ellipsis = (val) => (val.length > 20 ? { overflow: 'hidden', textOverflow: 'ellipsis' } : {});
//     return (
//         <div style={{ display: 'flex' }}>
//             <div style={{ marginRight: 10, whiteSpace: 'nowrap' }}>{`${label}:`}</div>
//             <Tooltip title={value} disableHoverListener={!Object.keys(ellipsis(value)).length}>
//                 <div style={{ whiteSpace: 'nowrap', maxWidth: 150, ...ellipsis(value) }}>{value || ' - '}</div>
//             </Tooltip>
//         </div>
//     );
// };
