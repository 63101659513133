/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {handleOnRuleChange, getTimeFrameByMilli, TIME_FRAME_OPTIONS} from './utils';
import Form from "./Form";

const FrequencyCappingItem = ({ rule, tagId, onDelete, onSave, onEdit, editMode: globalEditMode, onActive }) => {
    const [ruleFields, setRuleFields] = useState({
        id: rule.id,
        active: rule.active,
        clicks: rule.config.maxInInterval,
        interval: rule.config.interval,
        displayedInterval: '',
        timeFrame: '',
        campaignInput: '',
        campaignArr: rule.config.conditions?.url ? rule.config.conditions.url : [],
    });

    useEffect(() => {
        if (ruleFields.interval >= TIME_FRAME_OPTIONS[0].minMilliValue) {
            const { frame, amount } = getTimeFrameByMilli(ruleFields.interval);
            setRuleFields(s => ({ ...s, displayedInterval: amount, timeFrame: frame }));
        }
    }, [ruleFields.interval])
    const [initialRuleValues, setInitialRuleValues] = useState(null);
    const [editMode, setEditMode] = useState(!!rule.editMode);


    useEffect(() => {
        setRuleFields((s) => ({ ...s, campaignInput: '' }));
    }, [ruleFields.campaignArr]);

    useEffect(() => {
        onEdit(editMode);
    }, [editMode]);

    const onRuleChange = (property, value) => {
        handleOnRuleChange(tagId, property, value, ruleFields, setRuleFields, initialRuleValues, setInitialRuleValues, onActive);
    };

    const onSubmit = (e) => {
        e.preventDefault();
        setEditMode(false);
        onSave(tagId, ruleFields);
    };

    const handleOnEditMode = () => {
        setEditMode(true);
    };
    const handleOnDelete = (e) => {
        e.preventDefault();
        onDelete(tagId, ruleFields.id);
    };

    const handleOnCancelRule = () => {
        setEditMode(false);
        if (rule.id === -1) onDelete(tagId, ruleFields.id);
        else if (initialRuleValues) setRuleFields(initialRuleValues);
    };

    return (
        <Form globalEditMode={globalEditMode}
              editMode={editMode}
              onSubmit={onSubmit}
              handleOnCancelRule={handleOnCancelRule}
              handleOnDelete={handleOnDelete}
              handleOnEditMode={handleOnEditMode}
              handleOnRuleChange={onRuleChange}
              ruleFields={ruleFields}
        />
    );
};

export default FrequencyCappingItem;
