import React, { useState } from 'react';
import { createNetwork, selectAccounts, updateNetwork } from 'redux/slicers/accounts';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Input from '../../widgets/Input';
import { Select } from '../../widgets/select';
import Toast from '../../../services/notifications';
import { ToastContainer } from 'react-toastify';
import countryList from 'country-list';
import moment from 'moment-timezone';
import { selectSettings } from 'redux/slicers/settings';
import { selectStyle } from '../selectStyle';
import useStyles from './styles';
import validator from 'validator';

const NetworkForm = ({ action }) => {
    const createNewNetwork = action === 'create';
    const params = useParams();
    const accountId = parseInt(params.accountId),
        networkId = parseInt(params.networkId);
    const accountData = useSelector((state) => state.accounts.accounts.find((a) => a.id === accountId));
    const networkData = accountData?.networks?.find((n) => n.id === networkId);
    const [network, setNetwork] = useState(createNewNetwork ? {} : networkData);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { industries: industriesOptions } = useSelector(selectSettings);
    const { mediaSpendTiers = [] } = useSelector(selectAccounts);
    const timezoneOptions = moment.tz.names().map((tz) => ({ value: tz, label: tz }));

    const renderTitle = () => {
        if (createNewNetwork) {
            return (
                <p>
                    <b>ADD</b> BRAND
                </p>
            );
        }
        return (
            <p>
                <b>EDIT</b> BRAND
            </p>
        );
    };

    const countryOptions = Object.entries(countryList.getCodeList()).map(([value, label]) => ({ value, label }));
    const mediaSpendTiersOpt = mediaSpendTiers.map(({ name, id }) => ({ label: name, value: id }));

    const onSubmit = async (e) => {
        e.preventDefault();
        const changes = [];
        if (!createNewNetwork && !Object.entries(network).some(([key, value]) => networkData[key] !== value)) {
            changes.push('nothing changed');
        }
        const errors = [...isValidState(network), ...changes];
        if (errors.length) {
            return Toast({ type: 'error', message: errors.pop() });
        }
        dispatch(createNewNetwork ? createNetwork({ ...network, accountId, active: true }) : updateNetwork({ accountId, ...extractChanges(network, networkData) }));
        onBack();
    };

    const onBack = () => {
        history.push(`/accounts/view/${accountId}`);
    };

    if (!createNewNetwork && !networkData) {
        onBack();
        return <></>;
    }
    return (
        <div className="container">
            <div className="form-title">{renderTitle()}</div>
            <form onSubmit={onSubmit}>
                <div className="input-group">
                    <Input className={classes.smallInput} placeholder={'Name'} value={network?.name} onChange={(value) => setNetwork((s) => ({ ...s, name: value }))} />
                    <Input className={classes.smallInput} placeholder={'Email'} value={network?.email} onChange={(email) => setNetwork((s) => ({ ...s, email }))} />
                </div>

                <div className="input-group">
                    <Input
                        className={classes.smallInput}
                        placeholder={'City'}
                        value={network?.city}
                        onChange={(value) =>
                            (`${value}`.match(/[a-zA-Z]s*/) || !value) && `${value}`.match(/^([^0-9]*)$/) && value.length < 20 && setNetwork((s) => ({ ...s, city: value }))
                        }
                    />
                    <Select
                        styles={selectStyle}
                        value={network?.country && countryOptions.find(({ value }) => value === network?.country)}
                        options={[{ value: 'none', label: 'none' }, ...countryOptions]}
                        placeholder={'Country'}
                        onChange={({ value }) => setNetwork((s) => ({ ...s, country: value === 'none' ? null : value }))}
                    />
                </div>
                <div className="input-group">
                    <Input
                        className={classes.smallInput}
                        placeholder={'Phone Number (numbers only)'}
                        value={network?.phoneNumber}
                        type={'text'}
                        onChange={(value) => (value >= 0 || !value) && value.length < 15 && `${value}`.match(/^\d+$|^$/) && setNetwork((s) => ({ ...s, phoneNumber: value }))}
                    />

                    <Input
                        className={classes.smallInput}
                        placeholder={'Zip Code'}
                        type={'text'}
                        value={network?.zipCode}
                        onChange={(value) => value.length < 15 && (validator.isAlphanumeric(value) || !value) && setNetwork((s) => ({ ...s, zipCode: value }))}
                    />
                </div>
                <div className="input-group">
                    <Select
                        styles={selectStyle}
                        value={mediaSpendTiersOpt.find(({ value }) => value === network?.mediaSpendTierId)}
                        options={mediaSpendTiersOpt}
                        placeholder={'Media spend'}
                        onChange={({ value }) => setNetwork((s) => ({ ...s, mediaSpendTierId: value }))}
                    />
                    <Select
                        styles={selectStyle}
                        value={industriesOptions.find(({ value }) => value === network?.industryId)}
                        options={industriesOptions}
                        placeholder={'Industry'}
                        onChange={({ value }) => setNetwork((s) => ({ ...s, industryId: value }))}
                    />
                </div>
                <div className="input-group">
                    <Select
                        styles={selectStyle}
                        value={timezoneOptions.find(({ value }) => value === network?.tz)}
                        options={timezoneOptions}
                        placeholder={'Timezone'}
                        onChange={({ value }) => setNetwork((s) => ({ ...s, tz: value }))}
                    />
                </div>

                <div className="form-actions">
                    <button type="submit" className="cq-btn-lg">
                        {createNewNetwork ? 'ADD' : 'UPDATE'}
                    </button>
                </div>
                <ToastContainer />
            </form>
        </div>
    );
};

export default NetworkForm;

function extractChanges(state, network) {
    return Object.entries(state)
        .filter(([key, value]) => value !== network[key] || key === 'id')
        .reduce((total, [key, value]) => ({ ...total, [key]: value }), {});
}
const isValidState = (state) =>
    [
        { prop: 'name', error: 'name', test: (prop) => !prop || '?,:;{[}]\\|*/+=)(^%$#!`~'.split('').some((char) => prop.includes(char)) },
        { prop: 'email', error: 'email address', test: (prop) => !prop || !validator.isEmail(prop) },
        { prop: 'industryId', error: 'industry', test: (prop) => !prop },
    ]
        .filter(({ prop, test }) => test(state[prop] || `${state[prop]}`.length > 25))
        .map(({ error }) => `Invalid ${error}`);
