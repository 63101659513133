import api from './';
import { ITag } from '../../components/containers/accounts/tabs/UserConversionData/UserConversionData.types';

export class TagService {
    static baseRoute = 'clickTrueTags';

    static async getTags(networkId?: number) {
        const res = await api.post<ITag[]>(`${this.baseRoute}/get_tags`, { networkId });
        return res.data;
    }
}
