import React, {useEffect, useState} from 'react';
import FrequencyCappingItem from './FrequencyCappingItem';
import {Accordion, AccordionDetails, AccordionSummary, Button, makeStyles} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddFCRules from "./AddFCRules";
import moment from 'moment';
import '../styles.css';
import {cloneDeep} from 'lodash';
import Toast from 'services/notifications';
import {addNetworkRule, deleteNetworkRule, updateNetworkRule} from "redux/slicers/accounts";
import {useDispatch} from "react-redux";
import api from "../../../../services/api";
import config from "../../../../config";
import Progress from "../../../widgets/progress";
const { baseAPI: baseURL } = config;

const MIN_ACTIVE_RULES = 1;

const useStyles = makeStyles({
    root: {
        borderRadius: 4,
        backgroundColor: "#282b3f",
        border: "1px solid #ffffff",
        marginBottom: 7
    }
});

const FrequencyCapping = ({ network }) => {
    const [rules, setRules] = useState({});
    const [tags, setTags] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [expanded, setExpanded] = useState("");
    const [showAddBox, setShowAddBox] = useState(false);
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(async () => {
        await fetchRules();
    }, []);

    const fetchRules = async () => {
        setLoading(true)
        const res = await api.get(`${baseURL}/frequency_capping/rule?networkId=${network.id}`);
        const items = res.data;
        Object.keys(items).map(id => {
            items[id].sort((a, b) =>  b.active - a.active )
        })
        setRules(items)
        const tagsResponse = await api.post(`${baseURL}/clickTrueTags/get_tags`, {networkId: network.id});
        setTags(tagsResponse.data);
        setLoading(false)
    }

    const classes = useStyles();
    const handleChange = (tagId) => {
        setExpanded(tagId);
    };

    const getTagNameById = id => {
        const tag = tags.find(item => item.value === Number(id));
        return tag.label;
    }

    const onRuleChange = async (action, newRule, newRules) => {
        switch (action) {
            case 'update':
                await dispatch(updateNetworkRule({ networkId: network.id, newRule }));
                await fetchRules();
                break;
            case 'add':
                await dispatch(addNetworkRule({ networkId: network.id, newRules }));
                await fetchRules();
                break;
            case 'delete':
                await dispatch(deleteNetworkRule({ networkId: network.id, newRule }));
                await fetchRules();
                break;
            default:
                break;
        }
    };

    const handleOnAddRule = (tagId) => {
        setEditMode(true);

        const draftRule = {
            id: -1,
            config: {},
            active: 1,
            editMode: true,
        };

        const newArray = [...rules[tagId], draftRule];
        setRules({...rules, [tagId]: newArray})
    };

    const handleOnRuleDelete = async (tagId, id) => {
        const newRules = rules[tagId].filter((rule) => rule.id !== id);

        if (id !== -1) {
            setRules({...rules, [tagId]: newRules})
            await onRuleChange('delete', id);
        } else {
            setRules({...rules, [tagId]: newRules})
        }
        setEditMode(false);
    };

    const handleOnRuleSave = async (tagId, currentRule) => {
        setLoading(true);
        setEditMode(false);
        const { active, campaignArr: url, clicks: maxInInterval, interval } = currentRule;
        if (currentRule.id === -1) {
            await handleOnRuleDelete(tagId,-1);
            const ruleExists = rules[tagId].filter(r => r.config.interval === interval && r.config.maxInInterval === maxInInterval);
            if (ruleExists.length > 0) {
                Toast({type: 'error', message: `Rule already exists.`});
                setLoading(false)
            } else {
                const newRule = {
                    id: currentRule.id,
                    name: null,
                    description: null,
                    config: {
                        vectorSegments: ['ip'],
                        conditions: { googlePaid: true, onlyLegit: true },
                        interval,
                        maxInInterval,
                    },
                    date_created: moment().toISOString(),
                    active,
                    clickTrueId: Number(tagId),
                };
                if (url.length > 0) {
                    newRule.config.conditions.url = url;
                }
                await onRuleChange('add',{}, [newRule]);
            }
        } else {
            const index = rules[tagId].findIndex((rule) => rule.id === currentRule.id);
            const ruleExists = rules[tagId].filter(r => r.config.interval === interval && r.config.maxInInterval === maxInInterval && r.id !== currentRule.id);
            if (ruleExists.length > 0) {
                const initialRules = rules[tagId];
                setRules({...rules, [tagId]: initialRules})
                setTimeout(() => {
                    setLoading(false)
                    Toast({type: 'error', message: `Rule already exists.`});
                }, 0)
            } else {
                let tempRules = cloneDeep(rules[tagId]);
                tempRules[index] = {
                    ...tempRules[index],
                    active,
                    config: {
                        ...tempRules[index].config,
                        interval,
                        maxInInterval,
                        conditions: {...tempRules[index].config.conditions}
                    },
                };

                if (url.length > 0) {
                    tempRules[index].config.conditions.url = url;
                } else {
                    delete tempRules[index].config.conditions.url;
                }
                await onRuleChange('update', tempRules[index]);
            }
        }
    };

    const onAddNewRules = async (newRules) => {
        setLoading(true)
        await onRuleChange('add', {}, newRules);
        setShowAddBox(false)
        await fetchRules();
    }

    const OnActiveChange = (tagId) => {
        const activeRules = rules[tagId].filter((rule) => rule.active);
        if (activeRules.length === 0) return true;
        return activeRules.length - 1 >= MIN_ACTIVE_RULES;
    };

    const handleOnRuleEdit = (flag) => {
        setEditMode(flag);
    };

    return (
        loading ?  <Progress size={22} /> : (
            <div className="frequency-capping-container">
                <div className="frequency-capping-header">
                    <span>{Object.keys(rules).length ? 'Rules by Search ID' : 'There are no rules for this network, add a new rule!'}</span>
                    <Button variant="outlined" onClick={() => setShowAddBox(!showAddBox)}>
                        {showAddBox ? 'Close' : '+ Add Rules'}
                    </Button>
                </div>

                {showAddBox && (
                    <AddFCRules onSave={onAddNewRules} network={network}/>
                )}
                {rules && Object.keys(rules).length > 0 && (
                    Object.keys(rules).map(tagId => {
                        return (
                            <Accordion key={tagId} expanded={expanded === tagId} onChange={() => handleChange(expanded === tagId ? "" : tagId)} className={classes.root}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`${tagId}-content`}
                                    id={tagId}
                                >
                                    <div>Search ID: {getTagNameById(tagId)} ({tagId})</div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{width: "100%"}}>
                                        {rules[tagId].map(rule => {
                                            return (
                                                <FrequencyCappingItem
                                                    key={rule.id}
                                                    rule={rule}
                                                    tagId={tagId}
                                                    onDelete={handleOnRuleDelete}
                                                    onSave={handleOnRuleSave}
                                                    onEdit={handleOnRuleEdit}
                                                    editMode={editMode}
                                                    onActive={OnActiveChange}
                                                />
                                            )
                                        })}
                                        <div>
                                            <Button variant="outlined" disabled={editMode} onClick={() => handleOnAddRule(tagId)}>
                                                + add rule
                                            </Button>
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        )
                    })
                )}
            </div>
        )
    );
};

export default FrequencyCapping;
