import React, { useState } from 'react';
import styles from './styles.module.css';
import { CircularProgress } from '@material-ui/core';
import { getBinaryContent } from 'pizzip/utils';
import { saveAs } from 'file-saver';
import Pizzip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import config from '../../../../../config'

const getDataLoactionPrefix = (dataLocationId) => {
    switch (dataLocationId) {
        case 1: {
            return '';
        }
        case 2: {
            return 'eu';
        }
        default: {
            return '';
        }
    }
};

const ImplementationGuide = ({ onclose, tagInfo }) => {
    const [loading, setIsloading] = useState(false);
    const fileName = 'CHEQImplementationDocument.docx';
    const downloadFileName = 'CHEQ Implementation Guide'
    const implementationGuideUrl = `${config.implementationGuideUrl}/${fileName}`;
    const downloadPDF = async () => {
        try {
            setIsloading(true);
            getBinaryContent(implementationGuideUrl, (err, content) => {
                if (err) {
                    throw err;
                } else {
                    const zip = new Pizzip(content);
                    const doc = new Docxtemplater(zip, {
                        nullGetter: ({ value }) => `{${value}}`,
                        linebreaks: false,
                        paragraphLoop: true,
                    });

                    const dataLocationPrefix = getDataLoactionPrefix(tagInfo.dataLocationId);

                    doc.render({
                        TAG_HASH: tagInfo?.tagHash,
                        TAG_ID: tagInfo?.id,
                        CDN_SUBDOMAIN: tagInfo?.cdn,
                        SERVER_SUBDOMAIN: tagInfo?.server,
                        DOMAIN: tagInfo?.domain,
                        CDN_PATH: tagInfo?.cdnPath,
                        NAME: tagInfo?.name,
                        DATA_LOCATION: dataLocationPrefix,
                    });

                    const out = doc.getZip().generate({ type: 'blob', mimeType: 'application/vnd.openxmlformats-officedocument.wordprocess' });

                    saveAs(out, downloadFileName + " - " + tagInfo.accountName + ".docx");
                    setIsloading(false);
                    handleDownload();
                }
            });
        } catch (err) {
            console.error({ err });
        }
    };

    const handleDownload = () => {
        setTimeout(() => {
            onclose();
        }, 300);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>Implementation Guide</div>

            <div className={styles.content}>
                <div className={styles.buttonGroup}>
                    <div onClick={downloadPDF} className={styles.button}>
                        {loading && <CircularProgress color="white" style={{ height: 14, width: 14 }} />}
                        <span>Download</span>
                    </div>

                    <button className={styles.button} onClick={onclose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ImplementationGuide;
