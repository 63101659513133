/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Form from './Form';
import { ConversionDataEnum, EventTypeValue, IDAOEvent, IEventFrom } from '../UserConversionData.types';
import { CustomerConversionDataService } from '../../../../../../services/api/customerConversionData.service';
import { validateDomain } from '../utils';
import { toast } from 'react-toastify';

const UserConversionDataEventItem = ({
    event,
    eventList,
    onEdit,
    editMode: globalEditMode,
    handleDelete,
    isPermitted,
}: {
    event: IDAOEvent;
    eventList: string[];
    onEdit: (flag: boolean) => void;
    editMode: boolean;
    handleDelete: (tagId: number, id: number) => void;
    isPermitted: boolean;
}) => {
    const [eventFields, setEventFields] = useState<IEventFrom>({
        id: event.id,
        [ConversionDataEnum.SEARCH_ID]: event.tagId,
        [ConversionDataEnum.EVENT_NAME]: event.event,
        [ConversionDataEnum.EVENT_TYPE]: event.eventType,
        [ConversionDataEnum.TYPE]: event.type,
        [ConversionDataEnum.URL]: event.type === 1 ? '' : event.event,
    });
    const [editMode, setEditMode] = useState(false);

    useEffect(() => {
        onEdit(editMode);
    }, [editMode]);

    const onEventChange = async (property: string, selectedValue: EventTypeValue) => {
        switch (property) {
            case 'url': {
                setEventFields((s) => ({ ...s, url: selectedValue.value }));
                break;
            }
            case 'eventName': {
                setEventFields((s) => ({ ...s, eventName: selectedValue.value }));
                break;
            }
            case 'eventType': {
                setEventFields((s) => ({ ...s, eventType: selectedValue.value }));
                break;
            }
        }
    };

    const onSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        let eventDao: IDAOEvent;
        if (eventFields.url) {
            if (!validateDomain(String(eventFields[ConversionDataEnum.URL]))) {
                toast('Domain url not valid');
            } else {
                setEditMode(false);

                eventDao = createEventDao();
                CustomerConversionDataService.updateEvents(eventDao).then();
            }
        } else {
            setEditMode(false);
            eventDao = createEventDao();
            await CustomerConversionDataService.updateEvents(eventDao);
        }
    };

    const handleOnEditMode = () => {
        setEditMode(true);
    };
    const handleOnDelete = (tagId: number, id: number) => {
        handleDelete(tagId, id);
    };

    const handleOnCancel = () => {
        setEditMode(false);
    };
    const createEventDao = (): IDAOEvent => {
        console.log({ eventFields });
        if (eventFields.type == 1) {
            return {
                id: eventFields.id,
                tagId: eventFields.searchId,
                type: eventFields.type,
                event: eventFields.eventName,
                eventType: eventFields.eventType,
                networkId: event.networkId,
            };
        } else {
            return {
                id: eventFields.id,
                tagId: eventFields.searchId,
                type: eventFields.type,
                event: eventFields.url,
                eventType: eventFields.eventType,
                networkId: event.networkId,
            };
        }
    };

    return (
        <Form
            globalEditMode={globalEditMode}
            editMode={editMode}
            onSubmit={onSubmit}
            handleOnCancel={handleOnCancel}
            handleOnDelete={handleOnDelete}
            handleOnEditMode={handleOnEditMode}
            handleOnEventChange={onEventChange}
            eventFields={eventFields}
            eventList={eventList}
            isPermitted={isPermitted}
        />
    );
};

export default UserConversionDataEventItem;
