import { createTheme } from "@material-ui/core";

const COLORS = {
    DEFAULT: '#14172c',
    BACKGORUND: '#282b3f',
    PAPER: '#383851',
};

const theme = createTheme({
    transparent: '#00000000',
    palette: {
        custom: {
            rainbow: ['#fe0072', '#c92477', '#ba2d93', '#b826db', '#7d2aa0', '#6b1d95', '#4a1f74'],
            rainbow2: ['#f50e77', '#e156dc', '#b21cea', '#bd85ff', '#823cf6'],
            text: {
                primary: '#fe0072',
                secondary: 'white',
                disabled: 'rgba(255, 255, 255, 0.43)',
            },
        },
        type: 'dark',
        background: {
            default: COLORS.DEFAULT,
            paper: COLORS.BACKGORUND,
            component: COLORS.PAPER,
        },
        chart: {
            a: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, '#9250d5'],
                    [1, '#fe0072'],
                ],
            },
            b: '#5545a0',
            c: '#fe0072',
        },
    },
    typography: {
        table: {
            display: 'inline-block',
        },
        fontFamily: ['PingL'],
        fontWeight: 500,
        color: '#f1f2f6',
    },
    overrides: {
        MuiMenuItem: {
            root: {
                '&:hover': {
                    color: '#fe0072',
                },
                backgroundColor: '#fff',
                color: "#000",
                wordBreak: 'break-word',
                whiteSpace: 'break-spaces', 
                '&:focus-visible':{
                    backgroundColor: '#dde7f0'
                }
            },
        },
        MuiList:{
            root:{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
            }
        },
        MuiListItem:{
            gutters:{
                paddingLeft: '16px',
            }
        },
        MuiButtonBase:{
            root:{
                padding: '9px',
            }
        },
    }
});

export default theme;
