import { FC, forwardRef } from 'react'
import MaterialTable, { Options, Components, Column, Icons } from 'material-table';
import Progress from 'components/widgets/progress';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import Export from '@material-ui/icons/SaveAlt';
import ViewColumn from '@material-ui/icons/ViewColumn';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core';

const tableIcons: Icons = {
    Add: forwardRef<any>((props, ref) => <AddBox {...props} ref={ref} />),
    Delete: forwardRef<any>((props, ref) => <DeleteOutline {...props} ref={ref} />),
    Edit: forwardRef<any>((props, ref) => <Edit {...props} ref={ref} />),
    Check: forwardRef<any>((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
    Filter: forwardRef<any>((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef<any>((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef<any>((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef<any>((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef<any>((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef<any>((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef<any>((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef<any>((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ViewColumn: forwardRef<any>((props, ref) => <ViewColumn {...props} ref={ref} />),
    Export: forwardRef<any>((props, ref) => <Export {...props} ref={ref} />),
};

interface IProps {
    columns: Array<Column<any>>;
    rows: Array<object>;
    actions?: any;
    handleRowClick?: any;
    customComponents?: Components;
    options?: Options<object>;
    title?: string;
    isLoading?: boolean;
}

const useStyles = makeStyles(() => ({
    spinnerContainer: {
        height: '98vh',
        display: 'grid',
        placeContent: 'center'
    }
}));

const Table: FC<IProps> = ({ columns, rows, actions = [], handleRowClick = () => { }, customComponents = {}, options = {}, title = '', isLoading = false }) => {

    const classes = useStyles()

    return (
        isLoading ?
            <div className={classes.spinnerContainer}>
                <Progress />
            </div> :
            <MaterialTable
                icons={tableIcons}
                title={title}
                columns={columns}
                data={rows}
                actions={actions}
                onRowClick={handleRowClick}
                components={customComponents}
                options={options}
            />
    )
}

export default Table