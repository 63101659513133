import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";


export default ({className, placeholder, type = 'text', onChange = console.log, disabled, value: inputValue = '', ...props}) => {
    const handleChange = v => onChange(v);
    const classes = useStyle();

    return <input
        className={`${className} ${classes.input} ${inputValue ? classes.notEmpty : ''}`}
        placeholder={placeholder}
        type={type}
        value={inputValue}
        disabled={disabled}
        onChange={({ target: { value } }) => handleChange(value)}
        {...props}
    />
}
const useStyle = makeStyles(() => ({
    notEmpty: {
        border: 'solid 1px #fe0072 !important'
    },
    input: {
        fontSize: 14,
        borderRadius: 4,
        backgroundColor: 'hsl(0,0%,100%)',
        borderColor: 'hsl(0,0%,80%)',
        borderStyle: 'solid',
        borderWidth: 1,
        paddingLeft: 10,
        '&:focus': {
            outlineColor: '#fe0072'

        }
    }
}))