import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';

export default ({ Component, onClose, title, body, onSubmit = () => {}, onDismiss = () => {}, dismissText = 'No', submitText = 'Yes', isOpen, props }) => {
    return (
        <Dialog open={isOpen} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            {Component ? (
                <Component {...props} />
            ) : (
                <div>
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{body}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <button
                            className="cq-btn"
                            onClick={(e) => {
                                onSubmit(e);
                                onClose(e);
                            }}
                            autoFocus
                        >
                            {submitText}
                        </button>
                        <button
                            className="cq-btn"
                            onClick={(e) => {
                                onDismiss(e);
                                onClose(e);
                            }}
                        >
                            {dismissText}
                        </button>
                    </DialogActions>
                </div>
            )}
        </Dialog>
    );
};
