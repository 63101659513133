import { ConversionDataEnum, ConversionDataTypeEnum, IUserConversionDataStateType, IConversionDataAction } from './UserConversionData.types';

export const initialUserConversionDataState: IUserConversionDataStateType = {
    [ConversionDataEnum.TYPE]: { value: ConversionDataTypeEnum.CONVERSION_EVENT_NAME, label: 'Conversion Event Name' },
    events: [],
};

export const userConversionDataReducer = (state: IUserConversionDataStateType, action: IConversionDataAction): IUserConversionDataStateType => {
    const { type, payload } = action;

    switch (type) {
        case ConversionDataEnum.TYPE:
            return {
                ...initialUserConversionDataState,
                [ConversionDataEnum.TYPE]: payload.value,
            };

        default:
            if (payload?.index !== undefined) {
                const eventsState = state.events.map((event, index) => {
                    if (index === payload.index) {
                        return {
                            ...event,
                            [type]: payload.value,
                        };
                    }
                    return event;
                });
                return {
                    ...state,
                    events: eventsState,
                };
            } else {
                return state;
            }
    }
};
