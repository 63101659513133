import { FC, useEffect, useMemo, useState } from 'react';
import { selectUsers, isLoading as isLoadingUsers, initUsers } from 'redux/slicers/users';
import { getRegions, isLoading as isLoadingAccounts, selectAccounts } from 'redux/slicers/accounts';
import Table from 'components/widgets/table';
import { Column, MTableToolbar, Options } from 'material-table';
import { requirePermissions, selectPermissions } from 'redux/slicers/permissions';
import UserForm from './UserForm';
import { editUser, deleteUser, createUser, formatToTableStructure, formatToDbStructure, getRegionsOptions, getUserTypesOptions } from './utils';
import Dialog from 'components/widgets/dialog';
import styles from './styles';
import AddButton from 'components/widgets/add-button';
import Tooltip from 'components/widgets/tooltip';
import { UsersService } from '../../../services/api/users.service';
import Toast from '../../../services/notifications';
import { IFormattedOrgMember } from 'models/Common';
import { useAppSelector } from 'redux/store';
import { useDispatch } from 'react-redux';

const options: Options<object> = {
    filtering: true,
    search: true,
    pageSize: 20,
    tableLayout: 'fixed',
    doubleHorizontalScroll: true,
};

const UsersTable: FC = () => {
    const dispatch = useDispatch();
    const {
        childrens = [],
        childrensTypes = [],
        didFetchUsers,
    } = useAppSelector(selectUsers);
    const permissions = useAppSelector(selectPermissions);
    const { regions = [], didFetchAccounts } = useAppSelector(selectAccounts);
    const { regions: regionsLookup } = useAppSelector((state) => state.lookUps);
    const [dialogProps, setDialogProps] = useState<any>({});
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const classes = styles();
    const regionOptions = getRegionsOptions(regions);
    const usersTypesOpt = getUserTypesOptions(childrensTypes);

    const tableData = useMemo(() => childrens.map((user) => formatToTableStructure(user, regionsLookup)), [childrens, regionsLookup]);

    const token = window.localStorage.getItem('auth0Token');
    useEffect(() => {
        if (token && !didFetchUsers) {
            if (permissions.user && permissions.user.read) {
                dispatch(initUsers());
            }
            // init accounts also fetches the region, so checking to not fetch it twice for performance
            if (!didFetchAccounts) {
                dispatch(getRegions());
            }
        }
    }, [token, permissions]);

    const isLoading: any = dispatch(isLoadingAccounts('initAccounts')) || dispatch(isLoadingUsers());

    const handleRowClick = async (_a: any, rowData:IFormattedOrgMember) => {
        if (!rowData.id) {
            return;
        }
        if(rowData.id.startsWith('uinv_')) {
            Toast({type: 'info', message: `You can't edit pending user`});
            return;
        }
        const { userTypeName }: { userTypeName: string } = (await UsersService.getUserById(rowData.id)) || {};
        const user = { ...rowData, userTypeName };
        setDialogIsOpen(true);
        setDialogProps({
            Component: () =>
                UserForm({
                    user: formatToDbStructure(user),
                    currentUsers: childrens,
                    onDelete: () => {
                        setDialogIsOpen(false);
                        deleteUser({ id: rowData.id, userType: user.userTypeName });
                    },
                    onEdit: (updatedUser:IFormattedOrgMember) => {
                        setDialogIsOpen(false);
                        editUser(updatedUser, childrensTypes);
                    },
                    onClose: () => setDialogIsOpen(false),
                    usersTypes: usersTypesOpt,
                    childrensTypes,
                    regionOptions: regionOptions,
                }),
        });
    };

    const onCreate = () => {
        setDialogIsOpen(true);
        setDialogProps({
            Component: () =>
                UserForm({
                    user: {},
                    currentUsers: childrens,
                    onAdd: (user: IFormattedOrgMember) => {
                        setDialogIsOpen(false);
                        createUser(user, childrensTypes);
                    },
                    onClose: () => setDialogIsOpen(false),
                    usersTypes: usersTypesOpt,
                    childrensTypes,
                    regionOptions: regionOptions,
                }),
        });
    };

    // const renderAction = permissions.user && permissions.user.write;

    const COLUMNS: Column<IFormattedOrgMember>[] = [
        {
            field: 'name',
            title: 'Name',
            render: ({ name }) => (
                <Tooltip title={name} disable={false}>
                    <div className="ellipsis">{name}</div>
                </Tooltip>
            ),
        },
        {
            field: 'email',
            title: 'Email',
            render: ({ email }) => (
                <Tooltip title={email} disable={false}>
                    <div className="ellipsis">{email}</div>
                </Tooltip>
            ),
        },
        {
            field: 'regionName',
            title: 'Region',
            render: ({ regionName }) => (
                <Tooltip title={regionName} disable={false}>
                    <div className="ellipsis">{regionName}</div>
                </Tooltip>
            ),
        },
        {
            field: 'active',
            title: '' +
                'Active',
            render: ({ active }) => (
                <Tooltip title={active.toString()} disable={false}>
                    <div className="ellipsis">{active}</div>
                </Tooltip>
            ),
        },
    ];

    return (
        <>
            <Table
                columns={COLUMNS}
                rows={tableData}
                options={options}
                isLoading={isLoading}
                handleRowClick={handleRowClick}
                customComponents={{
                    Toolbar: (props) => (
                        <div className="table-toolbar">
                            <MTableToolbar {...props} />
                            {dispatch(
                                requirePermissions({
                                    required: ['user:write'],
                                    Component: <AddButton handleClick={onCreate} label="Add User" className={classes.addUserBtnNavBar} />,
                                })
                            )}
                        </div>
                    ),
                }}
            />
            <Dialog isOpen={dialogIsOpen} {...dialogProps} />
        </>
    );
};

export default UsersTable;
