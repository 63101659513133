import React, {useState} from "react";
import {resetInternalAdminPassword} from "redux/slicers/accounts";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Input from "../../widgets/Input";
import DialogActions from "@material-ui/core/DialogActions";
import {useDispatch} from "react-redux";
import bcrypt from 'bcryptjs';

const ResetPassword = ({setDialogResetPasswordIsOpen, account}) => {
    const dispatch = useDispatch();
    const passwordModel = {
        passwordOne: '',
        passwordTwo: ''
    };
    const [password, setPassword] = useState(passwordModel);
    const [errors, setErrors] = useState([]);
    const onClose = () => setDialogResetPasswordIsOpen(false);
    const onSubmit = () => {
        setErrors([]);
        const internalAdmin = account.internalAdmin;
        if (!internalAdmin) return true;
        if (!password.passwordOne || !password.passwordOne.length) {
            setErrors(s => [...s, 'Password is empty.']);
            return false;
        }
        if (password.passwordOne.length < 8) {
            setErrors(s => [...s, 'Password must be at least 8 characters long.']);
            return false;
        }
        if (!/[0-9]/.test(password.passwordOne)) {
            setErrors(s => [...s, 'Password must contain at least one digit.']);
            return false;
        }
        if (!/[!@#$%^&*]/.test(password.passwordOne)) {
            setErrors(s => [...s, 'Password must contain at least one special character.']);
            return false;
        }
        if (password.passwordOne !== password.passwordTwo) {
            setErrors(s => [...s, 'Passwords are not equal.']);
            return false;
        }

        dispatch(resetInternalAdminPassword({
            userId: internalAdmin.id,
            password: bcrypt.hashSync(password.passwordOne, 10, null),
        }));
        return true;
    };
    return <div>
        <DialogTitle id="alert-dialog-title">RESET PASSWORD</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <div className="password-container">
                    <div className="password-box">
                        <span>New Password</span>
                    </div>
                    <Input
                        type={'password'}
                        value={password.passwordOne}
                        onChange={
                            passwordOne => setPassword(s => ({...s, passwordOne}))
                        }
                    />
                </div>
                <div className="password-container">
                    <div className="password-box">
                        <span>Re-enter new password</span>
                    </div>
                    <Input
                        type={'password'}
                        value={password.passwordTwo}
                        onChange={passwordTwo => setPassword(s => ({...s, passwordTwo}))}
                    />
                </div>
                {errors.length ? errors.map(err => <div key={err} className='password-validation-error'>{err}</div>)
                    : ''
                }
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <button
                className="cq-btn"
                onClick={(e) => {
                    onClose(e);
                }}
            >
                CANCEL
            </button>
            <button
                className="cq-btn"
                onClick={(e) => {
                    const shouldClose = onSubmit(e);
                    if (shouldClose) {
                        onClose(e);
                    }
                }}
                autoFocus
            >
                SAVE
            </button>
        </DialogActions>
    </div>
}

export default ResetPassword;