import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {  ReactElement } from 'react';
import { AppDispatch, RootState } from 'redux/store';
import { IFormattedPermissionsObject, IPermissionsState, IRawPermissionsObject } from './types';

const initialState:IPermissionsState = {
    ready: false,
    permissions: {},
  }

export const permissionsSlice = createSlice({
    name: 'permissionsManager',
    initialState,
    reducers: {
        setPermissions:(state, action:PayloadAction<IFormattedPermissionsObject>) => {
            state.permissions = action.payload
        },
        setReady: (state, action:PayloadAction<boolean>) => {
            state.ready = action.payload;
        },
    },
})

const {setPermissions, setReady} = permissionsSlice.actions;

export const initPermissions = (permissionsObject:IRawPermissionsObject) => (dispatch:AppDispatch) => {
    dispatch(setPermissions(parsePermissions(permissionsObject.permissions)));
    dispatch(setReady(true));
};

export const requirePermissions = ({Component,required = [], Disabled = null }:{required:string[],Component:ReactElement,Disabled?:any}) =>
 (_dispatch: any, getState:()=>RootState) =>  {
    const { permissionsManager } = getState();
    return isPermitted(permissionsManager.permissions, required) ? Component : Disabled;
}


const isPermitted = (permissions:IFormattedPermissionsObject, required:any[]) => {
    return required.every(perm => {
        const [model, action] = perm.split(':');
        return permissions[model] && permissions[model][action];
    })
};

const parsePermissions = (permissions:string[] = []) => {
    const retVal = permissions.map(perm => {
        const split = perm.split(':');
        return {model: split[1], action: split[0]}
    }).reduce((prem, {model, action}) => ({...prem, [model]: {...prem[model], [action]: true}}),{});
    return retVal;
};
// user: {read:true, write:true}
export const selectPermissions = (state:RootState) => state.permissionsManager.permissions;

export default permissionsSlice.reducer;