import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const useStyle = makeStyles(theme => ({
    tooltip:{ backgroundColor: '#14172c', fontSize: 12, maxWidth: 'none' }
}));

export default ({title = ' ', children, disable, ...params })=> {
    const classes = useStyle();
    return <Tooltip classes={classes} title={title} disableHoverListener={disable || !title} {...params}>
        {children}
    </Tooltip>
}