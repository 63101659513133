import { FC } from 'react';
import AddButton from 'components/widgets/add-button';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectPermissions } from '../../../../redux/slicers/permissions';

interface IProps {
    className?: string,
    label: string,
    dest: string,
}

const AddAccountButton: FC<IProps> = ({ className = '', label='', dest}) => {
    const history = useHistory()
    const permissions = useSelector(selectPermissions);
    const userCanWrite = permissions.account && permissions.account.write;

    const handleAddAccount = () => {
        history.push(dest);
    };

    return userCanWrite ? (
        <AddButton handleClick={handleAddAccount} className={className} label={label}/>
    ) : <></>
}

export default AddAccountButton