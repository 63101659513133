import './styles.css';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import React, { useState } from 'react';
import { deleteNetwork, selectAccounts } from '../../../redux/slicers/accounts';
import { useDispatch, useSelector } from 'react-redux';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Dialog from '../../widgets/dialog';
import moment from 'moment';
import { selectPermissions } from '../../../redux/slicers/permissions';
import { selectSettings } from '../../../redux/slicers/settings';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';

const getNameFromId = (options, id, formater = (str) => str) => formater(options.reduce((found, current) => (current.id === id ? current.name : found), ''));

const NetworkProperty = ({ property, value }) => {
    return (
        <div className="field-group">
            <div className="field-key">{property}</div>
            <div className="field-value">{value ? value : 'N/A'}</div>
        </div>
    );
};

const NetworkList = ({ networks = [], account }) => {
    const [dialogParams, setDialogParams] = useState({});
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const { industries } = useSelector(selectSettings);
    const { mediaSpendTiers } = useSelector(selectAccounts);
    const permissions = useSelector(selectPermissions);
    const userCanWriteNetwork = permissions.network && permissions.network.write;

    const onEdit = (network) => {
        history.push(`/accounts/networks/${account.id}/edit/${network.id}`);
    };

    const renderEditButton = (network) => {
        if (userCanWriteNetwork) {
            return (
                <button className="cq-btn" onClick={() => onEdit(network)}>
                    EDIT
                </button>
            );
        }
        return <></>;
    };

    const renderDeleteButton = (network) => {
        if (userCanWriteNetwork && account?.isAgency && networks.length > 1 && network.active) {
            return (
                <button className="cq-btn" onClick={() => setDialogParams({ ...network, isOpen: true })}>
                    DELETE
                </button>
            );
        }
        return <></>;
    };
    return !networks.length ? (
        <div className="networks-container"> NO NETWORKS FOR ACCOUNT</div>
    ) : (
        <div className="networks-container">
            {networks
                .slice()
                .sort((a, b) => (a.active && b.active ? 0 : a.active ? -1 : 1))
                .map((network) => {
                    const { label: industry } = industries.find(({ value }) => network.industryId === value) || {};
                    return (
                        <Accordion
                            key={network.id}
                            TransitionProps={{ unmountOnExit: true }}
                            className={`${classes.accordionPanel} ${network.active ? '' : classes.inactiveNetwork}`}
                        >
                            <AccordionSummary className={classes.accordionPanelSummary} expandIcon={<AddCircleOutlineIcon color={'secondary'} />}>
                                {network.name}
                            </AccordionSummary>
                            <AccordionDetails className={classes.accordionPanelDetails}>
                                <div className="network-details-container">
                                    <div className="network-details-section">
                                        <NetworkProperty property={'Name'} value={network.name || '-'} />
                                        <NetworkProperty property={'Email'} value={network.email || '-'} />
                                        <NetworkProperty property={'Active'} value={network.active ? 'Yes' : 'No'} />
                                        <NetworkProperty property={'media spend'} value={getNameFromId(mediaSpendTiers, network.mediaSpendTierId) || '-'} />
                                        <NetworkProperty property={'Industry'} value={industry || '-'} />
                                    </div>
                                    <div className="network-details-section">
                                        <NetworkProperty property={'Pilot'} value={network.isPilot ? 'Yes' : 'No'} />
                                        <NetworkProperty property={'Time Zone'} value={network.tz} />
                                        <NetworkProperty property={'Date Created'} value={moment(network.dateCreated).format('YYYY-MM-DD')} />
                                    </div>
                                </div>
                                <div className="network-action-container">
                                    {renderEditButton(network)}
                                    {renderDeleteButton(network)}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            <Dialog
                onClose={() => setDialogParams()}
                isOpen={dialogParams.isOpen}
                onSubmit={() => {
                    dispatch(deleteNetwork({ id: dialogParams.id, accountId: account.id }));
                }}
                title={'Delete Network'}
                body={`Are you sure you want to delete ${dialogParams.name}?`}
            />
        </div>
    );
};

export default NetworkList;
