import React from 'react';
import { Button, IconButton } from '@material-ui/core';
import Input from 'components/widgets/Input';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { ConversionDataEnum, EventTypeValue, IEventFrom } from '../UserConversionData.types';
import { ControlContainer, FormLabelStyle, GroupControls } from './UserConversionDataEvent.style';
import { customerConversionDataOptions } from '../UserConversionData.config';
import { Select } from 'components/widgets/select';
import useStyle from '../../../styles';
import { selectStyle } from '../../../../selectStyle';

const commonSelectProps = {
    isMulti: false,
};
// const useStyles = makeStyles({
//     root: {
//         borderRadius: 4,
//         backgroundColor: '#282b3f',
//
//         marginBottom: 7,
//         margin: '7px -20px',
//     },
// });
const conversionDataSelectStyle = { ...selectStyle, container: { ...selectStyle.container } };
const Form = ({
    eventList,
    onSubmit,
    editMode,
    globalEditMode,
    eventFields,
    handleOnEventChange,
    handleOnDelete,
    handleOnEditMode,
    handleOnCancel,
    addRulesComponent,
    isPermitted,
}: {
    eventList: string[];
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    editMode: boolean;
    globalEditMode: boolean;
    eventFields: IEventFrom;
    handleOnEventChange: (property: string, value: EventTypeValue) => void;
    handleOnDelete: (tagId: number, id: number) => void;
    handleOnEditMode: () => void;
    handleOnCancel: () => void;
    addRulesComponent?: () => void;
    isPermitted: boolean;
}) => {
    const classes = useStyle();
    return (
        <form onSubmit={onSubmit} className={`${'rule-row'} ${!editMode && globalEditMode && 'rule-row-blur'}`}>
            <div className={`${'rule-content'} `}>
                <div className="rule-content-left">
                    {eventFields.type === 1 ? (
                        <GroupControls>
                            <ControlContainer>
                                <FormLabelStyle
                                    label="Event Name"
                                    labelPlacement="start"
                                    control={
                                        <Select
                                            styles={conversionDataSelectStyle}
                                            options={eventList?.map((event) => ({
                                                value: event,
                                                label: event.replaceAll('_', ' '),
                                            }))}
                                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
                                            onChange={(selectedOption: EventTypeValue) => handleOnEventChange('eventName', selectedOption)}
                                            value={{
                                                value: eventFields.eventName,
                                                label: eventFields.eventName?.replaceAll('_', ' '),
                                            }}
                                            placeholder="Select Event Name"
                                            isDisabled={!editMode}
                                            {...commonSelectProps}
                                        />
                                    }
                                />
                            </ControlContainer>
                            <ControlContainer>
                                <FormLabelStyle
                                    label="Event Type"
                                    labelPlacement="start"
                                    control={
                                        <Select
                                            styles={conversionDataSelectStyle}
                                            options={customerConversionDataOptions[ConversionDataEnum.EVENT_TYPE]}
                                            onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
                                            onChange={(selectedOption: EventTypeValue) => handleOnEventChange('eventType', selectedOption)}
                                            value={{
                                                value: eventFields.eventName,
                                                label: eventFields.eventType?.replaceAll('_', ' '),
                                            }}
                                            placeholder="Select Event Type"
                                            isDisabled={!editMode}
                                            {...commonSelectProps}
                                        />
                                    }
                                />
                            </ControlContainer>
                        </GroupControls>
                    ) : (
                        <GroupControls>
                            <ControlContainer>
                                <FormLabelStyle
                                    label="URL"
                                    labelPlacement="start"
                                    control={
                                        <Input
                                            className={classes.midInput}
                                            placeholder="Enter URL"
                                            style={conversionDataSelectStyle}
                                            type="text"
                                            value={eventFields?.url ?? ''}
                                            disabled={!editMode}
                                            onChange={(value: string) => {
                                                handleOnEventChange('url', { value: value, label: value });
                                            }}
                                        />
                                    }
                                />
                            </ControlContainer>
                        </GroupControls>
                    )}
                </div>

                {!editMode && isPermitted && (
                    <div className="rule-content-right">
                        <IconButton onClick={() => handleOnDelete(Number(eventFields.searchId), Number(eventFields.id))} disabled={!editMode && globalEditMode}>
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={handleOnEditMode} disabled={!editMode && globalEditMode}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </div>
                )}
            </div>

            {editMode && (
                <div className="button-group">
                    {!addRulesComponent && (
                        <>
                            <div className="button">
                                <Button fullWidth variant="contained" color="secondary" type="submit">
                                    save
                                </Button>
                            </div>
                            <div className="button">
                                <Button fullWidth className="button-text" onClick={handleOnCancel}>
                                    cancel
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </form>
    );
};

export default Form;
