import { createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
import {
    isLoadingSlice,
    thunkPending,
    thunkRejected
} from 'services/redux';
import ajax from 'services/ajax';
import { AccountsService } from 'services/api/accounts.service';
import { LicensesService } from 'services/api/licenses.service';
import moment from 'moment';
import { FrequencyCappingService } from 'services/api/frequencyCapping.service';
import { UsersService } from 'services/api/users.service';
import { mapIds } from 'components/containers/accounts/CreateAccountForm/AccountForm';
import { IAccountsState, IInitAccountsFullfiledValue } from './types';
import { RootState } from 'redux/store';
import { IFormattedAccount, IGetAccountResponse } from 'models/Account';
import { IGetRegionResponse } from 'models/Region';
import { ISupporter } from '../lookups/types';

const networksAjax = ajax({ baseRoute: 'networks' });
const settingsAjax = ajax({ baseRoute: 'settings' });

const createAppAsyncThunk = createAsyncThunk.withTypes<{
    state: IAccountsState,
    dispatch: Dispatch<any>;
}>();

export const initAccounts = createAppAsyncThunk<IInitAccountsFullfiledValue, void>('initAccounts', async () => {
    const [accounts, regions, products, accountTypes, bundlePlans, mediaSpendTiers, accountsSupporters, dataLocations] = await Promise.all([
        AccountsService.getAccounts(),
        AccountsService.getAllRegions(),
        LicensesService.getProducts(),
        AccountsService.getAccountTypes(),
        AccountsService.getBundlePlans(),
        AccountsService.getMediaSpendTiers(),
        AccountsService.getAccountsSupporters(),
        AccountsService.getDataLocations(),
    ]);
    return {
        accounts,
        regions,
        products,
        accountTypes,
        bundlePlans,
        mediaSpendTiers,
        accountsSupporters,
        dataLocations,
    };
});

export const getRegions = createAppAsyncThunk<IGetRegionResponse[], void>('getRegions', async () => await AccountsService.getAllRegions());

export const createAccount = createAppAsyncThunk<any, any>('createAccount', async (account, { dispatch }) => {
    const res: any = await AccountsService.create(account);
    const { regionId, orgId } = res.data || {};
    UsersService.addCheqUsersToOrganizations(regionId, orgId);
    account.network_id = res.networkId;
    account.accountId = res.accountId;
    dispatch(upsertLicense(account));
    dispatch(
        updateIntegrationsLicense({
            networkId: res.networkId,
            products: account.products.map((p: { id: any; }) => {
                return { value: p.id };
            }),
        })
    );
    return res;
});

export const addUserToAccountSupporters = createAppAsyncThunk('addUserToAccountSupporters', async (userInfo) => {
    return await AccountsService.addUserToAccountSupporters(userInfo);
});

export const editAccountSupporter = createAppAsyncThunk('editAccountSupporter', async (userInfo) => {
    return await AccountsService.editAccountSupporter(userInfo);
});

export const deleteAccountSupporter = createAppAsyncThunk<any, any>('deleteAccountSupporter', async (id) => {
    await AccountsService.deleteAccountSupporter(id);
});

export const handleAccountStatus = createAppAsyncThunk<any, any>('handleAccountStatus', (account) => AccountsService.handleAccountStatus(account));
export const updateAccount = createAppAsyncThunk<any, any>('updateAccount', (account) => AccountsService.update(account));
export const resetInternalAdminPassword = createAppAsyncThunk<any, any>('resetInternalAdminPassword', (params) => AccountsService.resetInternalAdminPassword(params));
export const sendNotificationtoAccount = createAppAsyncThunk('sendNotificationtoAccount', (params) => AccountsService.sendAdminActivationEmail(params));
export const createNetwork = createAppAsyncThunk<any, any>('createNetwork', (params) => networksAjax('create_network', params));
export const updateNetwork = createAppAsyncThunk<any, any>('updateNetwork', (params) => networksAjax('update_network', params));
export const deleteNetwork = createAppAsyncThunk<any, any>('deleteNetwork', (params) => networksAjax('delete_network', { id: params.id }));

export const getAssociatedNetworks = createAppAsyncThunk('getAssociatedNetworks', async (params: any, { getState, dispatch }) => {
    const { accounts } = getState();
    const { accountId } = params;
    const currentAccount = Array.isArray(accounts) ? accounts.find((a) => a.id === accountId) : undefined;
    if (currentAccount && currentAccount.networks) {
        /* @ts-ignore */
        dispatch(getLicense(currentAccount.networks.id));
        /* @ts-ignore */
        dispatch(fetchNetworkRules({ networkId: currentAccount.networks.id }));
        return Promise.resolve([]);
    } else {
        const res = await AccountsService.getAssociatedNetworks(params);
        dispatch(getLicense({ id: res[0].id, accountId }));
        dispatch(fetchNetworkRules({ networkId: res[0].id }));
        return res;
    }
});

export const getNextAvailableDomain = createAppAsyncThunk<any, any>('getNextAvailableDomain', (params) => networksAjax('get_next_available_domain', params));

export const getAccountInternalAdmin = createAppAsyncThunk<any, any>('getAccountInternalAdmin', (params) => AccountsService.getAccountInternalAdmin(params));

export const getNetworkFeatures = createAppAsyncThunk<any, any>('get_network_features', (params) => {
    return settingsAjax('get_network_features', params);
});

export const toggleNetworkFeature = createAppAsyncThunk<any, any>('toggle_network_feature', (params) => settingsAjax('toggle_network_feature', params));
export const fetchNetworkRules = createAppAsyncThunk<any, any>('fetchNetworkRules', (params) => FrequencyCappingService.fetchRulesByNetworkId(params));
export const updateNetworkRule = createAppAsyncThunk<any, any>('updateNetworkRule', async (params, { dispatch }) => {
    try {
        const res = await FrequencyCappingService.updateRule(params);
        dispatch(fetchNetworkRules({ networkId: params.networkId }));
        return res;
    } catch (e) {
        console.error('updateNetworkRule.createAsyncThunk return with exception: ', e);
    }
});
export const addNetworkRule = createAppAsyncThunk<any, any>('addNetworkRule', async (params, { dispatch }) => {
    try {
        const res = await FrequencyCappingService.addRule(params);
        dispatch(fetchNetworkRules({ networkId: params.networkId }));
        return res;
    } catch (e) {
        console.error('addNetworkRule.createAsyncThunk return with exception: ', e);
    }
});
export const deleteNetworkRule = createAppAsyncThunk('deleteNetworkRule', (params) => FrequencyCappingService.deleteRule(params));

export const getLicense = createAppAsyncThunk<any, any>('getLicense', async ({ id }) => {
    return await LicensesService.getById(id);
});

export const updateIntegrationsLicense = createAppAsyncThunk<any, any>('updateIntegrationsLicense', async (params) => {
    try {
        params.hasOnSiteConversion = params.products.some((product: any) => product.value === 2);
        delete params.products;
        return await AccountsService.updateIntegrationsLicense(params);
    } catch (e) {
        console.error('updateIntegrationsLicense.createAsyncThunk return with exception: ', e);
    }
});

export const upsertLicense = createAppAsyncThunk<any, any>('upsertLicense', async (params) => {
    try {
        params.products = convertParamsToProductsIds(params);
        return JSON.parse(await AccountsService.updateAccountLicenses(params));
    } catch (e) {
        console.error('upsertLicense.createAsyncThunk return with exception: ', e);
    }
});

const convertParamsToProductsIds = (params: any) => {
    return params?.products.map((product: any) => {
        return { id: product.value };
    });
};

const initialState: IAccountsState = {
    accounts: [],
    errors: [],
    loading: [],
    csms: [],
    ses: [],
    mediaSpendTiers: [],
    didFetchAccounts: false,
    regions: [],
    products: [],
    accountTypes: [],
    bundlePlans: [],
    dataLocations: [],
    license: [],
    rules: []
};

export const accountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {},
    extraReducers(builder) {
        // init accounts
        builder.addCase(initAccounts.pending, thunkPending(initAccounts.typePrefix)),
            builder.addCase(initAccounts.rejected, thunkRejected(initAccounts.typePrefix)),
            builder.addCase(initAccounts.fulfilled, (state, { payload }) => {
                state.loading = state.loading.filter((l) => l !== initAccounts.typePrefix);
                state.didFetchAccounts = true;
                const { accounts, regions, products, accountTypes, bundlePlans, mediaSpendTiers, accountsSupporters, dataLocations } = payload;
                Object.assign(state, {
                    accounts: parseAccounts(accounts),
                    regions: regions.map(({ id, region }) => ({ name: region.toUpperCase(), id })),
                    products: products.products,
                    accountTypes,
                    bundlePlans,
                    mediaSpendTiers,
                    dataLocations,
                });
                let csms: ISupporter[] = [];
                let ses: ISupporter[] = [];

                accountsSupporters.forEach((supporter) => {
                    if (supporter.userTypeName === 'se') ses.push(supporter);
                    else if (supporter.userTypeName === 'csm') csms.push(supporter);
                    else if (['console_vp_admin', 'console_team_lead'].includes(supporter.userTypeName)) {
                        csms.push(supporter);
                        ses.push(supporter);
                    }
                });
                const key = 'id';
                const csmsUniqueByKey = [...new Map(csms.map((item) => [item[key], item])).values()];
                const sesUniqueByKey = [...new Map(ses.map((item) => [item[key], item])).values()];
                state.csms = [...state.csms, ...csmsUniqueByKey];
                state.ses = [...state.ses, ...sesUniqueByKey];
            });

        // get regions
        builder.addCase(getRegions.pending, thunkPending(getRegions.typePrefix)),
            builder.addCase(getRegions.rejected, thunkRejected(getRegions.typePrefix)),
            builder.addCase(getRegions.fulfilled, (state, { payload }) => {
                state.loading = state.loading.filter((l) => l !== getRegions.typePrefix);
                state.regions = payload.map(({ id, region }) => ({ name: region.toUpperCase(), id }));
            });

        // create account
        builder.addCase(createAccount.pending, thunkPending(createAccount.typePrefix)),
            builder.addCase(createAccount.rejected, thunkRejected(createAccount.typePrefix)),
            builder.addCase(createAccount.fulfilled, (state, { payload }) => {
                state.loading = state.loading.filter((l) => l !== createAccount.typePrefix);
                const { createdDate } = payload.data;
                if (createdDate == null) {
                    payload.data.createdDate = formatDate(new Date());
                }
                state.accounts = [...state.accounts, payload.data];
            });

        // update account
        builder.addCase(updateAccount.pending, thunkPending(updateAccount.typePrefix)),
            builder.addCase(updateAccount.rejected, thunkRejected(updateAccount.typePrefix)),
            builder.addCase(updateAccount.fulfilled, (state, { meta }) => {
                state.loading = state.loading.filter((l) => l !== updateAccount.typePrefix);
                const accountData = { ...meta.arg };
                state.accounts = state.accounts.map((account) => (account.id !== accountData.id ? account : { ...account, ...accountData }));
            });

        // handle account status
        builder.addCase(handleAccountStatus.pending, thunkPending(handleAccountStatus.typePrefix)),
            builder.addCase(handleAccountStatus.rejected, thunkRejected(handleAccountStatus.typePrefix)),
            builder.addCase(handleAccountStatus.fulfilled, (state, { meta }) => {
                state.loading = state.loading.filter((l) => l !== handleAccountStatus.typePrefix);
                const { accountId, active } = meta.arg;
                state.accounts.map(account => {
                    if (account.id === accountId) {
                        account.active = active;
                    }
                    return account;
                });
                state.accounts = [...state.accounts, meta.arg];
            });

        // add user to account supporters
        builder.addCase(addUserToAccountSupporters.pending, thunkPending(addUserToAccountSupporters.typePrefix)),
            builder.addCase(addUserToAccountSupporters.rejected, thunkRejected(addUserToAccountSupporters.typePrefix)),
            builder.addCase(addUserToAccountSupporters.fulfilled, (state, { payload }) => {
                state.loading = state.loading.filter((l) => l !== addUserToAccountSupporters.typePrefix);
                const { userTypeName } = payload;
                if (userTypeName === 'se') state.ses = [...state.ses, payload];
                else if (userTypeName === 'csm') state.csms = [...state.csms, payload];
                else if (['console_vp_admin', 'console_team_lead'].includes(userTypeName)) {
                    state.csms = [...state.csms, payload];
                    state.ses = [...state.ses, payload];
                }
            });

        // edit account supporter
        builder.addCase(editAccountSupporter.pending, thunkPending(editAccountSupporter.typePrefix)),
            builder.addCase(editAccountSupporter.rejected, thunkRejected(editAccountSupporter.typePrefix)),
            builder.addCase(editAccountSupporter.fulfilled, (state, { payload }) => {
                state.loading = state.loading.filter((l) => l !== editAccountSupporter.typePrefix);
                const { userTypeName, id } = payload;

                state.csms = state.csms.filter((csm) => csm.id !== id);
                state.ses = state.ses.filter((se) => se.id !== id);

                if (userTypeName === 'se') state.ses = [...state.ses, payload];
                else if (userTypeName === 'csm') state.csms = [...state.csms, payload];
                else if (['console_vp_admin', 'console_team_lead'].includes(userTypeName)) {
                    state.csms = [...state.csms, payload];
                    state.ses = [...state.ses, payload];
                }
            });

        // delete account supporter
        builder.addCase(deleteAccountSupporter.pending, thunkPending(deleteAccountSupporter.typePrefix)),
            builder.addCase(deleteAccountSupporter.rejected, thunkRejected(deleteAccountSupporter.typePrefix)),
            builder.addCase(deleteAccountSupporter.fulfilled, (state, { meta }) => {
                state.loading = state.loading.filter((l) => l !== deleteAccountSupporter.typePrefix);
                const id = meta.arg;
                state.csms = state.csms.filter((csm) => csm.id !== id);
                state.ses = state.ses.filter((se) => se.id !== id);
            });

        // reset internal admin password
        builder.addCase(resetInternalAdminPassword.pending, thunkPending(resetInternalAdminPassword.typePrefix)),
            builder.addCase(resetInternalAdminPassword.rejected, thunkRejected(resetInternalAdminPassword.typePrefix)),
            builder.addCase(resetInternalAdminPassword.fulfilled, (state, { payload, meta }) => {
                const { message, success } = payload;
                if (!success) {
                    console.warn(message);
                    return;
                } else {
                    console.warn(message);
                }
                state.loading = state.loading.filter((l) => l !== resetInternalAdminPassword.typePrefix);
                const accountData = { ...meta.arg };
                state.accounts = state.accounts.map((account) => (account.id !== accountData.id ? account : { ...account, ...accountData }));
            });

        // get account internal admin
        builder.addCase(getAccountInternalAdmin.pending, thunkPending(getAccountInternalAdmin.typePrefix)),
            builder.addCase(getAccountInternalAdmin.rejected, thunkRejected(getAccountInternalAdmin.typePrefix)),
            builder.addCase(getAccountInternalAdmin.fulfilled, (state, { payload, meta }) => {
                state.loading = state.loading.filter((l) => l !== getAccountInternalAdmin.typePrefix);
                const accountData = { ...meta.arg };
                if (payload) {
                    state.accounts = state.accounts.map((account) =>
                        account.name !== accountData.accountName ? account : { ...account, internalAdmin: { id: payload.id, active: payload.active, username: payload.username } }
                    );
                }
            });

        // create network
        builder.addCase(createNetwork.pending, thunkPending(createNetwork.typePrefix)),
            builder.addCase(createNetwork.rejected, thunkRejected(createNetwork.typePrefix)),
            builder.addCase(createNetwork.fulfilled, (state, { payload, meta }) => {
                state.loading = state.loading.filter((l) => l !== createNetwork.typePrefix);
                const { accountId } = meta.arg;
                const accountNetworks = getAccountNetworks(state.accounts, accountId) || [];
                state.accounts = state.accounts.map((account) => (account.id !== accountId ? account : { ...account, networks: [...accountNetworks, payload] }));
            });

        // update network
        builder.addCase(updateNetwork.pending, thunkPending(updateNetwork.typePrefix)),
            builder.addCase(updateNetwork.rejected, thunkRejected(updateNetwork.typePrefix)),
            builder.addCase(updateNetwork.fulfilled, (state, { meta }) => {
                state.loading = state.loading.filter((l) => l !== updateNetwork.typePrefix);
                const { accountId, id: networkId, ...params } = meta.arg;
                const updatedNetworks = getUpdatedNetworksForAccount(state.accounts, accountId, networkId, { ...params });
                if (!updatedNetworks) return;
                state.accounts = state.accounts.map((account) => (account.id !== accountId ? account : { ...account, networks: updatedNetworks }));
            });

        // delete network
        builder.addCase(deleteNetwork.pending, thunkPending(deleteNetwork.typePrefix)),
            builder.addCase(deleteNetwork.rejected, thunkRejected(deleteNetwork.typePrefix)),
            builder.addCase(deleteNetwork.fulfilled, (state, { meta }) => {
                state.loading = state.loading.filter((l) => l !== deleteNetwork.typePrefix);
                const { accountId, id } = meta.arg;
                const updatedNetworks = getUpdatedNetworksForAccount(state.accounts, accountId, id, { active: false });
                if (!updatedNetworks) return;
                state.accounts = state.accounts.map((account) => (account.id !== accountId ? account : { ...account, networks: updatedNetworks }));
            });

        // get associated networks
        builder.addCase(getAssociatedNetworks.pending, thunkPending(getAssociatedNetworks.typePrefix)),
            builder.addCase(getAssociatedNetworks.rejected, thunkRejected(getAssociatedNetworks.typePrefix)),
            builder.addCase(getAssociatedNetworks.fulfilled, (state, { meta, payload }) => {
                state.loading = state.loading.filter((l) => l !== getAssociatedNetworks.typePrefix);
                const { accountId } = meta.arg;
                state.accounts = state.accounts.map((account) =>
                    account.id !== accountId
                        ? account
                        : {
                            ...account,
                            networks: payload,
                        }
                );
            });

        // get next available domain
        builder.addCase(getNextAvailableDomain.pending, thunkPending(getNextAvailableDomain.typePrefix)),
            builder.addCase(getNextAvailableDomain.rejected, thunkRejected(getNextAvailableDomain.typePrefix)),
            builder.addCase(getNextAvailableDomain.fulfilled, (state, { meta, payload }) => {
                state.loading = state.loading.filter((l) => l !== getNextAvailableDomain.typePrefix);
                const { accountId, networkId } = meta.arg;
                const updatedNetworks = getUpdatedNetworksForAccount(state.accounts, accountId, networkId, { whiteLabelDomain: payload });
                if (!updatedNetworks) return;
                state.accounts = state.accounts.map((account) => (account.id !== accountId ? account : { ...account, networks: updatedNetworks }));
            });

        // get network features
        builder.addCase(getNetworkFeatures.pending, thunkPending(getNetworkFeatures.typePrefix)),
            builder.addCase(getNetworkFeatures.rejected, thunkRejected(getNetworkFeatures.typePrefix)),
            builder.addCase(getNetworkFeatures.fulfilled, (state, { meta, payload }) => {
                state.loading = state.loading.filter((l) => l !== getNetworkFeatures.typePrefix);
                const { accountId, networkId } = meta.arg;
                const updatedNetworks = getUpdatedNetworksForAccount(state.accounts, accountId, networkId, { networkFeatures: payload });
                if (!updatedNetworks) return;
                state.accounts = state.accounts.map((account) => (account.id !== accountId ? account : { ...account, networks: updatedNetworks }));
            });

        // toggle network features
        builder.addCase(toggleNetworkFeature.pending, thunkPending(toggleNetworkFeature.typePrefix)),
            builder.addCase(toggleNetworkFeature.rejected, thunkRejected(toggleNetworkFeature.typePrefix)),
            builder.addCase(toggleNetworkFeature.fulfilled, (state, { meta, payload }) => {
                state.loading = state.loading.filter((l) => l !== toggleNetworkFeature.typePrefix);
                const { accountId, networkId, featureId, enabled } = meta.arg;
                const { message, success } = payload;
                if (!success) {
                    console.warn(message);
                    return;
                }
                const selectedNetwork = getAccountNetworks(state.accounts, accountId)?.find((n: any) => n.id === networkId);
                const updatedNetworkFeatures = selectedNetwork?.networkFeatures?.map((nf: any) => {
                    const feature = { ...nf };
                    return feature.id !== featureId ? nf : { ...feature, enabled: enabled };
                });
                const updatedNetworks = getUpdatedNetworksForAccount(state.accounts, accountId, networkId, { networkFeatures: updatedNetworkFeatures });
                if (!updatedNetworks) return;
                state.accounts = state.accounts.map((account) =>
                    account.id !== accountId
                        ? account
                        : {
                            ...account,
                            networks: updatedNetworks,
                        }
                );
            });

        // get license
        builder.addCase(getLicense.pending, thunkPending(getLicense.typePrefix)),
            builder.addCase(getLicense.rejected, thunkRejected(getLicense.typePrefix)),
            builder.addCase(getLicense.fulfilled, (state, { meta, payload }) => {
                try {
                    state.loading = state.loading.filter((l) => l !== getLicense.typePrefix);
                    const { accountId } = meta.arg;
                    state.license = {
                        ...state.license,
                        [accountId]: { ...payload },
                    };
                    state.accounts = [
                        ...state.accounts?.map((account) =>
                            +account.id === +accountId
                                ? {
                                    ...account,
                                    products: filterAccountProduct(state.products, payload) || [],
                                }
                                : account
                        ),
                    ];
                } catch (e) {
                    console.error('getLicense.fulfilled return with exception: ', e);
                }
            });

        // upsert license
        builder.addCase(upsertLicense.pending, thunkPending(upsertLicense.typePrefix)),
            builder.addCase(upsertLicense.rejected, thunkRejected(upsertLicense.typePrefix)),
            builder.addCase(upsertLicense.fulfilled, (state, { meta, payload }) => {
                try {
                    if (state.loading.includes(upsertLicense.typePrefix)) {
                        state.loading = state.loading.filter((l) => l !== upsertLicense.typePrefix);
                        const { accountId } = meta.arg;

                        state.license[accountId] = {
                            ...(state.license[accountId] || []),
                            products: payload
                                ? (payload.products || []).map((product: any) => {
                                    return { id: product.id };
                                })
                                : state.license[accountId].products,
                        };
                    }
                } catch (e) {
                    console.error('upsertLicense.fulfilled return with exception: ', e);
                }
            });

        // update integrations license
        builder.addCase(updateIntegrationsLicense.pending, thunkPending(updateIntegrationsLicense.typePrefix)),
            builder.addCase(updateIntegrationsLicense.rejected, thunkRejected(updateIntegrationsLicense.typePrefix)),
            builder.addCase(updateIntegrationsLicense.fulfilled, (state) => {
                state.loading = state.loading.filter((l) => l !== updateIntegrationsLicense.typePrefix);
            });

        // fetch network rules
        builder.addCase(fetchNetworkRules.pending, thunkPending(fetchNetworkRules.typePrefix)),
            builder.addCase(fetchNetworkRules.rejected, thunkRejected(fetchNetworkRules.typePrefix)),
            builder.addCase(fetchNetworkRules.fulfilled, (state, { meta, payload }) => {
                state.loading = state.loading.filter((l) => l !== fetchNetworkRules.typePrefix);
                const { networkId } = meta.arg;
                state.rules = { ...state.rules, [networkId]: payload || [] };
            });
    },
});

const filterAccountProduct = (products: any, license: any): any => {
    return products?.map(mapIds((str) => str.charAt(0).toUpperCase() + str.substr(1))).filter((option: { value: any; }) => license?.products.map((product: { id: any; }) => product.id).includes(option.value));
};

// const {setErrors, remove, update, setNetworks, updateNetwork, removeNetwork } = accountsSlice.actions;

export const isLoading = isLoadingSlice('accounts');
export const selectAccounts = (state: RootState) => state.accounts;

const formatDate = (date: Date) => moment(date, 'YYYY-MM-DD HH:mm:SS').format('YYYY-MM-DD');

const parseAccounts = (accounts: IGetAccountResponse[] = []): IFormattedAccount[] => {
    return accounts?.map((account) => ({
        id: account.id,
        name: account.name,
        accountType: account.accountType,
        regionId: account.operationRegion,
        isAgency: account.isAgency,
        isBlockingControlGroup: account.isBlockingControlGroup,
        dataLocationId: account.dataLocationId,
        productId: account.productId,
        products: [],
        bundlePlan: account.bundlePlan,
        email: account.contactEmail,
        seId: account.seId,
        endDate: formatDate(account.pocEndDate),
        createdDate: formatDate(account.createdDate),
        csmId: account.csmId,
        csmAuth0Id: account.csmAuth0Id,
        seAuth0Id: account.seAuth0Id,
        domain: account.domain,
        logoUrl: account.logoUrl,
        mediaBudget: account.budget,
        active: account.active,
        phone: account.phone,
        billingAddress: account.billingAddress,
        country: account.country,
        city: account.city,
        zipCode: account.zipCode,
        mediaSpendTierId: account.mediaSpendTierId,
        networkId: account.networkId,
        orgId: account.orgId,
    }));
};

const getAccountNetworks = (accounts: any, accountId: any) => {
    const existingAccount = accounts.find((a: any) => a.id === accountId);
    const accountNetworks = existingAccount?.networks;
    return (accountNetworks || []).map((an: any) => ({ ...an }));
};

const getUpdatedNetworksForAccount = (accounts: any, accountId: any, networkId: any, updatedNetworkParams = {}) => {
    const networks = getAccountNetworks(accounts, accountId);
    const updatedNetworks = networks.map((network: { id: any; }) => (network.id !== networkId ? network : { ...network, ...updatedNetworkParams }));
    return updatedNetworks;
};

export default accountsSlice.reducer;
