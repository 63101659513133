import React, {useState} from 'react';
import MuiSwitch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {withStyles, makeStyles} from "@material-ui/core/styles";
import Progress from '../progress/index';


const useStyle = (checkedLabel = '', uncheckedLabel = '', disabled) => makeStyles(theme => ({
    root: {
        width: 43,
        height: 20,
        padding: 0,
        margin: theme.spacing(1),
        cursor : disabled ? 'default' : 'pointer',
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(25px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: '#fe0072',
                opacity: 1,
                border: 'none',
                '&::before': {
                    content: `"${checkedLabel}"`,
                    color: '#ffffff',
                    fontSize: 10,
                    marginLeft: 6
                },
                '&::after': {
                    display: 'none'
                }
            },
        },
        '&$focusVisible $thumb': {
            color: '#fe0072',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 16,
        height: 16,
        marginTop: 1,
        backgroundColor: '#ffffff'
    },
    track: {
        height: '100%',
        width: '100%',
        borderRadius: 26 / 2,
        border: `none`,
        backgroundColor: 'gray',
        opacity: 1,
        display: 'flex',
        alignItems: 'center',
        '&::after': {
            content: `"${uncheckedLabel}"`,
            fontSize: 10,
            color: disabled? 'black' : '#fe0072',
            marginLeft: 21
        },

    },
    checked: {},
    focusVisible: {},
	formRoot : {
        cursor : disabled ? 'default':'pointer',
        marginRight:0,
    }
}));


const Switch = ({onChange = console.log, checked, checkedLabel = '', uncheckedLabel = '', className = '', isLoading, progressContainerStyle,disabled, ...props }) => {
    const classes = useStyle(checkedLabel, uncheckedLabel,disabled)();
    return (
        <FormControlLabel
            classes={{root : classes.formRoot}}
            className={`${className}`}
            control={
                <>
                    {isLoading &&
                    <div style={{ position: 'relative' }}>
                        <div style={{ position: 'absolute', zIndex: 1, ...progressContainerStyle }}><Progress
                            size={13}/></div>
                    </div>
                    }
                    <MuiSwitch
                        disabled={disabled}
                        focusVisibleClassName={classes.focusVisible}
                        onMouseDown={e=>e.preventDefault()}
                        disableRipple
                        classes={{
                            root: classes.root,
                            switchBase: classes.switchBase,
                            thumb: classes.thumb,
                            track: classes.track,
                            checked: classes.checked,
                        }}
                        checked={checked}
                        onChange={e => {
                            return onChange(e.target.checked)
                        }}
                        {...props}
                    />

                </>
            }>
        </FormControlLabel>
    )
}

export default Switch;
