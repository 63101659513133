import React from "react";


const getStyle =(lineHeight, multiLine) => {
    return {
        fontFamily: 'PingLThin',
        letterSpacing: '1.3px',
        fontSize: '15px',
        textAlign: 'center',
        marginTop: multiLine ? lineHeight : 'inherit',
        lineHeight: lineHeight
    }
}

const NoData = ({children = 'NO DATA TO DISPLAY', lineHeight = 190,multiLine = false, style}) =>{
	return (
		<div lineHeight={lineHeight} multiLine={multiLine} style={{...style}}>
			{children}
		</div>
	)
}

export default NoData;