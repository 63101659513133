import React, { useEffect, useState, useRef } from 'react';
import ReactSelect, { components } from 'react-select';
import buildStyle from './style';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

export const Select = ({ options = [], value, styles = {}, placeholder, onChange, isDisabled, isMulti, ...params }) => {
    const selectStyles = buildStyle(styles);
    function Option(props) {
        const { children } = props;
        const { fontSize = 10 } = props.getStyles('option', props);
        const { width: containerWidth } = props.getStyles('container', props);
        const ellipsis = (val) => (val.length * fontSize > containerWidth * 0.7 ? { overflow: 'hidden', textOverflow: 'ellipsis' } : {});
        return isMulti ? (
            <components.Option {...props}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Checkbox disabled={true} checked={!!(value || []).find((selected) => selected.value === props.value)} style={{ color: '#3542a5' }} />
                    <Tooltip title={children} disableHoverListener={!Object.keys(ellipsis(children)).length}>
                        <div style={{ marginTop: 'auto', marginBottom: 'auto', whiteSpace: 'nowrap', maxWidth: '80%', ...ellipsis(children) }}>{children}</div>
                    </Tooltip>
                </div>
            </components.Option>
        ) : (
            <components.Option {...props} />
        );
    }
    // const getSortedOptions = () => {
    //     const sortByLabel = ({ label: labelA }, { label: labelB }) => (labelA < labelB ? -1 : labelA > labelB ? 1 : 0);
    //     const selected = value?.sort(sortByLabel) || [];
    //     const opts = options.filter(({ label }) => !value?.find((o) => o.label === label))?.sort(sortByLabel);
    //     return [...selected, ...opts];
    // };

    return (
        <ReactSelect
            styles={selectStyles}
            value={value}
            options={options}
            components={{ Option }}
            onChange={onChange}
            placeholder={placeholder}
            isDisabled={isDisabled}
            isMulti={isMulti}
            closeMenuOnSelect={!isMulti}
            hideSelectedOptions={false}
            // controlShouldRenderValue={!isMulti}
            {...params}
        />
    );
};
