import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import accounts from './slicers/accounts';
import profile from './slicers/profile';
import users from './slicers/users';
import permissions from './slicers/permissions';
import reports from './slicers/reports';
import settings from './slicers/settings';
import lookUps from './slicers/lookups';

const store = configureStore({
    reducer: {
        accounts,
        profile,
        users,
        permissionsManager: permissions,
        reports,
        settings,
        lookUps
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store