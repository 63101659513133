import instance from './';
import config from '../../config';
import { NEW_PRODUCT_NAMES } from './consts';
const { licenseLicenseUrl, licenseProductUrl } = config;

export class LicensesService {
    static async getProducts() {
        const res = await instance.get(`${licenseProductUrl}/list?platform=paradome`);
        res.data.products = res.data.products?.map((product) => ({
            ...product,
            name: NEW_PRODUCT_NAMES[product.id],
        }));
        return res.data;
    }

    static async getById(id) {
        const res = await instance.get(`${licenseLicenseUrl}/${id}`);
        return res.data;
    }

    static async upsert(license) {
        const res = await instance.put(`${licenseLicenseUrl}/`, license);
        return res.data;
    }

    static async deleteAllProduction(networkId) {
        const res = await instance.delete(`${licenseLicenseUrl}/${networkId}`);
        return res.data;
    }
}
