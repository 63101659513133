
/**
      * this file is auto generated by chopin-scripts.
      * you can check the project package.json 
      * to see how it was generated.
      */

import Logo from './logo';

export default {
		Logo,
}
