import React, {useCallback } from 'react';
import { toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './style.module.css';

const types = [
  'success',
    'info',
    'warn',
    'error'
];

export default ({message, type='info', ...props}) => {
    toast[types.find(t => t === type) || 'info'](message,
        {
            className: styles.container,
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 5000,
            hideProgressBar: true, ...props
        })
    // return <ToastContainer/>;
}
