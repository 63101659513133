import React, {useEffect, useState} from "react";
import {getTimeFrameByMilli, TIME_FRAME_OPTIONS, handleOnRuleChange} from './utils'
import Form from "./Form";

const AddRulesItem =  ({ rule, rules, setRules, editMode, globalEditMode, handleOnCancelRule, handleOnEditMode }) => {
    const [ruleFields, setRuleFields] = useState({
        id: rule.id,
        active: rule.active,
        clicks: rule.config?.maxInInterval,
        interval: rule.config?.interval,
        displayedInterval: '',
        timeFrame: '',
        campaignInput: '',
        campaignArr: rule.config?.conditions?.url ? rule.config.conditions.url : [],
    });

    const [initialRuleValues, setInitialRuleValues] = useState(null);

    useEffect(() => {
        if (ruleFields.interval >= TIME_FRAME_OPTIONS[0].minMilliValue) {
            const { frame, amount } = getTimeFrameByMilli(ruleFields.interval);
            setRuleFields((s) => ({ ...s, displayedInterval: amount, timeFrame: frame }));
        }
    }, [ruleFields.interval]);

    useEffect(() => {
        setRuleFields((s) => ({ ...s, campaignInput: '' }));
    }, [ruleFields.campaignArr]);


    const onRuleChange = (property, value) => {
        const newObject = handleOnRuleChange(-1, property, value, ruleFields, setRuleFields, initialRuleValues, setInitialRuleValues)
        setRules({...rules, [rule.id]: newObject})
    }

    return (
        <Form globalEditMode={globalEditMode}
              editMode={editMode}
              handleOnEditMode={handleOnEditMode}
              handleOnCancelRule={handleOnCancelRule}
              handleOnRuleChange={onRuleChange}
              ruleFields={ruleFields}
              addRulesComponent
        />
    );
}

export default AddRulesItem;
