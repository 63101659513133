export enum ConversionDataEnum {
    TYPE = 'type',
    SEARCH_ID = 'searchId',
    EVENT_NAME = 'eventName',
    EVENT_TYPE = 'eventType',
    URL = 'url',
}

export enum ConversionDataTypeEnum {
    CONVERSION_EVENT_NAME = 'conversionEventName',
    THANK_YOU_PAGE = 'thankYouPage',
}

export interface IConversionDataAction {
    type: ConversionDataEnum;
    payload: {
        index?: number;
        value: EventTypeValue;
    };
}

export type EventTypeValue = { value: string; label: string };
export type EventTypeOptions = { value: ConversionDataTypeEnum; label: string };
export type EventNameOptions = { value: string; label: string };

export interface IBaseEvent {
    id?: number;
}
export interface IEventProps extends IBaseEvent {
    [ConversionDataEnum.SEARCH_ID]: null | ITag;
    [ConversionDataEnum.EVENT_NAME]: null | EventNameOptions;
    [ConversionDataEnum.EVENT_TYPE]: null | EventTypeValue;
    [ConversionDataEnum.URL]: null | EventTypeValue;
}
export interface IEventFrom extends IBaseEvent {
    [ConversionDataEnum.SEARCH_ID]: undefined | number;
    [ConversionDataEnum.EVENT_NAME]: undefined | string;
    [ConversionDataEnum.EVENT_TYPE]: undefined | string;
    [ConversionDataEnum.TYPE]: undefined | number;
    [ConversionDataEnum.URL]: undefined | string;
}
export interface IEvent {
    [key: number]: ConversionEventList;
}

export interface IEventDAO {
    [key: number]: IDAOEvent[] | [];
}

export interface IUserConversionDataStateType {
    [ConversionDataEnum.TYPE]: EventTypeValue;
    events: Array<IEvent>;
}

export interface INetwork {
    [key: string]: null | object;
}

export type ITag = {
    value: number;
    label: string;
    mainTag: string;
};

export interface ICustomerConversionDataOptions {
    [ConversionDataEnum.TYPE]: EventTypeOptions[];
    [ConversionDataEnum.SEARCH_ID]: ITag[];
    [ConversionDataEnum.EVENT_NAME]: string[];
    [ConversionDataEnum.EVENT_TYPE]: EventTypeValue[];
    [ConversionDataEnum.URL]: string;
}

export type ConversionEventList = Array<string>;

export interface IDAOEvent {
    id?: number;
    tagId?: number;
    networkId?: number;
    type?: number;
    event?: string;
    eventType?: string;
}
