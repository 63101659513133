import { customerConversionDataOptions } from '../UserConversionData.config';
import { Container, ControlContainer, ControlContainerUrl, FormLabelStyle, GroupControls } from './UserConversionDataEvent.style';
import { Select } from 'components/widgets/select';
import Input from 'components/widgets/Input';
import useStyle from '../../../styles';
import { ConversionDataEnum, ConversionDataTypeEnum, EventTypeValue, IDAOEvent, IEvent, IEventProps } from '../UserConversionData.types';
import Button from '../../../../../widgets/button';
import { selectStyle } from '../../../../selectStyle';
import Dialog from './../../../../../widgets/dialog';
import Toast from '../../../../../../services/notifications';

import { EVENT_NAME_TYPE_NUM, THANK_YOU_PAGE_TYPE_NUM, validateDomain } from '../utils';
import { useEffect, useState } from 'react';

const commonSelectProps = {
    isMulti: false,
    isDisabled: false,
};

export const conversionDataSelectStyle = { ...selectStyle, container: { ...selectStyle.container, width: '70%' } };

export const AddUCDEvent = ({
    event,
    type,
    onSave,
    onDelete,
    checkEvents,
}: {
    event: IEvent;
    type: string;
    onDelete: (e: IEventProps, tagId: number, typeEvent: number) => void;
    onSave: (e: IEventProps) => void;
    checkEvents: (tagId: number) => IDAOEvent[];
}) => {
    const classes = useStyle();
    const [isEventOpen, setEventNameOpen] = useState<boolean>(false);
    const [isUrlEventOpen, setUrlEvent] = useState<boolean>(false);
    const [isSaveDisable, setSaveDisable] = useState<boolean>(true);
    const [_typeEvent, setTypeEvent] = useState<number>(1);
    const [eventFields, setEventFields] = useState<IEventProps>({
        [ConversionDataEnum.SEARCH_ID]: null,
        [ConversionDataEnum.EVENT_NAME]: null,
        [ConversionDataEnum.EVENT_TYPE]: null,
        [ConversionDataEnum.URL]: null,
    });
    useEffect(() => {
        if (type === ConversionDataTypeEnum.CONVERSION_EVENT_NAME) {
            eventFields[ConversionDataEnum.SEARCH_ID]?.value && eventFields[ConversionDataEnum.EVENT_NAME]?.value && eventFields[ConversionDataEnum.EVENT_TYPE]?.value
                ? setSaveDisable(() => false)
                : setSaveDisable(() => true);
        }
        if (type === ConversionDataTypeEnum.THANK_YOU_PAGE) {
            eventFields[ConversionDataEnum.SEARCH_ID]?.value && eventFields[ConversionDataEnum.URL]?.value ? setSaveDisable(false) : setSaveDisable(true);
        }
    }, [eventFields, type]);

    const handleValueChange = ({ type, value }: { type: ConversionDataEnum; value: EventTypeValue }) => {
        setEventFields((s) => ({ ...s, [type]: value }));
        switch (type) {
            case ConversionDataEnum.SEARCH_ID: {
                customerConversionDataOptions[ConversionDataEnum.EVENT_NAME] = event[value.value]?.map((event: string) => {
                    return { value: event, label: event.replaceAll('_', ' ') };
                });
                break;
            }
        }
    };

    const onSubmit = () => {
        type === ConversionDataTypeEnum.THANK_YOU_PAGE ? setTypeEvent(THANK_YOU_PAGE_TYPE_NUM) : setTypeEvent(EVENT_NAME_TYPE_NUM);
        const events = checkEvents(Number(eventFields[ConversionDataEnum.SEARCH_ID]?.value));
        if (type === ConversionDataTypeEnum.THANK_YOU_PAGE && !validateDomain(String(eventFields[ConversionDataEnum.URL]?.value))) {
            return Toast({ message: 'Domain url not valid' });
        }

        if (events.length > 0) {
            if (
                (type === ConversionDataTypeEnum.THANK_YOU_PAGE && events[0].type === THANK_YOU_PAGE_TYPE_NUM) ||
                (type === ConversionDataTypeEnum.CONVERSION_EVENT_NAME && events[0].type === EVENT_NAME_TYPE_NUM)
            ) {
                onSave(eventFields);
            } else {
                type === ConversionDataTypeEnum.CONVERSION_EVENT_NAME && events[0].type === THANK_YOU_PAGE_TYPE_NUM ? setUrlEvent(true) : setEventNameOpen(true);
                type === ConversionDataTypeEnum.THANK_YOU_PAGE && events[0].type === EVENT_NAME_TYPE_NUM ? setEventNameOpen(true) : setUrlEvent(true);
            }
        } else {
            onSave(eventFields);
        }
        return;
    };

    const handleDelete = (type: number) => {
        onDelete(eventFields, Number(eventFields.searchId?.value), type);
    };

    return (
        <form>
            <Container>
                <GroupControls>
                    <ControlContainer>
                        <FormLabelStyle
                            label="Search ID"
                            labelPlacement="start"
                            control={
                                <Select
                                    styles={conversionDataSelectStyle}
                                    options={customerConversionDataOptions[ConversionDataEnum.SEARCH_ID]}
                                    onChange={(selectedOption: EventTypeValue) =>
                                        handleValueChange({
                                            type: ConversionDataEnum.SEARCH_ID,
                                            value: selectedOption,
                                        })
                                    }
                                    value={eventFields?.searchId}
                                    placeholder="Select Search ID"
                                    {...commonSelectProps}
                                />
                            }
                        />
                    </ControlContainer>
                </GroupControls>
                {type === ConversionDataTypeEnum.CONVERSION_EVENT_NAME ? (
                    <GroupControls>
                        <ControlContainer>
                            <FormLabelStyle
                                label="Event Name"
                                labelPlacement="start"
                                control={
                                    <Select
                                        styles={conversionDataSelectStyle}
                                        options={customerConversionDataOptions[ConversionDataEnum.EVENT_NAME]}
                                        onChange={(selectedOption: EventTypeValue) =>
                                            handleValueChange({
                                                type: ConversionDataEnum.EVENT_NAME,
                                                value: selectedOption,
                                            })
                                        }
                                        value={eventFields?.eventName}
                                        placeholder="Select Event Name"
                                        {...commonSelectProps}
                                    />
                                }
                            />
                        </ControlContainer>
                        <ControlContainer>
                            <FormLabelStyle
                                label="Event Type"
                                labelPlacement="start"
                                control={
                                    <Select
                                        styles={conversionDataSelectStyle}
                                        options={customerConversionDataOptions[ConversionDataEnum.EVENT_TYPE]}
                                        onChange={(selectedOption: EventTypeValue) =>
                                            handleValueChange({
                                                type: ConversionDataEnum.EVENT_TYPE,
                                                value: selectedOption,
                                            })
                                        }
                                        value={eventFields?.eventType}
                                        placeholder="Select Event Type"
                                        {...commonSelectProps}
                                    />
                                }
                            />
                        </ControlContainer>
                    </GroupControls>
                ) : (
                    <GroupControls>
                        <ControlContainerUrl>
                            <FormLabelStyle
                                label="URL"
                                labelPlacement="start"
                                control={
                                    <Input
                                        className={classes.largeInput}
                                        placeholder="Enter URL"
                                        style={{ width: '85%', boxSizing: 'border-box' }}
                                        type={'url'}
                                        value={eventFields?.url?.value ?? ''}
                                        disabled={false}
                                        onChange={(value: string) => {
                                            handleValueChange({
                                                type: ConversionDataEnum.URL,
                                                value: { value, label: 'url' },
                                            });
                                        }}
                                    />
                                }
                            />
                        </ControlContainerUrl>
                    </GroupControls>
                )}
                <GroupControls>
                    <div />
                    <Button
                        className={classes.saveButton}
                        color="primary"
                        style={isSaveDisable ? { backgroundColor: '#A8A8A8' } : {}}
                        disabled={isSaveDisable}
                        onEnter={undefined}
                        onClick={() => onSubmit()}
                    >
                        Save
                    </Button>
                </GroupControls>
                <Dialog
                    onClose={() => {
                        setEventNameOpen(false);
                    }}
                    onDismiss={() => {
                        setEventNameOpen(false);
                    }}
                    isOpen={isEventOpen}
                    onSubmit={async () => {
                        await handleDelete(EVENT_NAME_TYPE_NUM);
                        setEventNameOpen(false);
                    }}
                    title={'Add Conversion Event'}
                    body={`The Thank You URL page is already defined.
By selecting Save conversion events the URL will be deleted. Click Confirm to continue.`}
                    submitText={`Confirm`}
                    dismissText={`Cancel`}
                    Component={undefined}
                    props={undefined}
                />
                <Dialog
                    onClose={() => {
                        setUrlEvent(false);
                    }}
                    onDismiss={() => {
                        setUrlEvent(false);
                    }}
                    isOpen={isUrlEventOpen}
                    onSubmit={async () => {
                        await handleDelete(THANK_YOU_PAGE_TYPE_NUM);
                        setUrlEvent(false);
                    }}
                    title={'Add Thank You URL Page'}
                    body={`The Conversion Event is already defined.
By selecting Save thank you url page the conversion event will be deleted. Click Confirm to continue.`}
                    submitText={`Confirm`}
                    dismissText={`Cancel`}
                    Component={undefined}
                    props={undefined}
                />
            </Container>
        </form>
    );
};
