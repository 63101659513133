import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAccounts } from 'redux/slicers/accounts';
import { setRegionsLookUp, setCsmsLookUp, setDataLocationsLookUp, setSesLookUp } from 'redux/slicers/lookups';

export const useLookUps = () => {
    const dispatch = useDispatch();
    const { regions = [], csms = [], ses = [], dataLocations = [] } = useSelector(selectAccounts);

    useEffect(() => {
        dispatch(setRegionsLookUp(regions));
    }, [regions]);

    useEffect(() => {
        dispatch(setCsmsLookUp(csms));
    }, [csms]);

    useEffect(() => {
        dispatch(setSesLookUp(ses));
    }, [ses]);

    useEffect(() => {
        dispatch(setDataLocationsLookUp(dataLocations));
    }, [dataLocations]);
};