import React, {useEffect, useState} from "react";
import { Select } from '../../../widgets/select';
import api from '../../../../services/api';
import config from '../../../../config';
import {Button} from "@material-ui/core";
import AddRulesItem from "./AddRulesItem";
import moment from "moment";

const { baseAPI: baseURL } = config;
const initialRule = {
    123456: {
        id: 123456,
        active: 1,
        config: {}
    }
}

const AddFCRules = ({ onSave, network }) => {
    const [tags, setTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [rules, setRules] = useState(initialRule)

    useEffect(async () => {
        const res = await api.post(`${baseURL}/clickTrueTags/get_tags`, {networkId: network.id});
        const tagsArray = res.data.map(data => {return {value: data.value, label: data.label}})
        setTags(tagsArray)
    }, [])

    const onAddRules = () => {
        const id = new Date().getTime();
        const draftRule = {
            [id]: {
                id,
                active: 1,
                config: {}
            }
        }

        setRules({...rules, ...draftRule})
    }

    const onCancelRule = (id) => {
        const copyObject = rules;
        delete copyObject[id];
        setRules({...copyObject});
    }

    const onSaveRules = () => {
        const insertArr = [];
        Object.keys(rules).map(ruleId => {
            const { id, active, campaignArr: url, clicks: maxInInterval, interval } = rules[ruleId];

            for (let tag of selectedTags) {
                const newRule = {
                    id: id,
                    name: null,
                    description: null,
                    config: {
                        vectorSegments: ['ip'],
                        conditions: { googlePaid: true, onlyLegit: true},
                        interval,
                        maxInInterval,
                    },
                    date_created: moment().toISOString(),
                    active,
                    clickTrueId: Number(tag),
                };
                if (url.length > 0) {
                    newRule.config.conditions.url = url;
                }
                insertArr.push(newRule)
            }
        })
        onSave(insertArr);
        setRules(initialRule)
        setSelectedTags([])
        setTags([])
    }

    const isRulesValid = () => {
        let res = true;
        for (const rule in rules) {
            if (rule.clicks === 0) {
                res = false
            }
        }

        return res;
    }

    return (
        <div className="add-fc-rules-container">
            <div className="add-fc-rules-title">
                Select required SearchIds you wish to create rules for:
                <Select isMulti={true}
                        placeholder={'Tags'}
                        options={tags}
                        value={tags.filter(tag => selectedTags.includes(tag.value))}
                        onChange={(selected) => setSelectedTags(([ ... (selected || []).map(({ value }) => value) ]))}
                />
            </div>
            <div style={{marginTop: 15}}>
                {Object.keys(rules).map(id => (
                    <div key={id}>
                        <AddRulesItem rule={rules[id]}
                                      rules={rules}
                                      setRules={setRules}
                                      handleOnCancelRule={onCancelRule}
                                      editMode={true}
                                      globalEditMode={false}
                        />
                    </div>
                ))}
            </div>
            <div>
                <Button variant="outlined" disabled={false} onClick={onAddRules}>
                    + add rule
                </Button>
                <Button variant="contained" disabled={selectedTags.length === 0 || !isRulesValid()} onClick={onSaveRules} color="secondary" style={{marginLeft: 12}}>
                    SAVE
                </Button>
            </div>
        </div>
    )
}

export default AddFCRules;