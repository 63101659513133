import api from './';

export class FrequencyCappingService {
    static baseRoute = 'frequency_capping';

    static async fetchRulesByNetworkId(params) {
        const { networkId } = params;
        const res = await api.get(`${this.baseRoute}/rule?networkId=${networkId}`);
        return res.data;
    }

    static async updateRule(params) {
        console.log({ params });
        const newRule = params.newRule ? params.newRule : params;
        const res = await api.put(`${this.baseRoute}/rule`, newRule);
        return res.data;
    }

    static async addRule(params) {
        const { newRules } = params;
        const res = await api.post(`${this.baseRoute}/rule`, {list: newRules});
        return res.data;
    }

    static async deleteRule(params) {
        const { newRule: id } = params;
        const res = await api.delete(`${this.baseRoute}/rule?id=${id}`);
        return res.data;
    }
}
