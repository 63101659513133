import React from 'react';
import svg from '../../svgs';
import HelloUser from '../../widgets/hello-user';
import lodash from 'lodash';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { requirePermissions } from 'redux/slicers/permissions';

const { Logo } = svg;

export const Navbar = ({ position = 'top', tabs = [], selectedTab }) => {
    const dispatch = useDispatch();
    const classes = useStyle(tabs)();

    return (
        <div className={classes.container}>
            <div className={classes.user}>
                <HelloUser />
            </div>
            <div className={classes.tabsContainer}>
                <div className={classes.tabs}>
                    {tabs.map(({ key, perms = [] }, index) => (
                        <div key={index} className={classes.tab}>
                            {dispatch(requirePermissions({ required: [...perms], Component: linkComponent(key, selectedTab), Disabled: disabled({ key }) }))}
                        </div>
                    ))}
                </div>
            </div>

            <Logo className={classes.logo} />
        </div>
    );
};

const linkComponent = (tab, selectedTab) => {
    return (
        <Link to={`/${tab}`} style={{ color: selectedTab === tab ? '#fe0072' : '#fff' }}>
            {lodash.capitalize(tab)}
        </Link>
    );
};

const disabled = ({ tab }) => <div style={{ color: 'gray' }}>{lodash.capitalize(tab)}</div>;

const useStyle = (tabs) =>
    makeStyles((theme) => ({
        container: {
            backgroundColor: '#14172c',
            width: '100vw',
            height: 50,
            position: 'fixed',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: 20,
        },
        tabsContainer: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        user: {
            // left: 10,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        logo: {
            position: 'relative',
        },
        logoutIcon: {
            cursor: 'pointer',
            width: 50,
            textAlign: 'center',
            color: '#fe0072',
        },
        tabs: {
            display: 'flex',
            justifyContent: 'space-between',
            width: 120 * tabs.length,
        },
        tab: {
            '& a': {
                textDecoration: 'none !important',
            },
        },
    }));
