import './styles.css';

import React, { useEffect, useState } from 'react';
import ajax from '../../../services/ajax';
import {
    fetchNetworkRules,
    getAccountInternalAdmin,
    getAssociatedNetworks,
    getNetworkFeatures,
    isLoading,
    sendNotificationtoAccount,
    updateAccount,
    updateNetwork,
    handleAccountStatus,
    upsertLicense
} from 'redux/slicers/accounts';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Dialog from '../../widgets/dialog';
import Switch from '../../widgets/switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography/Typography';
import { selectPermissions } from 'redux/slicers/permissions';
import { useTheme } from '@material-ui/core/styles';
import { Select } from '../../widgets/select';
import ImplementationGuide from './utils/implementation-guide';
import Input from '../../widgets/Input';
import { UserConversionData } from './tabs/UserConversionData';
import { useSplit } from '../../../hooks/useSplit';
import { SPLIT_CONSTANTS } from '../../../services/split.io/constants';
import SettingsTab from './SettingsTab';
import InfoTab from './InfoTab';
import { LicensesService } from '../../../services/api/licenses.service';

const AccountView = () => {
    const params = useParams();
    const accountId = parseInt(params.accountId);
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { isOn: isUserConversionDataFlagOn } = useSplit(SPLIT_CONSTANTS.SPLIT_USER_CONVERSION_DATA);

    const [tab, setTab] = React.useState('info');
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogImplemetationGuideIsOpen, setDialogImplemetationGuideIsOpen] = useState(false);
    const [dialogControlGroupIsOpen, setDialogControlGroupIsOpen] = useState(false);
    const [dialogResetPasswordIsOpen, setDialogResetPasswordIsOpen] = useState(false);
    const [tagInfo, setTagInfo] = useState(0);

    const permissions = useSelector(selectPermissions);
    const account = useSelector((state) => state.accounts.accounts.find((a) => a.id === accountId));

    const userCanViewSettingTab = permissions.account_settings && permissions.account_settings?.write;
    const networks = account?.networks;
    const userCanWriteAccounts = account && permissions.account && permissions.account?.write;
    const userCanDeactivate = account && permissions.account && permissions.account?.enable_disable;

    const [network] = networks || [];

    const getTagId = async (networkId) => {
        const {
            whiteLabelDomain: { domain, cdn, cdnPath, server },
        } = network;
        const res = ajax({ baseRoute: 'clickTrueTags/get_main_tag' })('', { networkId });
        const { id, tagHash, name } = await res;
        setTagInfo({ tagHash, id, domain, cdn, cdnPath, server, name: encodeURIComponent(name), dataLocationId: account.dataLocationId, accountName: network.name });
    };

    useEffect(() => {
        if (network) {
            getTagId(network.id);
        }
    }, [network]);

    useEffect(() => {
        if (!networks && !dispatch(isLoading('getAssociatedNetworks'))) dispatch(getAssociatedNetworks({ accountId }));
    }, [accountId, dispatch, networks]);

    useEffect(() => {
        if (network && !network.networkFeatures && account) {
            dispatch(getNetworkFeatures({ networkId: network.id, accountId: account.id }));
        }
        if (account && !account.internalAdmin) {
            dispatch(getAccountInternalAdmin({ accountName: account.name }));
        }
    }, [account, network, dispatch]);

    function createTabPanelId(name) {
        return `full-width-tabpanel-${name}`;
    }
    function createTabId(name) {
        return `full-width-tab-${name}`;
    }

    function a11yProps(name) {
        return {
            id: createTabId(name),
            name: name,
            'aria-controls': createTabPanelId(name),
        };
    }

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <Typography component="div" role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} {...other}>
                <Box p={3}>{children}</Box>
            </Typography>
        );
    }

    const onEdit = () => {
        history.push(`/accounts/edit/${account?.id}`);
    };

    const onSendInvite = () => {
        dispatch(sendNotificationtoAccount(account));
    };

    const renderEditButton = () => {
        if (userCanWriteAccounts) {
            return (
                <button className="cq-btn" onClick={onEdit}>
                    EDIT
                </button>
            );
        }
        return <></>;
    };

    async function onActivateDeActivateAccount({ orgId, accountId, networkId, active }) {
        if(!active) {
            await LicensesService.deleteAllProduction(networkId);
            dispatch(upsertLicense({ network_id: networkId, products: [], accountId: accountId }));
        }
        dispatch(handleAccountStatus({ orgId: orgId, accountId: accountId, networkId: networkId, active: active }));
    }

    const getTabsProps = () => {
        const tabs = { info: { Panel: <InfoTab accountId={accountId} account={account} networks={networks} />, label: 'Info' } };
        if (!account?.isAgency && networks && networks[0] && userCanViewSettingTab) {
            tabs['settings'] = {
                Panel: (
                    <SettingsTab
                        accountId={accountId}
                        network={network}
                        networks={networks}
                        setDialogControlGroupIsOpen={setDialogControlGroupIsOpen}
                        setDialogResetPasswordIsOpen={setDialogResetPasswordIsOpen}
                    />
                ),
                label: 'Settings',
            };
        }
        if (isUserConversionDataFlagOn && userCanWriteAccounts) {
            tabs['customerConversionData'] = { Panel: <UserConversionData network={network} />, label: 'Customer Conversion Data' };
        }
        return tabs;
    };

    const tabsProps = getTabsProps();

    const onActionChange = ({ value }) => {
        // eslint-disable-next-line default-case
        switch (value) {
            case 'activationEmail':
                onSendInvite();
                break;
            case 'implementationGuide':
                setDialogImplemetationGuideIsOpen(true);
                break;
        }
    };

    return (
        <div>
            <div className="accounts-header">
                <div className="accounts-header-actions">
                    <div className="accounts-header-item">{renderEditButton()}</div>
                </div>
            </div>
            <div className="cq-container">
                <Card style={{ overflow: 'auto' }}>
                    <CardHeader
                        avatar={<Avatar src={account?.logoUrl} />}
                        title={account?.name}
                        subheader={account?.active ? '(Active)' : '(Deactivated)'}
                        action={
                            <div>
                                {/*C4PA-516 TO-DO: change SELECT to MENU */}
                                <Select
                                    value=""
                                    options={[
                                        { value: 'activationEmail', label: 'Send activation email' },
                                        tagInfo && { value: 'implementationGuide', label: 'Get implementation guide' },
                                    ]}
                                    placeholder={'Actions'}
                                    onChange={onActionChange}
                                />

                                <Switch
                                    type="checkbox"
                                    checked={!!account?.active}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        setDialogIsOpen(true);
                                    }}
                                />
                            </div>
                        }
                    />
                    <CardContent>
                        <Tabs
                            value={tab}
                            onChange={(e, newValue) => {
                                setTab(newValue);
                            }}
                        >
                            {Object.entries(tabsProps).map((currTab, i) => {
                                const [key, { label }] = currTab;
                                return <Tab key={key} value={key} label={label} {...a11yProps(label)} />;
                            })}
                        </Tabs>
                        <TabPanel key={tab} value={tab} index={tab} dir={theme.direction}>
                            {tabsProps[tab]?.Panel ?? tabsProps[Object.keys(tabsProps)[0]]}
                        </TabPanel>
                    </CardContent>
                </Card>
            </div>
            <Dialog
                onClose={() => setDialogIsOpen(false)}
                isOpen={dialogIsOpen}
                onSubmit={() => {
                    onActivateDeActivateAccount({ orgId: account?.orgId, accountId: account?.id, networkId: account?.networkId, active: !account?.active });
                }}
                title={account?.active ? 'Deactivate Account' : 'Activate Account'}
                body={account?.active ? `Are you sure you want to deactivate ${account?.name}?` : `Do you want to activate ${account?.name}?`}
            />
            <Dialog
                onClose={() => setDialogControlGroupIsOpen(false)}
                isOpen={dialogControlGroupIsOpen}
                onSubmit={() => {
                    const network = networks[0];
                    if (!network) return;
                    dispatch(
                        updateNetwork({
                            id: network.id,
                            isBlockingControlGroup: !network?.isBlockingControlGroup,
                            accountId: account?.id,
                        })
                    );
                }}
                title={networks && networks[0]?.isBlockingControlGroup ? 'Deactivate Blocking Control Group' : 'Activate Blocking Control Group'}
                body={networks && networks[0]?.isBlockingControlGroup ? `Are you sure you want to deactivate control group?` : `Do you want to activate control group?`}
            />
            <Dialog Component={() => <ImplementationGuide tagInfo={tagInfo} onclose={() => setDialogImplemetationGuideIsOpen(false)} />} isOpen={dialogImplemetationGuideIsOpen} />
            <Dialog isOpen={dialogResetPasswordIsOpen} Component={ResetPassword} props={{ account, setDialogResetPasswordIsOpen }} />
        </div>
    );
};

export default AccountView;
