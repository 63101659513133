import axios from 'axios';
import config from '../../config';

const { baseAPI: baseURL } = config;

const getCsrfToken = () => {
    let csrfToken = null;
    try {
        const meta = JSON.parse(localStorage.meta);
        csrfToken = meta.csrfToken;
    } catch (e) {}
    if (!csrfToken) {
        csrfToken = localStorage.csrf;
    }

    return csrfToken;
};

const getAuthorizationToken = () => {
    return localStorage.getItem('auth0Token');
};

export const getHTTPHeaders = (extraHeaders = {}) => {
    return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-T-CSRF': getCsrfToken(),
        Authorization: `Bearer ${getAuthorizationToken()}`,
        'X-CHEQ-LOCATION': 'TBD',
        ...extraHeaders,
    };
};

const instance = axios.create({
    baseURL,
    withCredentials: true,
});

instance.interceptors.request.use((config) => {
    config.headers = getHTTPHeaders();
    return config;
});

instance.interceptors.response.use(undefined, (error) => {
    return Promise.reject(error.response.data);
});

export default instance;
