import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    accountForm: {
        fontSize: 14,
        backgroundColor: 'hsl(0,0%,100%) !important',
        color: "#000 !important",
        '&$accountFormFocused $accountFormNotchedOutline': {
            borderColor: `black !important`,
        },
        '&:hover $accountFormNotchedOutline': {
            borderColor: `hsl(0,0%,60%)`,
        }
    },

    accountFormNotchedOutline: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'hsl(0,0%,80%)',
    },
    
    accountFormFocused: {
        borderColor: 'black !important',
    },

    accountFormLabel: {
        color : 'hsl(0,0%,40%) !important',
        fontSize: 14,
    },
      accountFormLabelFocused: {
        color  : 'black !important',
    },
    smallInputMui: {
        width: "12.5rem !important",
    },
    smallSelectMui: {
        width: "11.3rem !important",
    },
    smallDateMui: {
        width: "14.2rem !important",
    },
    accountFormSelectPaperLong: {
        width:'2rem',
        maxHeight: '15rem !important'
    },
    accountFormSelectPaperShort: {
        width:'2rem',
        maxHeight: '8rem !important'
    },
    dateIcon: {
        color: 'inherit !important'
    }
}));