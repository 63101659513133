import { useTreatments } from '@splitsoftware/splitio-react';
import { useSelector } from 'react-redux';
import { isFlagOn } from '../services/split.io';

export const useSplit = (splitName, attributes = {}, ...args) => {
    const { id, networkId } = useSelector((state) => state.profile.meta);

    const attr = {
        ...attributes,
        userId: String(id),
    };

    const { [splitName]: { treatment } = {} } = useTreatments([splitName], attr, String(networkId));

    return { isOn: isFlagOn(treatment) };
};
