import instance from '.';
import config from '../../config';
const { hubspotServiceURL } = config;

export class HubspotService {
    static async getMonitoringMode(networkId) {
        const res = await instance.get(`${hubspotServiceURL}/config/v1/monitoringMode/${networkId}`);
        return res.data;
    }

    static async setMonitoringMode(isMonitoringMode, networkId) {
        const res = await instance.put(`${hubspotServiceURL}/config/v1/monitoringMode/${networkId}`, {isMonitoringMode});
        return res.data;
    }
}
