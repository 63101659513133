import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'redux/store';
import ajax from 'services/ajax'
import { isLoadingSlice, thunkRejected, thunkPending } from "services/redux";
import { ISettingsState, IIndustry } from './types';

const settingsAjax = ajax({ baseRoute: 'settings' });

const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: ISettingsState,
  dispatch: AppDispatch
}>()

export const getIndustries = createAppAsyncThunk<IIndustry[], void>('getIndustries', () => {
  return settingsAjax('get_industries', {})
});

const initialState: ISettingsState = {
  industries: [],
  errors: [],
  loading: []
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIndustries.pending, thunkPending(getIndustries.typePrefix)),
      builder.addCase(getIndustries.rejected, thunkRejected(getIndustries.typePrefix)),
      builder.addCase(getIndustries.fulfilled, (state, { payload }) => {
        state.loading = state.loading.filter(l => l !== getIndustries.typePrefix)
        state.industries = payload
      })
  }
})

export const isLoading = isLoadingSlice('settings');

export const selectSettings = (state: RootState) => state.settings;

export default settingsSlice.reducer









