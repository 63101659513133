import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {Ajax} from 'services/ajax'
import md5 from 'md5';
import Toast from 'services/notifications'
import moment from 'moment'

const ajax = new Ajax({ baseRoute: 'analytics' });

export const getReport = createAsyncThunk(
    'runConsoleReport',
    (params, {getState}) => {
        const runAt = moment().format('YYYY-MM-DD HH:mm:ss');
        const { loading } = getState().reports;
        if (!loading.includes('runConsoleReport')) {
            return
        }
        return ajax.request('run_console_report', params).then(({data}) => ({runAt,data}))
    }
)


export const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        history: [],
        loading: [],
        errors: [],
        report: {data: []}
    },

    reducers: {
        setReport: (state, action) => {
            state.report = action.payload;
        },
        removeReport: (state, action) => {
            state.history = state.history.filter(({key}) => key !== action.payload.key)
        }
    },
    extraReducers: {
        [getReport.pending]: (state, action) => {
            if (!state.loading.includes(getReport.typePrefix)){
                state.loading.push(getReport.typePrefix)
            }
        },
        [getReport.fulfilled]: (state, {meta, payload}) => {
            const { arg: params } = meta;
            if (state.loading.includes(getReport.typePrefix)) {
                const key = getHistoryKey(params)
                const report = {key, ...payload, params}
                state.loading = state.loading.filter(l => l !==getReport.typePrefix)
                state.report = report
                state.history.push(report)

            }
        },
        [getReport.rejected]: (state, action) => {
            if (state.loading.includes(getReport.typePrefix)) {
                state.loading = state.loading.filter(l => l !== getReport.typePrefix)
                Toast({message: action.error.message})
                state.report = undefined;
            }
        }
    }
});
export const selectReports = state => state.reports;

const {setReport, removeReport} = reportsSlice.actions


export const isLoading = params => (dispatch, getState) => {
    const {reports: {loading}} = getState();
    return params ? loading.includes(params) : !!loading.length;
};

export const setHistoryReport = report => (dispatch, getReport) => {
    dispatch(setReport(report))
}

export const removeFromHistory = report => (dispatch, getReport) => {
    dispatch(removeReport(report))
}

export const getHistoryKey = (params = {}) => {
    const { groupBy = [], where = {} } = params;
    return md5(JSON.stringify({
        groupBy: groupBy.map(g => g).slice().sort(),
        where: Object.entries(JSON.parse(JSON.stringify(where)))
            .map(([key, value = []]) => !Array.isArray(value) ? [key, value] : [key, value.sort()])
            .reduce((total, [key, value]) => ({ ...total, [key]: value }), {})
    }))
}


export default reportsSlice.reducer