import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isLoadingSlice, thunkPending, thunkRejected } from 'services/redux';
import { AppDispatch, RootState } from 'redux/store';
import { IProfileMeta, IProfileState } from './types';
import { User } from '@auth0/auth0-react';

export const isLoading = isLoadingSlice('profile');

const createAppAsyncThunk = createAsyncThunk.withTypes<{
    state: IProfileState,
    dispatch: AppDispatch
}>()

export const preLogout = createAppAsyncThunk<number, void>('preLogout', async () => {
    return 1;
});

const initialState: IProfileState = {
    user: {},
    meta: {},
    loading: [],
    ready: false,
}

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<{ user: User, meta: IProfileMeta }>) => {
            const { user, meta } = action.payload;
            state.user = user;
            state.meta = meta;
        },
        setReady: (state, action: PayloadAction<boolean>) => {
            state.ready = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(preLogout.pending, thunkPending(preLogout.typePrefix)),
            builder.addCase(preLogout.rejected, thunkRejected(preLogout.typePrefix)),
            builder.addCase(preLogout.fulfilled, () => localStorage.clear())
    }
});

const { setUser, setReady } = profileSlice.actions;
export default profileSlice.reducer;

export const initProfile = (user: User, meta: IProfileMeta) => (dispatch: AppDispatch) => {
    dispatch(setUser({ user, meta }));
    dispatch(setReady(true));
};

export const selectProfile = (state: RootState) => state.profile;
