import { FC, useMemo } from 'react';
import {  IAccountTable } from 'models/Account';
import { useHistory } from 'react-router-dom';
import { Column, MTableToolbar, Options } from 'material-table';
import Table from 'components/widgets/table';
import AddAccountButton from './AddAccountBtn';
import { formatToTableStructure } from './utils';
import Tooltip from '../../../widgets/tooltip';
import { isLoading, selectAccounts } from '../../../../redux/slicers/accounts';
import { useAppDispatch ,useAppSelector} from 'redux/store';

const options: Options<object> = {
    filtering: true,
    search: true,
    pageSize: 20,
    columnsButton: true,
    exportButton: true,
    exportAllData: true,
    tableLayout: 'fixed',
    doubleHorizontalScroll: true,
};

const AccountTable: FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { accounts = [] } = useAppSelector(selectAccounts);
    const { csms, regions, dataLocations } = useAppSelector((state) => state.lookUps);
    const loading: any = dispatch(isLoading('initAccounts')) || dispatch(isLoading('createAccount'));

    const tableData = useMemo(() => accounts.map((acc) => formatToTableStructure(acc, regions, dataLocations, csms)), [accounts, regions, dataLocations, csms]);

    const handleRowClick = (_a: any, rowData: IAccountTable) => {
        if (rowData.id) {
            history.push(`/accounts/view/${rowData.id}`);
        }
    };

    const COLUMNS: Column<IAccountTable>[] = [
        {
            title: 'Name',
            field: 'name',
            removable: false,
            render: ({ name }) => (
                <Tooltip title={name} disable={false}>
                    <div className="ellipsis">{name}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Network ID',
            field: 'networkId',
            render: ({ networkId }) => (
                <Tooltip title={networkId?.toString()} disable={false}>
                    <div className="ellipsis">{networkId}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Domain',
            field: 'domain',
            render: ({ domain }) => (
                <Tooltip title={domain} disable={false}>
                    <div className="ellipsis">{domain}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Region',
            field: 'region',
            render: ({ region }) => (
                <Tooltip title={region} disable={false}>
                    <div className="ellipsis">{region}</div>
                </Tooltip>
            ),
        },
        {
            title: 'CSM',
            field: 'csmEmail',
            render: ({ csmEmail }) => (
                <Tooltip title={csmEmail} disable={false}>
                    <div className="ellipsis">{csmEmail}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Data Location',
            field: 'dataLocation',
            render: ({ dataLocation }) => (
                <Tooltip title={dataLocation} disable={false}>
                    <div className="ellipsis">{dataLocation}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Creation Date',
            field: 'createdDate',
            type: 'date',
            render: ({ createdDate }) => (
                <Tooltip title={createdDate?.toString()} disable={false}>
                    <div className="ellipsis">{createdDate}</div>
                </Tooltip>
            ),
        },
        {
            title: 'Active',
            field: 'isActive',
            render: ({ isActive }) => (
                <Tooltip title={isActive} disable={false}>
                    <div className="ellipsis">{isActive}</div>
                </Tooltip>
            ),
        },
    ];

    return (
        <Table
            columns={COLUMNS}
            rows={tableData}
            options={options}
            isLoading={loading}
            handleRowClick={handleRowClick}
            customComponents={{
                Toolbar: (props) => (
                    <div className="table-toolbar">
                        <MTableToolbar {...props} />
                        <AddAccountButton className="add-account-btn" label="Add Account" dest="/accounts/create/" />
                    </div>
                ),
            }}
        />
    );
};

export default AccountTable;
