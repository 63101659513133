import TextField from '@mui/material/TextField';

interface IMuiTextField {
  id: string,
  label: string,
  size?: "small" | "medium" | undefined,
  onChange: (event:any)=> void,
  value: string | number,
  fullWidth?: boolean,
  type?:string,
  placeholder?: string,
  inputProps?: any,
  InputProps?: any,
  InputLabelProps?: any
}

export const MuiTextField = ({id, label, size, type, placeholder, fullWidth=false, onChange, value, inputProps, InputProps, InputLabelProps}:IMuiTextField) => {
  return (
    <TextField
    id={id}
    label={label}
    variant="outlined"
    type={type}
    size={size}
    fullWidth={fullWidth}
    placeholder={placeholder}
    autoComplete="off"
    onChange={onChange}
    value={value}
    inputProps={inputProps}
    InputProps={InputProps}
    InputLabelProps={InputLabelProps}
/>
  )
}
