import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {makeStyles} from "@material-ui/core";
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import Dialog from "../../widgets/dialog";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppSelector } from '../../../redux/store';
import { preLogout, selectProfile } from '../../../redux/slicers/profile';


export default ({ name }) => {
    const profile = useSelector(selectProfile)
    const styles = useStyles();
    return <div className={styles.helloContainer}>
        <PermIdentityIcon style={{ color: '#fe0072', width: 30, height: 30 }}/>
        <div className={styles.username}>
            {`${profile.user.name}`}
        </div>
        <Dropdown styles={styles}/>
    </div>
}


const Dropdown = ({styles}) => {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const { logout } = useAuth0();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const Profile = ({}) => {
        const {meta} = useAppSelector(selectProfile);
        const classes = useStyles()
        return <div className={classes.profileContainer}>
            <AddCircleOutlineIcon className={classes.closeProfileBtn} onClick={() => setDialogIsOpen(false)}/>
            <div className={classes.profileTitle}>User Profile</div>
            <div className={classes.profileProps}>
                <div className={classes.profileValue}> ID</div>
                <div className={classes.profileValue}> {meta.id}</div>
            </div>
            <div className={classes.profileProps}>
                <div className={classes.profileValue}> Name</div>
                <div className={classes.profileValue}> {meta.name}</div>
            </div>
            <div className={classes.profileProps}>
                <div className={classes.profileValue}> Email</div>
                <div className={classes.profileValue}> {meta.email}</div>
            </div>
        </div>
    }

    return <div>
        <ArrowDropDownIcon className={styles.arrow} onClick={handleClick}/>
        <Menu
            className={styles.menu}
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
            <MenuItem onClick={() => setDialogIsOpen(true)}>
                <ListItemIcon>
                    <PermIdentityIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Profile</Typography>
            </MenuItem>
            <MenuItem onClick={() => {dispatch(preLogout()).then(()=>logout())}}>
                <ListItemIcon>
                    <PowerSettingsNewOutlinedIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Logout</Typography>
            </MenuItem>
        </Menu>
        <Dialog isOpen={dialogIsOpen} Component={Profile}/>
    </div>
}






const useStyles = makeStyles(theme => ({
    helloContainer: {
        // width: `${name}`.length * 10,
        display: 'flex'
    },
    username: {
        color: '#fe0072',
        fontSize: 18,
        paddingTop: 5
    },
    arrow: {
        color: '#fe0072',
        position: 'relative',
        top: 5,
        left: 3,
        cursor: 'pointer'
    },
    menu: {
        top: '25px !important'
    },
    profileContainer: {
        backgroundColor: '#EBECF0',
        width: 300,
        height: 150,
        color: '#000',
        padding: 20
    },
    profileProps: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    profileValue: {
        fontSize: 16,
        textAlign: 'left'
    },
    profileTitle: {
        fontSize: 24,
        textAlign: 'center'
    },
    closeProfileBtn: {
        position: 'absolute',
        cursor: 'pointer',
        transform: 'rotate(45deg)',
        color: '#fe0072',
        right: 15,
        top: 10
    }

}))