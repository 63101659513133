import { FC } from 'react'

interface IProps {
    className?: string;
    handleClick: any;
    label?: string
}

const AddButton: FC<IProps> = ({ className = '', handleClick, label = '' }) => {
    return (
        <button className={`cq-btn ${className}`} onClick={handleClick} >
            {label}
        </button >
    )
}

export default AddButton